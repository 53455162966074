import dayjs from 'dayjs';
import * as S from '../styles';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ClickAwayListener, IconButton, InputAdornment } from '@mui/material';
import InputCell from 'components/atoms/InputCell';
import { useInvoiceItemUpdateMutation } from 'store/api/invoices/invoiceApi';
import { setMessage } from 'store/slices/message';
import { useNavigate, useParams } from 'react-router-dom';
import { Dialog } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { AccessLevel } from 'types/enums/AppointmentEnum';
import { ChooseDoctorOrClinic } from './ChooseDoctorOrClinic';
import { ChooseProviderTab } from 'constants/constants';
import { ClaimStatusType, ItemInInvoiceSheetType, ServicesType } from 'types/InvoiceType';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { useAppointmentGetOneMutation } from 'store/api/appointment/appointmentApi';
import { ChooseInjuryCodeMultipleWithGroup } from '../../../../Claims/components/ClaimForm/components/AlphabetLines/ChooseInjuryCodeMultipleWithGroup';
import DatePickerItem from '../../../../../../../components/atoms/DatePicker';
import { ChooseCptCodeWithGroup } from '../../../../Claims/components/ClaimForm/components/ServicesTable/ChooseCptCodeWithGroup';
import InputCellNumber from '../../../../../../../components/atoms/InputCell/InputCellNumber';
import { ChooseDoctorOrFacility } from './ChooseDoctorOrFacility';

type Props = {
  row: ItemInInvoiceSheetType;
  header: string;
};

const InvInfData: React.FC<Props> = ({ row, header }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const { invoiceSheetAccessLevel } = useAppSelector(state => state.auth);

  const [editNote, setEditNote] = useState(false);
  const [note, setNote] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [amount, setAmount] = useState(0);
  const [DOS, setDOS] = useState<any | string>('');
  const [editIndexDOS, setEditIndexDOS] = useState<number | null>(null);
  const [editIndexChargeAmount, setEditIndexChargeAmount] = useState<number | null>(null);

  const [editCpt, setEditCpt] = useState(false);
  const [editICD, setEditICD] = useState(false);
  const [editIndexCPT, setEditIndexCPT] = useState<number | null>(null);
  const [editRenderingProvider, setEditRenderingProvider] = useState(false);
  const [editServiceLocation, setEditServiceLocation] = useState(false);
  const [editBillingProvider, setEditBillingProvider] = useState(false);
  const [editBillingLocation, setEditBillingLocation] = useState(false);
  const [releasedStatus, setReleasedStatus] = useState(false);

  const [changeItem] = useInvoiceItemUpdateMutation({});
  const [getAppointmentOne] = useAppointmentGetOneMutation({});

  const emptyService = (): ServicesType => ({
    cpt: null,
    emg: null,
    epsdt: null,
    units: null,
    dateTo: null,
    idQual: null,
    charges: null,
    pointer: null,
    dateFrom: null,
    modifier: [],
    placeOfService: null,
    renderingProviderId: null,
  });

  const handleSave = ({ ...params }) => {
    changeItem({
      ...params,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Invoice item was updated successfully',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  const handleAppointmentClick = (id: string) => {
    getAppointmentOne({ id })
      .unwrap()
      .then(res => {
        dispatch(
          showDrawer({
            show: true,
            mode: DrawerMode.INFO_APPOINTMENT,
            props: res,
          }),
        );
      });
  };
  useEffect(() => {
    const checkStatusCondition = (row: ItemInInvoiceSheetType) => {
      return [
        // ClaimStatusType.CREATED,
        ClaimStatusType.PAID,
        // ClaimStatusType.RELEASED,
        // ClaimStatusType.MODIFIED,
        ClaimStatusType.REJECTED,
      ].includes(row?.claim.status as ClaimStatusType);
    };
    if (row) {
      setReleasedStatus(checkStatusCondition(row));
    }
  }, []);

  switch (header) {
    case 'SIGS': {
      return (
        <S.CellWrapper className={row.appointment ? 'cursor' : ''}>
          <S.Link
            onClick={() =>
              row.appointmentId ? handleAppointmentClick(row.appointmentId) : undefined
            }
          >
            {row?.date ? dayjs(row?.date).format('MM/DD/YYYY') : '-'}{' '}
          </S.Link>
          {row.claim.claimType === 'System' && row.appointment?.countSystemClaims
            ? `(${row.appointment?.countSystemClaims})`
            : row.appointment?.countManualClaims
              ? `(${row.appointment?.countManualClaims})`
              : ''}
        </S.CellWrapper>
      );
    }
    case 'DOS': {
      return (
        <>
          {(!!row?.dateOfServices?.length ? row?.dateOfServices : [null]).map(
            (date, index) => (
              <S.DOSWrapper
                key={index}
                editable={invoiceSheetAccessLevel}
                onDoubleClick={
                  !releasedStatus
                    ? () => {
                        if (invoiceSheetAccessLevel === AccessLevel.WRITE) {
                          setDOS(date || '');
                          setEditIndexDOS(index);
                          setIsOpen(true);
                        }
                      }
                    : undefined
                }
              >
                {editIndexDOS === index ? (
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => {
                      if (DOS && DOS !== date) {
                        const services =
                          row.cpt && row.cpt.length > 0
                            ? row.cpt.map((cptItem, i) => ({
                                ...(row?.claim?.services?.[i] || emptyService()),
                                cpt: cptItem || null,
                                charges: row.chargeAmount?.[i] || emptyService().charges,
                                dateFrom:
                                  i === editIndexDOS
                                    ? DOS
                                    : row.dateOfServices?.[i] || null,
                              }))
                            : [
                                {
                                  ...emptyService(),
                                  dateFrom: DOS,
                                },
                              ];

                        handleSave({
                          id: row.id,
                          services: services,
                          invoiceId: Number(params.invoiceId),
                          claimId: row.claimId,
                        });
                      }

                      setEditIndexDOS(null);
                      setIsOpen(false);
                    }}
                  >
                    <S.InputWrapper>
                      <DatePickerItem
                        open={isOpen}
                        disabled={releasedStatus}
                        onClose={() => {
                          setIsOpen(false);
                        }}
                        value={date ? dayjs(date) : null}
                        label={''}
                        onChange={value => {
                          setDOS(dayjs(value).format('YYYY-MM-DD'));
                        }}
                      />
                    </S.InputWrapper>
                  </ClickAwayListener>
                ) : (
                  <S.DOSCellWrapper disabled={releasedStatus}>
                    {date ? dayjs(date).format('MM/DD/YYYY') : '-'}
                  </S.DOSCellWrapper>
                )}
              </S.DOSWrapper>
            ),
          )}
        </>
      );
    }

    case 'ICD': {
      return (
        <>
          <S.ICDWrapper
            editable={invoiceSheetAccessLevel}
            onDoubleClick={
              !releasedStatus
                ? () => invoiceSheetAccessLevel === AccessLevel.WRITE && setEditICD(true)
                : undefined
            }
          >
            {row?.icd?.length
              ? row?.icd.map(i => (
                  <S.CptCellWrapper disabled={releasedStatus} key={i.code}>
                    &nbsp;{i.code}
                  </S.CptCellWrapper>
                ))
              : '-'}
          </S.ICDWrapper>
          <Dialog open={editICD} onClose={() => setEditICD(false)}>
            <ChooseInjuryCodeMultipleWithGroup
              onApplyClick={code => {
                handleSave({
                  id: row.id,
                  icd: code.map(c => ({ type: 'icd-10', code: c })),
                  invoiceId: Number(params.invoiceId),
                  claimId: row.claimId,
                });
                setEditICD(false);
              }}
              title={'ICD-10 Codes'}
              currentValue={row?.icd?.map(i => i.code) ?? []}
            />
          </Dialog>
        </>
      );
    }
    case 'CPT': {
      return (
        <>
          {(!!row?.cpt?.length ? row.cpt : [null]).map((cpt, index) => (
            <S.CptWrapper
              key={index}
              editable={invoiceSheetAccessLevel}
              onDoubleClick={
                !releasedStatus
                  ? () => {
                      if (invoiceSheetAccessLevel === AccessLevel.WRITE) {
                        setEditCpt(true);
                        setEditIndexCPT(index);
                      }
                    }
                  : undefined
              }
            >
              {cpt ? (
                <S.CptCellWrapper disabled={releasedStatus}>&nbsp;{cpt}</S.CptCellWrapper>
              ) : (
                <S.CptCellWrapper disabled={releasedStatus}>{'-'}</S.CptCellWrapper>
              )}
            </S.CptWrapper>
          ))}
          {editIndexCPT !== null && (
            <Dialog open={editCpt} onClose={() => setEditCpt(false)}>
              <ChooseCptCodeWithGroup
                onApplyClick={code => {
                  const services = row?.claim.services ?? [];
                  const updatedServices = !services.length
                    ? [
                        {
                          ...emptyService(),
                          cpt: code,
                          charges: row.chargeAmount?.[editIndexCPT] || null,
                          dateFrom: row.dateOfServices?.[editIndexCPT] || null,
                        },
                      ]
                    : services.map((s, i) =>
                        i === editIndexCPT
                          ? {
                              ...s,
                              cpt: code,
                              charges: row.chargeAmount?.[editIndexCPT] || s.charges,
                              dateFrom: row.dateOfServices?.[editIndexCPT] || s.dateFrom,
                            }
                          : s,
                      );

                  handleSave({
                    id: row.id,
                    services: updatedServices,
                    invoiceId: Number(params.invoiceId),
                    claimId: row.claimId,
                  });
                  setEditCpt(false);
                }}
                title={'CPT Codes'}
                currentValue={row?.claim.services?.[editIndexCPT]?.cpt ?? ''}
              />
            </Dialog>
          )}
        </>
      );
    }
    case 'RenderingProvider': {
      return (
        <>
          <S.CellWrapper
            disabled={releasedStatus}
            editable={invoiceSheetAccessLevel}
            onDoubleClick={
              !releasedStatus
                ? () =>
                    invoiceSheetAccessLevel === AccessLevel.WRITE &&
                    setEditRenderingProvider(true)
                : undefined
            }
          >
            {row.doctor?.profile?.firstName
              ? row.doctor?.profile?.firstName + ' ' + row.doctor?.profile?.lastName
              : '-'}
          </S.CellWrapper>

          <Dialog
            open={editRenderingProvider}
            onClose={() => setEditRenderingProvider(false)}
          >
            <ChooseDoctorOrClinic
              onApplyClick={id => {
                !!id &&
                  handleSave({
                    id: row.id,
                    doctorId: id,
                    invoiceId: Number(params.invoiceId),
                    claimId: row.claimId,
                  });
                setEditRenderingProvider(false);
              }}
              title={'Rendering Provider'}
              onlyDoctor
              currentValue={row?.doctor?.id}
            />
          </Dialog>
        </>
      );
    }
    case 'ServiceLoc': {
      return (
        <>
          <S.CellWrapper
            disabled={releasedStatus}
            editable={invoiceSheetAccessLevel}
            onDoubleClick={
              !releasedStatus
                ? () =>
                    invoiceSheetAccessLevel === AccessLevel.WRITE &&
                    setEditServiceLocation(true)
                : undefined
            }
          >
            {row.clinic?.name ? row.clinic?.name : '-'}
          </S.CellWrapper>

          <Dialog
            open={editServiceLocation}
            onClose={() => setEditServiceLocation(false)}
          >
            <ChooseDoctorOrClinic
              onApplyClick={id => {
                !!id &&
                  handleSave({
                    id: row.id,
                    clinicId: id,
                    invoiceId: Number(params.invoiceId),
                    claimId: row.claimId,
                  });
                setEditServiceLocation(false);
              }}
              title={'Service Loc'}
              onlyClinic
              currentValue={row?.clinic?.id}
            />
          </Dialog>
        </>
      );
    }
    case 'BillingProvider': {
      return (
        <>
          <S.CellWrapper
            disabled={releasedStatus}
            editable={invoiceSheetAccessLevel}
            onDoubleClick={
              !releasedStatus
                ? () =>
                    invoiceSheetAccessLevel === AccessLevel.WRITE &&
                    setEditBillingProvider(true)
                : undefined
            }
          >
            {row.billingProviderId
              ? row.billingProvider?.facilityName
              : row.billingProviderAsDoctorId
                ? row.billingProviderAsDoctor?.profile?.firstName +
                  ' ' +
                  row.billingProviderAsDoctor?.profile?.lastName
                : '-'}
          </S.CellWrapper>

          <Dialog
            open={editBillingProvider}
            onClose={() => setEditBillingProvider(false)}
          >
            <ChooseDoctorOrFacility
              onApplyClick={(id, tab) => {
                !!id &&
                  handleSave({
                    id: row.id,
                    billingProviderId: tab === ChooseProviderTab.Clinic ? id : null,
                    billingProviderAsDoctorId:
                      tab === ChooseProviderTab.Doctor ? id : null,
                    invoiceId: Number(params.invoiceId),
                    claimId: row.claimId,
                  });
                setEditBillingProvider(false);
              }}
              currentValue={
                row.billingProviderId
                  ? row.billingProviderId
                  : row.billingProviderAsDoctorId
                    ? row.billingProviderAsDoctorId
                    : undefined
              }
              defaultDoctorTab={!!row.billingProviderAsDoctorId}
            />
          </Dialog>
        </>
      );
    }
    case 'BillingLoc': {
      return (
        <>
          <S.CellWrapper
            disabled={releasedStatus}
            editable={invoiceSheetAccessLevel}
            onDoubleClick={
              !releasedStatus
                ? () =>
                    invoiceSheetAccessLevel === AccessLevel.WRITE &&
                    setEditBillingLocation(true)
                : undefined
            }
          >
            {row.billingLocation?.name ? row.billingLocation?.name : '-'}
          </S.CellWrapper>

          <Dialog
            open={editBillingLocation}
            onClose={() => setEditBillingLocation(false)}
          >
            <ChooseDoctorOrClinic
              onApplyClick={id => {
                !!id &&
                  handleSave({
                    id: row.id,
                    billingLocationId: id,
                    invoiceId: Number(params.invoiceId),
                    claimId: row.claimId,
                  });
                setEditBillingLocation(false);
              }}
              title={'Billing Loc'}
              onlyClinic
              currentValue={row?.billingLocation?.id}
            />
          </Dialog>
        </>
      );
    }
    // case 'ChargeAmount': {
    //   return (
    //     <>
    //       {(!!row?.chargeAmount?.length ? row.chargeAmount : [null]).map(
    //         (charge, index) => (
    //           <S.ChargeWrapper
    //             disabled={releasedStatus}
    //             key={index}
    //             editable={invoiceSheetAccessLevel}
    //             onDoubleClick={
    //               !releasedStatus
    //                 ? () => {
    //                     if (invoiceSheetAccessLevel === AccessLevel.WRITE) {
    //                       if (charge === null) {
    //                         setAmount(amount);
    //                         setEditIndexChargeAmount(index);
    //                       } else {
    //                         setAmount(charge || 0);
    //                         setEditIndexChargeAmount(index);
    //                       }
    //                     }
    //                   }
    //                 : undefined
    //             }
    //           >
    //             {editIndexChargeAmount === index ? (
    //               <ClickAwayListener
    //                 mouseEvent="onMouseDown"
    //                 touchEvent="onTouchStart"
    //                 onClickAway={() => {
    //                   if (charge !== amount) {
    //                     const services =
    //                       !row.chargeAmount || row.chargeAmount.length === 0
    //                         ? [
    //                             {
    //                               ...emptyService(),
    //                               charges: amount,
    //                               dateFrom: row?.dateOfServices?.[index] || null,
    //                               cpt: row?.cpt?.[index] || null,
    //                             },
    //                           ]
    //                         : Array(row.chargeAmount?.length || 0)
    //                             .fill(null)
    //                             .map((_, i) =>
    //                               i === editIndexChargeAmount
    //                                 ? {
    //                                     ...(row?.claim?.services?.[i] || emptyService()),
    //                                     charges: amount,
    //                                     dateFrom: row?.dateOfServices?.[i] || null,
    //                                     cpt: row?.cpt?.[i] || null,
    //                                   }
    //                                 : row?.claim?.services?.[i] || emptyService(),
    //                             );
    //
    //                     handleSave({
    //                       id: row.id,
    //                       invoiceId: Number(params.invoiceId),
    //                       claimId: row.claimId,
    //                       services: services,
    //                     });
    //                   }
    //                   setEditIndexChargeAmount(null);
    //                 }}
    //               >
    //                 <S.InputWrapper>
    //                   <InputCellNumber
    //                     InputProps={{
    //                       endAdornment: (
    //                         <InputAdornment position="end">
    //                           <IconButton
    //                             disableRipple={true}
    //                             aria-label="toggle password visibility"
    //                           >
    //                             USD
    //                           </IconButton>
    //                         </InputAdornment>
    //                       ),
    //                     }}
    //                     type="number"
    //                     value={amount}
    //                     focused={true}
    //                     onChange={(e: ChangeEvent<HTMLInputElement>) =>
    //                       setAmount(+e.target.value)
    //                     }
    //                   />
    //                 </S.InputWrapper>
    //               </ClickAwayListener>
    //             ) : (
    //               <>{charge !== null && charge !== undefined ? `${charge} USD` : '-'}</>
    //             )}
    //           </S.ChargeWrapper>
    //         ),
    //       )}
    //     </>
    //   );
    // }
    case 'ChargeAmount': {
      return (
        <S.ChargeWrapper>
          {row?.claim?.totalCharge !== null && row?.claim?.totalCharge !== undefined
            ? `${row?.claim?.totalCharge} USD`
            : '-'}
        </S.ChargeWrapper>
      );
    }
    case 'Notes': {
      return (
        <S.CellWrapper
          disabled={releasedStatus}
          editable={invoiceSheetAccessLevel}
          onDoubleClick={
            !releasedStatus
              ? () => {
                  if (invoiceSheetAccessLevel === AccessLevel.WRITE) {
                    setNote(row.note || '');
                    setEditNote(true);
                  }
                }
              : undefined
          }
        >
          {editNote ? (
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => {
                setEditNote(false);
                editNote &&
                  note !== row.note &&
                  !(note === '' && row.note === null) &&
                  handleSave({
                    id: row.id,
                    note: note,
                    invoiceId: Number(params.invoiceId),
                    claimId: row.claimId,
                  });
              }}
            >
              <S.InputWrapper>
                <InputCell
                  multiline
                  rows={3}
                  value={note}
                  focused={true}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setNote(e.target.value)}
                />
              </S.InputWrapper>
            </ClickAwayListener>
          ) : (
            <>{row.note ? row.note : '-'}</>
          )}
        </S.CellWrapper>
      );
    }
    case 'Status': {
      return <S.Link className={'simply'}>{row.claim?.status}</S.Link>;
    }
    case 'Claim': {
      return (
        <S.Link onClick={() => !!row.claimId && navigate(`/claim-form/${row.claimId}`)}>
          {row.claimId ? '#' + row.claimId : '-'}
        </S.Link>
      );
    }

    default:
      // @ts-ignore
      return row[header] || '-';
  }
};

export default InvInfData;
