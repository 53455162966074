import { useNavigate, useParams } from 'react-router-dom';
import { AlertItem, Button, TabsItem } from 'components';
import * as S from './styles';
import { ArrowLeftSVG, CloseBtnSVG, ConfirmBtnSVG } from 'assets/icons';
import { Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppointmentStatus } from 'types/StatusTypes';
import { setMessage } from 'store/slices/message';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';
import FacilityDoctors from '../Facilities/components/FacilityDoctors';
import FacilityProfileInformation from './components/FacilityProfileInformation/FacilityProfileInformation';
import {
  useFacilitiesGetOneQuery,
  useFacilityUpdateMutation,
} from '../../../../store/api/facilities/facilityApi';

const ProfileFacility = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { role } = useAppSelector(state => state.auth);

  const facilityInfo = useFacilitiesGetOneQuery({
    id: params.facilityId,
  });

  const [updateStatus, statusUpdate] = useFacilityUpdateMutation();

  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();

  const [condition, setCondition] = useState<Array<string>>([]);

  useEffect(() => {
    setCondition([]);
    if (facilityInfo.currentData?.status === AppointmentStatus.DEACTIVATE.toUpperCase()) {
      setCondition(prev => [...prev]);
    }
  }, [facilityInfo]);

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText onClick={() => navigate('/facilities')}>
          <ArrowLeftSVG /> Back
        </S.SubtitleText>
        <S.SubtitleItems></S.SubtitleItems>
      </S.SubtitleBar>
      <S.SubtitleItems>
        <S.TabWrapper>
          <TabsItem tabIndex={tabIndex} setTabIndex={setTabIndex}>
            <Tab disableRipple label="Facility info" />
          </TabsItem>
        </S.TabWrapper>

        {facilityInfo.currentData?.status.toLowerCase() ===
          AppointmentStatus.DEACTIVATE &&
          role === Roles.SUPER_ADMIN && (
            <>
              <AlertItem
                text="Facility has been deactivated and you will not be able to assign Doctors to it or use billing data in Claims. If you want to make it active again, click the Activate button"
                condition={condition}
                type="warning"
                height="68px"
                $isHideButton={true}
                $isDefault={true}
              />
              <S.ButtonWrapper>
                <Button
                  onClick={() =>
                    updateStatus({
                      id: params.facilityId,
                      status: AppointmentStatus.ACTIVE.toUpperCase(),
                    })
                      .unwrap()
                      .then(res =>
                        dispatch(
                          setMessage({
                            message: 'Facility was successfully activated',
                            type: 'success',
                          }),
                        ),
                      )
                      .catch(err =>
                        dispatch(
                          setMessage({
                            message: 'Something went wrong',
                            type: 'error',
                          }),
                        ),
                      )
                  }
                  text="Activate facility"
                  backgroundColor="#79A030"
                  disabled={
                    // clinicInfo.currentData?.hasReceptionists === false ||
                    facilityInfo.isLoading || facilityInfo.isFetching
                  }
                >
                  <ConfirmBtnSVG
                    className={
                      facilityInfo.isLoading || facilityInfo.isFetching ? 'disabled' : ''
                    }
                  />
                </Button>
              </S.ButtonWrapper>
            </>
          )}
        {facilityInfo.currentData?.status.toLowerCase() === AppointmentStatus.ACTIVE &&
          role === Roles.SUPER_ADMIN && (
            <S.ButtonWrapper>
              <Button
                onClick={() =>
                  updateStatus({
                    id: params.facilityId,
                    status: AppointmentStatus.DEACTIVATE.toUpperCase(),
                  })
                    .unwrap()
                    .then(res =>
                      dispatch(
                        setMessage({
                          message: 'Facility was successfully deactivated',
                          type: 'success',
                        }),
                      ),
                    )
                    .catch(err =>
                      dispatch(
                        setMessage({
                          message: 'Something went wrong',
                          type: 'error',
                        }),
                      ),
                    )
                }
                text="Deactivate facility"
                backgroundColor="#C66060"
                disabled={facilityInfo.isLoading || facilityInfo.isFetching}
              >
                <CloseBtnSVG className="white" />
              </Button>
            </S.ButtonWrapper>
          )}
      </S.SubtitleItems>
      <S.Content>
        <S.ProfileInformation>
          <FacilityProfileInformation />
        </S.ProfileInformation>

        <S.TabInformation>
          <S.TabContent>{tabIndex === 0 && <FacilityDoctors deletable />}</S.TabContent>
        </S.TabInformation>
      </S.Content>
    </S.Wrapper>
  );
};

export default ProfileFacility;
