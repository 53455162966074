import { useParams } from 'react-router-dom';
import { DeleteRedBtnSVG, JPGSVG, PDFSVG } from '../../../../../../assets/icons';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import * as S from './styles';
import { Accordeon, CircularLoader, Dialog } from '../../../../../../components';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import {
  useFoldersDocumentsGetQuery,
  useFormsDocumentsMutation,
} from '../../../../../../store/api/treatment/treatmentApi';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import UserStatusPin from '../../../../../../components/atoms/UserStatusPin';
import { Roles } from '../../../../../../types/Roles';
import { setMessage } from '../../../../../../store/slices/message';
import ConfirmDeleteDialog from '../../../../../../components/molecules/ConfirmDeleteDialog';
import { useDeleteManualFileMutation } from '../../../../../../store/api/files/files';

const HealthRecords = () => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const id = useAppSelector(state => state.auth.id);
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const createdById = params.patientId ? params.patientId : id;

  const getFolders = useFoldersDocumentsGetQuery({
    clinicId: selectedClinic.id,
    userId: createdById,
  });
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [appointmentId, setAppointmentId] = useState<string | null>(null);
  const userInfo = useAppSelector(state => state.auth);
  const [getFormsDocuments, formsDocsStatus] = useFormsDocumentsMutation({});
  const [idForDelete, setIdForDelete] = useState<string>('');
  const [deleteManualFile, deleteManualStatus] = useDeleteManualFileMutation({});
  const handleExpanded = (id: string) => {
    setExpanded(expanded === id ? null : id);
  };
  const handleSuccessDelete = () => {
    if (idForDelete) {
      deleteManualFile({ id: idForDelete })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Document was successfully deleted',
              type: 'success',
            }),
          );
        })
        .catch(err => {
          dispatch(setMessage({ message: err.data.message, type: 'error' }));
        });
    }
  };
  const sortDocuments = (documents: any[]) => {
    return [...documents].sort((a, b) => {
      if (a.docType !== 'MANUAL' && b.docType === 'MANUAL') {
        return -1;
      }
      if (a.docType === 'MANUAL' && b.docType !== 'MANUAL') {
        return 1;
      }
      if (a.docType === 'MANUAL' && b.docType === 'MANUAL') {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      }
      return 0;
    });
  };

  useEffect(() => {
    if (appointmentId) {
      getFormsDocuments({
        userId: createdById,
        clinicId: selectedClinic.id,
        appointmentId: appointmentId,
      })
        .unwrap()
        .then();
    }
  }, [getFolders.data]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.EditBtnWrapper>
          <S.Article>Health Records</S.Article>
        </S.EditBtnWrapper>
        <Dialog
          open={isOpenSuccessDialog}
          onClose={() => {
            setIdForDelete('');
            setIsOpenSuccessDialog(!isOpenSuccessDialog);
          }}
        >
          <ConfirmDeleteDialog
            onClose={() => setIsOpenSuccessDialog(!isOpenSuccessDialog)}
            titleText={'file'}
            onSuccess={handleSuccessDelete}
          />
        </Dialog>
        <S.Files>
          {getFolders.data?.appointmentsForms.map((item, index) => {
            return (
              item.formsCount > 0 && (
                <Accordeon
                  key={index}
                  expanded={expanded === item.appointmentId}
                  title={'Appointment: #' + item.appointmentId.slice(0, 8)}
                  clinic={getFolders.data?.clinicName}
                  appointmentForms={item}
                  docsCount={item.formsCount}
                  button={'Add file'}
                  onClick={() => {
                    setAppointmentId(item.appointmentId);
                    handleExpanded(item.appointmentId);
                    getFormsDocuments({
                      userId: createdById,
                      clinicId: selectedClinic.id,
                      appointmentId: item.appointmentId,
                    });
                  }}
                >
                  <S.AccordeonContent onClick={e => e.stopPropagation()}>
                    {formsDocsStatus.isLoading ? (
                      <S.Loader>
                        <CircularLoader color="#0084B1"></CircularLoader>
                      </S.Loader>
                    ) : (
                      <S.AccordeonItems>
                        {sortDocuments(formsDocsStatus.data || []).map(item => (
                          <S.FileRow
                            key={item.id}
                            onClick={() => {
                              window.open(item.url);
                            }}
                          >
                            <S.InfoRow>
                              {item.name.includes('.pdf') ? (
                                <PDFSVG className="icon" />
                              ) : (
                                <JPGSVG className="icon" />
                              )}
                              <S.FileName
                                title={item.name}
                                isManual={item.docType === 'MANUAL'}
                              >
                                {item.name}
                              </S.FileName>
                              {item.docType === 'MANUAL' && (
                                <UserStatusPin type={'additionally'} />
                              )}
                            </S.InfoRow>
                            <S.CreatedBy>
                              <S.Text className="small">
                                Date: {dayjs(item.createdAt).format('MM/DD/YYYY')}
                              </S.Text>
                              {item.createdByDoctor?.profile?.firstName ? (
                                <S.Text>
                                  Created by:{' '}
                                  {item.createdByDoctor?.profile?.firstName +
                                    ' ' +
                                    item.createdByDoctor?.profile?.lastName}
                                </S.Text>
                              ) : (
                                <S.Text>Created by: -</S.Text>
                              )}
                              <S.EmptyBtn />
                            </S.CreatedBy>
                            {userInfo.role !== Roles.PATIENT &&
                            item.docType === 'MANUAL' ? (
                              <S.DeleteIconWrapper>
                                <DeleteRedBtnSVG
                                  width={20}
                                  onClick={e => {
                                    setIdForDelete(item.id);
                                    e.stopPropagation();
                                    setIsOpenSuccessDialog(true);
                                  }}
                                />
                              </S.DeleteIconWrapper>
                            ) : (
                              <S.EmptyRow></S.EmptyRow>
                            )}
                          </S.FileRow>
                        ))}
                      </S.AccordeonItems>
                    )}
                  </S.AccordeonContent>
                </Accordeon>
              )
            );
          })}
        </S.Files>
      </S.Content>
    </S.Wrapper>
  );
};

export default HealthRecords;
