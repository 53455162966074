import { Button, DatePicker, Input } from 'components';
import * as S from './styles';
import { useState } from 'react';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useDoctorAssignToFacilityMutation } from '../../../../../../store/api/facilities/facilityApi';
import { GetDoctorsFacilityList } from './GetDoctorsFacilityList';
import {
  AddCircleBlueSVG,
  DeleteBtnSVG,
  DeleteRedBtnSVG,
} from '../../../../../../assets/icons';
import { DoctorsDate } from '../../../../../../types/FacilityTypes';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
  onClose: () => void;
  facilityId?: string;
  getDoctorsList?: DoctorsDate[];
};

const AssignDoctorDialog = ({ onClose, facilityId, getDoctorsList }: Props) => {
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [assignOn] = useDoctorAssignToFacilityMutation({});
  const [firstWorkDay, setFirstWorkDay] = useState<Dayjs>(dayjs());
  const [lastWorkDay, setLastWorkDay] = useState<Dayjs | string>('');
  const [rows, setRows] = useState([{ taxonomy: '', taxonomyName: '' }]);
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<{ taxonomy: boolean; taxonomyName: boolean }[]>([
    { taxonomy: false, taxonomyName: false },
  ]);
  const [dateErrors, setDateErrors] = useState<{
    firstWorkDay: boolean;
    // lastWorkDay: boolean;
  }>({
    firstWorkDay: false,
    // lastWorkDay: false,
  });
  const handleAddRow = () => {
    setRows([...rows, { taxonomy: '', taxonomyName: '' }]);
    setErrors([...errors, { taxonomy: false, taxonomyName: false }]);
  };

  const handleRemoveRow = (index: number) => {
    if (rows.length > 1) {
      setRows(rows.filter((_, i) => i !== index));
      setErrors(errors.filter((_, i) => i !== index));
    }
  };

  const handleInputChange = (
    index: number,
    field: keyof (typeof rows)[0],
    value: string,
  ) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
    const updatedErrors = [...errors];
    updatedErrors[index][field] = !value.trim();
    setErrors(updatedErrors);
  };

  const validateFields = () => {
    const newErrors = rows.map(row => ({
      taxonomy: !row.taxonomy.trim(),
      taxonomyName: !row.taxonomyName.trim(),
    }));
    const newDateErrors = {
      firstWorkDay: !firstWorkDay,
      // lastWorkDay: !lastWorkDay,
    };

    setErrors(newErrors);
    setDateErrors(newDateErrors);

    return (
      newErrors.every(err => !err.taxonomy && !err.taxonomyName) &&
      !newDateErrors.firstWorkDay
      //&& !newDateErrors.lastWorkDay
    );
  };

  const handleAssign = () => {
    if (!selectedValue) {
      dispatch(setMessage({ message: 'Please select a doctor', type: 'error' }));
      return;
    }

    if (!validateFields()) {
      dispatch(
        setMessage({ message: 'Please fill in all required fields', type: 'error' }),
      );
      return;
    }

    assignOn({
      facilityId,
      doctorId: selectedValue,
      taxonomyes: rows,
      startDate: dayjs(firstWorkDay).format('YYYY-MM-DD'),
      ...(lastWorkDay && {
        endDate: dayjs(lastWorkDay).format('YYYY-MM-DD'),
      }),
    })
      .unwrap()
      .then(() => {
        onClose();
        dispatch(
          setMessage({
            message: 'Doctor was successfully assigned to facility',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        onClose();
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };
  return (
    <S.Content>
      <S.Article>Assign Doctor</S.Article>
      <S.InputWrapper>
        <S.InputAutoCompleteWrapper>
          <GetDoctorsFacilityList
            setSelectedValue={setSelectedValue}
            selectedValue={selectedValue}
            getDoctorsList={getDoctorsList}
          />
        </S.InputAutoCompleteWrapper>
      </S.InputWrapper>
      <S.InputDateWrapper>
        <DatePicker
          isRequired
          label="First work day"
          value={firstWorkDay}
          onChange={value => setFirstWorkDay(value)}
          error={dateErrors.firstWorkDay}
        />
        <DatePicker
          // isRequired
          label="Last work day"
          value={lastWorkDay}
          onChange={value => setLastWorkDay(value)}
          // error={dateErrors.lastWorkDay}
        />
      </S.InputDateWrapper>
      <S.InputTaxonomyWrapper>
        <S.Taxonomy>Taxonomy</S.Taxonomy>
        <S.AddTaxonomy onClick={handleAddRow}>
          <AddCircleBlueSVG />
          Add Taxonomy
        </S.AddTaxonomy>
      </S.InputTaxonomyWrapper>

      <S.InputWrapper>
        {rows.map((row, index) => (
          <S.InputRow key={index}>
            <Input
              id={`taxonomy-${index}`}
              name={`taxonomy-${index}`}
              label="Taxonomy"
              value={row.taxonomy}
              isRequired
              onChange={e => handleInputChange(index, 'taxonomy', e.target.value)}
              error={errors[index]?.taxonomy}
            />
            <Input
              id={`taxonomyName-${index}`}
              name={`taxonomyName-${index}`}
              label="Taxonomy Name"
              value={row.taxonomyName}
              isRequired
              onChange={e => handleInputChange(index, 'taxonomyName', e.target.value)}
              error={errors[index]?.taxonomyName}
            />
            {rows.length > 1 ? (
              <S.SVGWrapper disable={false}>
                <DeleteRedBtnSVG onClick={() => handleRemoveRow(index)} />
              </S.SVGWrapper>
            ) : (
              <S.SVGWrapper disable>
                <DeleteBtnSVG />
              </S.SVGWrapper>
            )}
          </S.InputRow>
        ))}
      </S.InputWrapper>

      <S.ButtonWrapper>
        <Button text="Assign" disabled={!selectedValue} onClick={handleAssign} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default AssignDoctorDialog;
