import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';
import { Input } from '@mui/material';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  gap: 10px;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  background: #fff;
  border-radius: 5px;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: row;
  overflow: auto;
  overflow-x: hidden;
`;

export const Content = styled.div`
  background: #fff;
  border-radius: 5px;
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: auto;
  padding-bottom: 30px;
`;

export const AlphabetInputs = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  align-items: center;

  svg {
    margin-top: 15px;
    cursor: pointer;

    &:first-child {
      margin-top: 2px;
    }
  }
`;

export const CardType = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  height: 20px;
  max-height: 20px;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  /* max-width: 65px; */
  width: fit-content;
  line-height: normal;
  padding: 2px 20px;
  border-radius: 3px;
  background: ${props => props.color && props.color};
`;

export const ButtonWrapper = styled.div`
  width: 180px;
`;

export const ShowHistory = styled.div<{ $isShow: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 30px;

  cursor: pointer;
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  padding: 13px 10px;
  font-style: normal;
  border-radius: 5px;
  font-weight: 500;
  line-height: normal;
  transition: 0.5s;
  background: ${props => props.$isShow && '#E1EBEE'};

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
`;

// export const Items = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: start;
//   width: 100%;
// `;

export const Footer = styled.div`
  display: flex;
  justify-content: end;
  height: 40px;
  min-height: 40px;
  gap: 20px;
`;

export const RadioItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const RadioItemRelationship = styled.div<{ hasError: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  border-radius: ${props => (props.hasError ? '5px' : 'none')};
  border: ${props => (props.hasError ? '1px solid red' : 'none')};
`;
export const RadioButtons = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 5px;

  &.error {
    border: 1px solid red;
    border-radius: 5px;
  }
`;

export const RequiredSpan = styled.div`
  color: #c66060;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;

  &.px480 {
    width: 480px;
  }

  &.px240 {
    width: 240px;
  }
  &.px180 {
    width: 180px;
  }
  &.px90 {
    width: 90px;
  }
  &.all {
    width: 100%;
  }

  &.percent33 {
    width: calc(33% - 12px);
  }
  &.percent50 {
    width: calc(50% - 10px);
  }
`;

export const TrashInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const Placeholder = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
`;

export const ConnectLine = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
`;

export const Article = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const BottomLine = styled.div`
  width: 100%;
  background-color: #d7d7d7;
  height: 1px;
`;

export const InputAutoCompleteWrapper = styled.div`
  width: 100%;

  & .MuiButtonBase-root.MuiChip-root {
    color: var(--icon, #848a9b);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 5px;
    margin-right: 10px;
    background: #e5f0f6;
    border: none;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiAutocomplete-root {
    padding: 0;

    & .Mui-focused {
      border: 0.5px solid var(--line, #0084b1) !important;
      border-radius: 5px;
    }
  }

  & .MuiInputBase-root {
    background: #f9f9fc;
    border: 0.5px solid var(--line, #d7d7d7);
    border-radius: 5px;
    padding: 9px 14px;
    line-height: normal;
    //height: 38px;

    color: var(--text, #202e5f);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & .MuiInputBase-input {
    padding: 0;
  }

  & .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0;
  }
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  &.biggap {
    gap: 40px;
  }

  &.withoutLabel {
    margin-top: 18px;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.end {
    justify-content: flex-end;
  }
`;

export const Btn = styled.div<{ disabled?: boolean }>`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  align-items: center;
  gap: 10px;
  color: ${({ disabled }) => (disabled ? 'gray' : 'var(--main, #0084b1)')};
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const LineFromBottom = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  margin-bottom: 0px;
`;

export const EmptyDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  &.smallGap {
    gap: 4px;
  }

  &.margin {
    gap: 4px;
    margin-top: 15px;
  }
`;

export const SmallArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 40px;
`;

export const GrayBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  border-radius: 5px;
  background: #f2f2f5;
  margin: 0px 10px;
  padding: 20px 40px;
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Caption = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Gap = styled.div`
  margin: 12px 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Required = styled.span`
  color: #c66060;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HelperText = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 300px;
`;

export const LabelArticle = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 10px;

  &.error {
    color: #d32f2f;
  }
`;

export const SubtitleItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 50px;
`;

export const SubtitleBar = styled.div`
  background-color: #f9f9fc;
  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 1000;
  height: 82px;
  display: flex;
  border-left: 1px solid #ddd;
  align-items: center;
  justify-content: space-between;

  @media ${devices.xs} {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: center;
    height: auto;

    @media ${devices.xs} {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;

export const SubtitleText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 20px;
  display: flex;
  gap: 10px;
  align-items: center;

  svg {
    cursor: pointer;
  }

  @media ${devices.xs} {
  }
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  &.twoColumn {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.pl} {
    grid-template-columns: 1fr;
  }
`;

export const Card = styled.div`
  border-radius: 5px;
  background: #f2f2f5;
  padding: 20px;
  min-height: 174px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ModifierWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-width: 200px;
`;

export const SearchBtn = styled.div<{ disabled?: boolean }>`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  align-items: center;
  gap: 5px;
  color: ${({ disabled }) => (disabled ? 'gray' : 'var(--main, #0084b1)')};
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  width: fit-content;
  line-height: normal;
  justify-content: center;
  margin-top: 15px;
  margin-right: 30px;
  margin-left: -15px;

  svg {
    path {
      stroke: ${({ disabled }) => (disabled ? 'gray' : '#0084b1')};
    }
    ellipse {
      stroke: ${({ disabled }) => (disabled ? 'gray' : '#0084b1')};
    }
  }
`;
