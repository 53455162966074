import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as S from './styles';
import {
  ArrowLeftSVG,
  ErrorHistoricalMessageSVG,
  ErrorMessageSVG,
  ListCheckSVG,
  PrintWhiteSVG,
} from 'assets/icons';
import { Button, CircularLoader, Dialog } from 'components';
import { PatientInfoClaim } from './components/PatientInfoClaim';
import { ServicesTable } from './components/ServicesTable';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { ClaimValues } from 'types/AppointmentNewTypes';
import {
  useCreateClaimBlankMutation,
  useCreateClaimFromPatientMutation,
  useGetClaimByIdMutation,
  useLazyPatientGetOneQuery,
  useReleaseClaimMutation,
  useUpdateClaimPatchMutation,
  useValidateClaimMutation,
} from 'store/api/claims/claimsApi';
import {
  insuranceClaimForm,
  insuranceFromUser,
  patientClaimForm,
  patientFromUser,
} from '../../formikClaim';
import { ClaimStatusType, ClaimTypeEnum } from 'types/InvoiceType';
import { getClaimTypeColor } from 'helpers/UserStatusPinColors/getClaimTypeColor';
import { getClaimConfig } from '../ClaimColumn/config';
import ClaimHistory from '../ClaimHistory';
import { InsuranceInfoClaim } from './components/InsuranceInfoClaim';
import { InjuryBlock } from './components/InjuryBlock';
import { ProviderBlock } from './components/ProviderBlock';
import { setMessage } from 'store/slices/message';
import { useAppSelector } from 'hooks/useAppSelector';
import { ucFirst } from 'helpers/functions/toUpperCase';
import { GenerateClaimFormPdf } from 'pages/Billing/components/Claims/components/ClaimForm/components/GenerateClaimFormPdf';
import dayjs from 'dayjs';

export enum ClaimActionEnum {
  SAVE = 'save',
  VALIDATE = 'validate',
  RELEASE = 'release',
}

const ClaimForm = () => {
  const navigate = useNavigate();
  const { claimId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [getClaimById, status] = useGetClaimByIdMutation({});
  const [getPatientById, patientStatus] = useLazyPatientGetOneQuery({});
  const [updateClaim, statusUpdating] = useUpdateClaimPatchMutation({});
  const [createClaim, statusCreating] = useCreateClaimFromPatientMutation({});
  const [createBlankClaim, statusBlankCreating] = useCreateClaimBlankMutation({});
  const [validateClaim, statusValidating] = useValidateClaimMutation({});
  const [releaseClaim, statusReleasing] = useReleaseClaimMutation({});
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const [showPdf, setShowPdf] = useState(false);
  const [renderingProviderId, setRenderingProviderId] = useState<
    string | null | undefined
  >(null);
  const [billingEin, setBillingEin] = useState<string | null | undefined>(null);

  const [isSave, setIsSave] = useState<ClaimActionEnum | null>(null);

  const [isReadyPatient, setIsReadyPatient] = useState(false);
  const [isReadyInsurance, setIsReadyInsurance] = useState(false);
  const [isReadyInjury, setIsReadyInjury] = useState(false);
  const [isReadyServices, setIsReadyServices] = useState(false);
  const [isReadyProvider, setIsReadyProvider] = useState(false);
  const [formData, setFormData] = useState<Partial<ClaimValues> | null>(null);
  const [isShowClaimHistory, setIsShowClaimHistory] = useState(false);
  const [disableAttachments, setDisableAttachments] = useState(false);
  const modalControlsRef = useRef<{
    openModal: () => void;
    closeModal: () => void;
  } | null>(null);
  const [isDirtyForm, setIsDirtyForm] = useState(false);

  const fromClaimId = searchParams.get('claimId');
  const fromPatientId = searchParams.get('patientId');

  const location = useLocation();
  const { claimType } = location.state || {};
  const isBlankClaim = claimType === 'Blank' || status?.data?.claimType === 'Blank';
  const isExistClaim = claimType === 'On the previous Claim';
  const handleOpenModal = () => {
    modalControlsRef.current?.openModal();
  };

  const onSaveClick = async (action: ClaimActionEnum) => {
    setIsReadyPatient(false);
    setIsReadyInsurance(false);
    setIsReadyInjury(false);
    setIsReadyServices(false);
    setIsReadyProvider(false);
    setIsSave(action);
  };

  useEffect(() => {
    (async () => {
      if (
        isReadyPatient &&
        isReadyInsurance &&
        isReadyInjury &&
        isReadyServices &&
        isReadyProvider &&
        formData &&
        isSave
      ) {
        const data = {
          payload: {
            defaultToGroupHealthPlan: formData?.defaultToGroupHealthPlan || undefined,
            patientName: formData?.patientName?.trim() || undefined,
            patientBirthDate: formData?.patientBirthDate
              ? dayjs(formData?.patientBirthDate).format('YYYY-MM-DD')
              : undefined,
            patientGender: formData?.patientGender
              ? ucFirst(formData.patientGender)
              : undefined,
            patientAddress: formData?.patientAddress?.trim() || undefined,
            patientCity: formData?.patientCity?.trim() || undefined,
            patientState: formData?.patientState || undefined,
            patientZip: formData?.patientZip?.trim() || undefined,
            patientPhone: formData?.patientPhone || null,
            isPatientEmployment: formData?.isPatientEmployment ?? undefined,
            isPatientAutoAccident: formData?.isPatientAutoAccident ?? undefined,
            isPatientOtherAccident: formData?.isPatientOtherAccident ?? undefined,
            state: formData?.state || '',

            insuredIdNumber: formData?.insuredIdNumber?.trim() || undefined,
            patientRelationshipToInsured:
              formData?.patientRelationshipToInsured || undefined,
            insuredName: formData?.insuredName?.trim() || undefined,
            insuredAddress: formData?.insuredAddress?.trim() || undefined,
            insuredCity: formData?.insuredCity?.trim() || undefined,
            insuredState: formData?.insuredState || undefined,
            insuredZip: formData?.insuredZip?.trim() || undefined,
            insuredPhone: formData?.insuredPhone || null,
            reservedForNUCC: formData?.reservedForNUCC?.trim() || null,
            otherInsuredName: formData?.otherInsuredName?.trim() || null,
            insuredPolicyGroupFECANumber:
              formData?.insuredPolicyGroupFECANumber?.trim() || undefined,
            insuredBirthDate: formData?.insuredBirthDate
              ? dayjs(formData?.insuredBirthDate).format('YYYY-MM-DD')
              : undefined,
            insuredGender: formData.insuredGender
              ? ucFirst(formData.insuredGender)
              : undefined,
            otherClaimIdType: formData?.otherClaimIdType || null,
            otherClaimId: formData?.otherClaimId?.trim() || null,
            insurancePlanNameOrProgramName:
              formData?.insurancePlanNameOrProgramName?.trim() || undefined,
            insuredPayerId: formData?.insuredPayerId?.trim() || undefined,
            isAnotherHealthBenefitPlan: formData?.isAnotherHealthBenefitPlan ?? undefined,
            patientSignature: formData?.patientSignature || undefined,
            patientSignatureDate: formData?.patientSignatureDate
              ? dayjs(formData?.patientSignatureDate).format('YYYY-MM-DD')
              : undefined,
            insuredSignature: formData?.insuredSignature || undefined,
            //insuredSignatureDate: new Date(),

            dateOfIllness: formData?.dateOfIllness
              ? dayjs(formData?.dateOfIllness).format('YYYY-MM-DD')
              : null,
            dateOfIllnessQual: formData?.dateOfIllnessQual || null,
            otherDate: formData?.otherDate
              ? dayjs(formData?.otherDate).format('YYYY-MM-DD')
              : null,
            otherDateQual: formData?.otherDateQual || null,
            unableToWorkStartDate: formData?.unableToWorkStartDate
              ? dayjs(formData?.unableToWorkStartDate).format('YYYY-MM-DD')
              : null,
            unableToWorkEndDate: formData?.unableToWorkEndDate
              ? dayjs(formData?.unableToWorkEndDate).format('YYYY-MM-DD')
              : null,
            nameOfReferringProviderType: formData?.nameOfReferringProviderType || null,
            nameOfReferringProvider: formData?.nameOfReferringProvider?.trim() || null,
            referringProviderNpi: formData?.referringProviderNpi || null,
            hospitalizationStartDate: formData?.hospitalizationStartDate
              ? dayjs(formData?.hospitalizationStartDate).format('YYYY-MM-DD')
              : null,
            hospitalizationEndDate: formData?.hospitalizationEndDate
              ? dayjs(formData?.hospitalizationEndDate).format('YYYY-MM-DD')
              : null,
            isOutsideLab: formData?.isOutsideLab ?? undefined,
            labCharges: +(formData?.labCharges ?? 0),
            diagnosisCodes: formData?.diagnosisCodes?.length
              ? formData?.diagnosisCodes?.filter(
                  ({ code, type }) =>
                    !!code && {
                      code,
                      type,
                    },
                )
              : [],
            resubmissionCode: formData?.resubmissionCode || null,
            originalRef: formData?.originalRef?.trim() || null,
            priorAuthorizationNumber: formData?.priorAuthorizationNumber?.trim() || null,

            federalTaxId: formData?.federalTaxId?.trim() || undefined,
            isFederalSSN: formData?.isFederalEIN === 'SSN',
            isFederalEIN: formData?.isFederalEIN === 'EIN',
            patientAccountNumber: formData?.patientAccountNumber?.trim(),
            isAcceptAssignment: formData?.isAcceptAssignment ?? undefined,
            totalCharge: +(formData?.totalCharge ?? 0),
            additionalClaimInfo: formData?.additionalClaimInfo?.trim() || null,
            totalPaid: +(formData?.totalPaid ?? 0),
            services: formData?.services?.map(i => ({
              dateFrom: dayjs(i.dateFrom).format('YYYY-MM-DD'),
              dateTo: dayjs(i.dateTo).format('YYYY-MM-DD'),
              placeOfService: i.placeOfService?.trim(),
              emg: i.emg?.trim() || null,
              cpt: i.cpt?.trim(),
              modifier: i.modifier,
              pointer: i.pointer?.trim() || null,
              charges: i.charges.toString(),
              units: i.units?.trim(),
              epsdt: i.epsdt || null,
              idQual: i.idQual?.trim() || null,
              renderingProviderId: i.renderingProviderId || null,
            })),

            isFacilityRendering: formData?.isFhysicanRendering === 'isFacilityRendering',
            isFhysicanRendering: formData?.isFhysicanRendering === 'isFhysicanRendering',
            renderingName: formData?.renderingName?.trim() || undefined,
            renderingId: formData?.renderingId ?? undefined,
            renderingNpi: formData?.renderingNpi?.trim() ?? undefined,

            serviceFacilityName: formData?.serviceFacilityName?.trim() || undefined,
            serviceFacilityId: formData?.serviceFacilityId ?? undefined,
            serviceStreet: formData?.serviceStreet?.trim() || undefined,
            serviceOffice: formData?.serviceOffice?.trim() || undefined,
            serviceCity: formData?.serviceCity?.trim() || undefined,
            serviceState: formData?.serviceState || undefined,
            serviceZip: formData?.serviceZip?.trim() || undefined,
            serviceNpi: formData?.serviceNpi?.trim() ?? undefined,

            isFacilityBilling: formData?.isFacilityBilling === 'isFacilityBilling',
            isFhysicanBilling: formData?.isFacilityBilling === 'isFhysicanBilling',
            billingFacilityName: formData?.billingFacilityName?.trim() ?? undefined,
            billingFacilityId: formData?.billingFacilityId ?? undefined,
            billingStreet: formData?.billingStreet?.trim() || '',
            billingOffice: formData?.billingOffice?.trim() || '',
            billingCity: formData?.billingCity?.trim() || '',
            billingState: formData?.billingState || '',
            billingZip: formData?.billingZip?.trim() || '',
            billingEin: formData?.billingEin?.trim() || '',
            billingNpi: formData?.billingNpi?.trim() || undefined,
          },
        };
        switch (isSave) {
          case ClaimActionEnum.SAVE: {
            try {
              let res;
              if (isBlankClaim) {
                res = await createBlankClaim({
                  // patientId: fromPatientId ?? undefined,
                  clinicId: selectedClinic.id,
                  ...data.payload,
                }).unwrap();
              } else {
                res = claimId
                  ? await updateClaim({ claimId: +claimId, ...data }).unwrap()
                  : await createClaim({
                      patientId: fromPatientId ?? undefined,
                      clinicId: selectedClinic.id,
                      ...data,
                    }).unwrap();
              }

              if (res) {
                dispatch(
                  setMessage({
                    message: isBlankClaim
                      ? 'Blank claim was successfully created'
                      : claimId
                        ? 'Claim was successfully updated'
                        : 'Claim was successfully created',
                    type: 'success',
                  }),
                );
                setFormData(null);
                navigate('/claims');
              }
            } catch (e: any) {
              dispatch(
                setMessage({
                  message: e.data?.message,
                  type: 'error',
                }),
              );
            }

            break;
          }
          case ClaimActionEnum.VALIDATE: {
            try {
              !!claimId && (await validateClaim({ id: +claimId }).unwrap());

              dispatch(
                setMessage({
                  message: 'Claim was successfully validated',
                  type: 'success',
                }),
              );
              setFormData(null);
              navigate('/claims');
            } catch (e: any) {
              dispatch(
                setMessage({
                  message: e.data?.message,
                  type: 'error',
                }),
              );
            }

            break;
          }
          case ClaimActionEnum.RELEASE: {
            try {
              !!claimId && (await releaseClaim({ id: +claimId }).unwrap());
              dispatch(
                setMessage({
                  message: 'Claim was successfully released',
                  type: 'success',
                }),
              );
              setFormData(null);
              navigate('/claims');
            } catch (e: any) {
              dispatch(
                setMessage({
                  message: e.data?.message,
                  type: 'error',
                }),
              );
            }
            break;
          }
          default: {
            return undefined;
          }
        }
      }
    })();
    setIsSave(null);
  }, [
    isReadyPatient,
    isReadyInsurance,
    isReadyInjury,
    isReadyServices,
    isReadyProvider,
    formData,
    isSave,
  ]);

  const getButton = useMemo(() => {
    if (isExistClaim && status.data?.status === ClaimStatusType.CREATED) {
      return (
        <S.ButtonWrapper>
          <Button text="Create" onClick={() => onSaveClick(ClaimActionEnum.SAVE)} />
        </S.ButtonWrapper>
      );
    }
    switch (status.data?.status) {
      // case ClaimStatusType.CREATED:
      // case ClaimStatusType.ERROR:
      //case ClaimStatusType.HOLD:
      //case ClaimStatusType.QUEUE:
      // case ClaimStatusType.RELEASED:
      case ClaimStatusType.MODIFIED:
      case ClaimStatusType.VALIDATED:
        return (
          <>
            <S.ButtonWrapper>
              <Button text="Save" onClick={() => onSaveClick(ClaimActionEnum.SAVE)} />
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                text="Release"
                onClick={() => onSaveClick(ClaimActionEnum.RELEASE)}
              />
            </S.ButtonWrapper>
          </>
        );
      case ClaimStatusType.CREATED:
        return (
          <>
            <S.ButtonWrapper>
              <Button text="Save" onClick={() => onSaveClick(ClaimActionEnum.SAVE)} />
            </S.ButtonWrapper>
            <S.ButtonWrapper>
              <Button
                text="Validate"
                onClick={() => onSaveClick(ClaimActionEnum.VALIDATE)}
              />
            </S.ButtonWrapper>
          </>
        );
      case ClaimStatusType.RELEASED:
        return (
          <>
            <S.ButtonWrapper>
              <Button text="Save" onClick={() => onSaveClick(ClaimActionEnum.SAVE)} />
            </S.ButtonWrapper>
          </>
        );
      case ClaimStatusType.ERROR:
        return (
          <>
            <S.ButtonWrapper>
              <Button text="Save" onClick={() => onSaveClick(ClaimActionEnum.SAVE)} />
            </S.ButtonWrapper>
          </>
        );
      case ClaimStatusType.ACKNOWLEDGED:
        return;

      case ClaimStatusType.REJECTED:
        return;

      case ClaimStatusType.PAID:
        return null;
      default:
        return (
          <S.ButtonWrapper>
            <Button text="Create" onClick={() => onSaveClick(ClaimActionEnum.SAVE)} />
          </S.ButtonWrapper>
        );
    }
  }, [status.data?.status, claimId, isExistClaim]);

  const checkStatusCondition = useMemo(() => {
    return [
      //ClaimStatusType.HOLD,
      //ClaimStatusType.QUEUE,
      // ClaimStatusType.RELEASED,
      ClaimStatusType.REJECTED,
      //ClaimStatusType.ACKNOWLEDGED,
      ClaimStatusType.PAID,
    ].includes(status.data?.status as ClaimStatusType);
  }, [status.data?.status]);

  useEffect(() => {
    if (claimId) {
      getClaimById({ id: claimId });
    }
    if (fromClaimId) {
      getClaimById({ id: fromClaimId });
    }
    if (fromPatientId) {
      getPatientById({ id: fromPatientId });
    }
  }, [claimId, fromClaimId, fromPatientId]);

  return (
    <>
      <S.Wrapper>
        <S.Main>
          <S.SubtitleItems>
            <S.SubtitleText>
              <ArrowLeftSVG onClick={() => navigate('/claims')} /> Claim{' '}
              {claimId ? `#${claimId}` : ''}
              {status.data?.status && !!claimId && (
                <S.CardType
                  color={
                    getClaimConfig(status.data.status as ClaimStatusType).headercolor
                  }
                >
                  {getClaimConfig(status.data.status as ClaimStatusType).title}
                </S.CardType>
              )}
              {status.data?.status === ClaimStatusType.ERROR && disableAttachments && (
                <ErrorHistoricalMessageSVG onClick={handleOpenModal} />
              )}
              {!!claimId && (
                <S.CardType
                  color={getClaimTypeColor(status.data?.claimType as ClaimTypeEnum)}
                >
                  {status.data?.claimType}
                </S.CardType>
              )}
            </S.SubtitleText>

            <S.Row>
              <S.ButtonWrapper>
                <Button
                  text="Print"
                  backgroundColor="#848A9B"
                  onClick={() => setShowPdf(true)}
                >
                  <PrintWhiteSVG />
                </Button>
              </S.ButtonWrapper>
              <Dialog open={showPdf} onClose={() => setShowPdf(false)}>
                <GenerateClaimFormPdf data={status.data} />
              </Dialog>
              <S.ShowHistory
                $isShow={isShowClaimHistory}
                onClick={() => setIsShowClaimHistory(!isShowClaimHistory)}
              >
                <ListCheckSVG />
                Show Claim History
              </S.ShowHistory>
            </S.Row>
          </S.SubtitleItems>

          <S.ContentWrapper>
            <S.Content>
              {status.isLoading ||
              patientStatus.isLoading ||
              statusUpdating.isLoading ||
              statusReleasing.isLoading ||
              statusBlankCreating.isLoading ||
              statusValidating.isLoading ||
              statusCreating.isLoading ? (
                <CircularLoader size={24} color="#0084B1" />
              ) : (
                <S.FormContent>
                  <PatientInfoClaim
                    initialValues={
                      fromPatientId
                        ? patientFromUser(patientStatus.data?.profile)
                        : patientClaimForm(status.data)
                    }
                    getData={value => {
                      setFormData(prev => ({ ...prev, ...value }));
                      setIsReadyPatient(true);
                    }}
                    isSave={isSave}
                    setIsSave={setIsSave}
                    setIsDirty={setIsDirtyForm}
                    checkStatusCondition={checkStatusCondition || disableAttachments}
                    isBlankClaim={isBlankClaim}
                  />

                  <InsuranceInfoClaim
                    initialValues={
                      fromPatientId
                        ? insuranceFromUser(patientStatus.data?.profile)
                        : insuranceClaimForm(status.data)
                    }
                    getData={value => {
                      setFormData(prev => ({ ...prev, ...value }));
                      setIsReadyInsurance(true);
                    }}
                    isSave={isSave}
                    setIsSave={setIsSave}
                    setIsDirty={setIsDirtyForm}
                    checkStatusCondition={checkStatusCondition || disableAttachments}
                  />

                  <InjuryBlock
                    data={status.data}
                    getData={value => {
                      setFormData(prev => ({ ...prev, ...value }));
                      setIsReadyInjury(true);
                    }}
                    providerData={patientStatus.data?.provider}
                    isSave={isSave}
                    setIsSave={setIsSave}
                    setIsDirty={setIsDirtyForm}
                    checkStatusCondition={checkStatusCondition || disableAttachments}
                    isBlankClaim={isBlankClaim}
                  />

                  <ServicesTable
                    data={status.data}
                    getData={value => {
                      setFormData(prev => ({ ...prev, ...value }));
                      setIsReadyServices(true);
                    }}
                    isSave={isSave}
                    setIsSave={setIsSave}
                    setIsDirty={setIsDirtyForm}
                    renderingProviderId={renderingProviderId}
                    billingEin={billingEin}
                    checkStatusCondition={checkStatusCondition || disableAttachments}
                    isBlankClaim={isBlankClaim}
                    isExistClaim={isExistClaim}
                  />

                  <ProviderBlock
                    data={status.data}
                    getData={value => {
                      setFormData(prev => ({ ...prev, ...value }));
                      setIsReadyProvider(true);
                    }}
                    isSave={isSave}
                    setIsSave={setIsSave}
                    setIsDirty={setIsDirtyForm}
                    setRenderingProviderId={setRenderingProviderId}
                    setBillingEin={setBillingEin}
                    checkStatusCondition={checkStatusCondition || disableAttachments}
                    isBlankClaim={isBlankClaim}
                  />
                </S.FormContent>
              )}
            </S.Content>

            {claimId && (
              <ClaimHistory
                isShow={isShowClaimHistory}
                claimId={claimId}
                claimStatus={status.data?.status}
                disable={status.data?.isEOB === false}
                setDisableAttachments={setDisableAttachments}
                exposeModalControls={(openModal, closeModal) => {
                  modalControlsRef.current = { openModal, closeModal };
                }}
              />
            )}
          </S.ContentWrapper>
          <S.Footer>{getButton}</S.Footer>
        </S.Main>
      </S.Wrapper>
    </>
  );
};

export default ClaimForm;
