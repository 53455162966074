import { ReactNode, useState } from 'react';
import * as S from './styles';
import { IconButton, InputAdornment, InputProps, TextFieldProps } from '@mui/material';
import { EyeUnVisibleSVG, EyeVisibleSVG } from '../../../assets/icons';

type Props = {
  width?: string;
  height?: string;
  isPassword?: boolean;
  label?: string;
  background?: string;
  isRequired?: boolean;
  isSum?: string | ReactNode;
} & TextFieldProps;

const InputNumber: React.FC<Props> = ({
  label,
  value,
  onChange,
  background,
  isPassword,
  width,
  height,
  isRequired,
  rows,
  multiline,
  children,
  select,
  error,
  helperText,
  id,
  name,
  isSum,
  type,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (type === 'number' && Number(inputValue) < 0) {
      return;
    }

    onChange?.(e);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (type === 'number' && Number(inputValue) < 0) {
      onChange?.({
        ...e,
        target: { ...e.target, value: '0' },
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === 'number' && e.key === '-') {
      e.preventDefault();
    }
  };
  return (
    <S.InputWrapper>
      <S.Label htmlFor="input">
        {label} {isRequired && <S.RequiredSpan>*</S.RequiredSpan>}
      </S.Label>
      <S.InputItem
        name={name}
        id={id}
        inputProps={{
          min: 0,
        }}
        onKeyDown={handleKeyDown}
        value={value}
        error={error}
        onBlur={handleBlur}
        helperText={helperText}
        select={select}
        onChange={handleInputChange}
        rows={rows}
        // required={isRequired}
        multiline={multiline}
        type={!showPassword && isPassword ? 'password' : type || 'text'}
        {...props}
        InputProps={{
          style: {
            backgroundColor: background,
            borderColor: error ? 'red' : '',
          },
          endAdornment: (isPassword || isSum) && (
            <InputAdornment position="end">
              {isPassword && (
                <IconButton
                  disableRipple={true}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? (
                    <EyeVisibleSVG style={{ cursor: 'pointer' }} />
                  ) : (
                    <EyeUnVisibleSVG style={{ cursor: 'pointer' }} />
                  )}
                </IconButton>
              )}
              {!!isSum && (
                <IconButton disableRipple={true} aria-label="toggle password visibility">
                  {isSum}
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      >
        {children}
      </S.InputItem>
    </S.InputWrapper>
  );
};

export default InputNumber;
