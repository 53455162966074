import * as S from '../styles';
import { StaffIconSVG } from '../../../../../assets/icons';
import { UserStatusPin } from '../../../../../components';
import React from 'react';

type Props = {
  row: any;
  header: string;
};

const FacilitiesData: React.FC<Props> = ({ row, header }) => {
  switch (header) {
    case 'FacilityName': {
      return <S.CellWrapper>{row.facilityName || '-'}</S.CellWrapper>;
    }

    case 'Doctors': {
      return (
        <S.CellWrapper>
          {row.doctors.slice(0, 3).map((doc: any, index: any) => (
            <>
              <S.Avatar key={index}>
                {doc.profile.avatar ? (
                  <img src={doc.profile.avatar.url} alt="avatar" />
                ) : (
                  <StaffIconSVG />
                )}
              </S.Avatar>
              {doc.profile.firstName + ' ' + doc.profile.lastName || '-'}
            </>
          ))}
        </S.CellWrapper>
      );
    }
    case 'NPI': {
      return <S.CellWrapper>{row.npi || '-'}</S.CellWrapper>;
    }
    case 'EIN': {
      return <S.CellWrapper>{row.ein || '-'}</S.CellWrapper>;
    }
    case 'Status': {
      return (
        <S.CellWrapper>
          <UserStatusPin type={row.status} />
        </S.CellWrapper>
      );
    }
    default:
      return row[header] || '-';
  }
};

export default FacilitiesData;
