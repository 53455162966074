import { Clinics } from '../../../types/ClinicTypes';
import { File } from '../../../types/FileType';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const fileApi = api.injectEndpoints({
  endpoints: builder => ({
    filesGet: builder.query<Response<File>, any>({
      query: ({ createdById, ...arg }) => {
        return {
          url: `/file?sort=createdAt&asc=DESC&createdById=${createdById}`,
          params: { ...arg },
        };
      },
      providesTags: ['Files'],
    }),
    filesCreate: builder.mutation({
      query: body => {
        const newData = new FormData();
        body.files.map((item: any) => {
          newData.append('files', item);
        });
        return {
          url: `/file/bulk-upload`,
          method: 'POST',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: () => [{ type: 'Files' }],
    }),
    filesCreateToProfile: builder.mutation({
      query: ({ files, id }) => {
        const newData = new FormData();
        files.map((item: File) => {
          newData.append('files', item as any);
        });

        return {
          url: `/file/bulk-upload?userId=${id}`,
          method: 'POST',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: () => [{ type: 'Files' }],
    }),
    uploadCSV: builder.mutation({
      query: ({ id, file }) => {
        const newData = new FormData();
        newData.append('file', file);
        return {
          url: `/clinic/${id}/upload-patient-csv`,
          method: 'POST',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: ['Files', 'User'],
    }),
    deleteFile: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/claim/attachments?fileId=${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['User', 'ClaimAttachments'],
    }),
    deleteManualFile: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/template-folder/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Documents', 'User', 'ClaimAttachments', 'HealthRecords'],
    }),
    uploadInitialDocs: builder.mutation({
      query: ({ files, ...params }) => {
        const newData = new FormData();
        files.map((item: any) => {
          newData.append('files', item);
        });
        return {
          url: `/file/upload-docs?docType=INITIAL`,
          method: 'POST',
          body: newData,
          params: { ...params },
          formData: true,
        };
      },
      invalidatesTags: ['Documents', 'Files'],
    }),
    addManualDocs: builder.mutation({
      query: ({ files, ...params }) => {
        const newData = new FormData();
        files.map((item: any) => {
          newData.append('file', item);
        });
        return {
          url: `/template-folder/add-patient-document`,
          method: 'POST',
          body: newData,
          params: { ...params },
          formData: true,
        };
      },
      invalidatesTags: ['Documents', 'User', 'ClaimAttachments', 'HealthRecords'],
    }),
    filesCreateInProfile: builder.mutation({
      query: body => {
        const newData = new FormData();
        body.files.map((item: any) => {
          newData.append('files', item);
        });
        return {
          url: `/file/bulk-upload`,
          method: 'POST',
          body: newData,
          formData: true,
        };
      },
      invalidatesTags: ['Files', 'User'],
    }),
  }),
});

export const {
  useFilesGetQuery,
  useFilesCreateMutation,
  useUploadCSVMutation,
  useDeleteFileMutation,
  useDeleteManualFileMutation,
  useFilesCreateToProfileMutation,
  useUploadInitialDocsMutation,
  useAddManualDocsMutation,
  useFilesCreateInProfileMutation,
} = fileApi;
