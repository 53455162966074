import { IconButton, InputAdornment, MenuItem, SelectChangeEvent } from '@mui/material';
import SelectInput from 'components/atoms/SelectInput';
import * as S from './styles';
import { useAppSelector } from 'hooks/useAppSelector';
import { daysBetweenVisits, repeatPicker } from 'constants/constants';
import { AppointmentFormValues } from '../../AppointmentDrawer';
import { FieldArray, FormikProps } from 'formik';
import { useAllClinicsGetQuery } from 'store/api/clinics/clinicApi';
import DatePicker from 'components/atoms/DatePicker';
import {
  useGetClinicWeekendsMutation,
  useGetClinicWorkingTimeMutation,
} from 'store/api/clinic-schedule/clinicScheduleApi';
import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import Input from 'components/atoms/Input';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import { AppointmentStatus } from 'types/StatusTypes';
import CheckBox from 'components/atoms/CheckBox';
import { UserProfile } from 'types/UserProfileTypes';
import VisitReasonCard from '../VisitReasonCard';
import { useCoveredServicesGetVisitReasonMutation } from 'store/api/insuranceRelative/insuranceRelativeApi';
import { configColor, configVRCount } from './configVRName';
import { Roles } from 'types/Roles';
import { useLazyGetServicesByClinicWithoutStoreQuery } from 'store/api/services/servicesApi';
import { ClinicInsuranceType } from 'types/enums/AppointmentEnum';
import { useLazyUserProfileGetQuery } from 'store/api/user/profileApi';
import { CloseBtnSVG } from 'assets/icons';

type Props = {
  formik: FormikProps<AppointmentFormValues>;
  selectedAppointment?: AppointmentsNew;
  duplAppointment?: AppointmentsNew;
  userProfile?: UserProfile;
  recordTime?: {
    time?: string;
    date?: string;
  };
};

const AppointmentInformationBlock: React.FC<Props> = ({
  formik,
  selectedAppointment,
  duplAppointment,
  recordTime,
  userProfile,
}) => {
  const me = useAppSelector(state => state.auth);
  const [currentMonth, setCurrentMonth] = useState<DateObject>(new DateObject());

  const [getServiceList, servicesList] = useLazyGetServicesByClinicWithoutStoreQuery();
  const [getUserData, userData] = useLazyUserProfileGetQuery();

  const [checkCoveredServices, checkCoveredServicesStatus] =
    useCoveredServicesGetVisitReasonMutation({});

  const clinic = useAllClinicsGetQuery({
    take: 50,
    status: 'active',
  });

  const [arrWeekends, setArrWeekends] = useState<string[] | null>(null);
  const [arrTime, setArrTime] = useState<string[] | null>(null);

  const [getWeekends, getWeekendsStatus] = useGetClinicWeekendsMutation({});
  const [getTime] = useGetClinicWorkingTimeMutation({});

  const checkActiveServiceInInsurance = (type: ClinicInsuranceType) => {
    return !!userData.data?.profile?.insurances?.find(insurance =>
      insurance?.insuranceTemplate?.insuranceGroupNumbers
        ?.at(0)
        ?.coveredServices?.some(
          serv =>
            serv.typeServiceId === formik.values.mainCoveredService &&
            serv.visitAuthorizet > 0 &&
            serv.type === type,
        ),
    );
  };

  const checkServiceInInsurance = (serviceId: string) => {
    return !!checkCoveredServicesStatus.data?.filter(
      item =>
        item.typeServiceId === serviceId &&
        !!item.coveredService?.filter(i => i.count > 0)?.length,
    )?.length;
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  useEffect(() => {
    if (formik.values.userId && formik.values.clinicId) {
      checkCoveredServices({
        userId: formik.values.userId,
        clinicId: formik.values.clinicId,
      });
    }
  }, [formik.values.userId, formik.values.clinicId]);

  useEffect(() => {
    setCurrentMonth(new DateObject());
    if (formik.values.clinicId && !selectedAppointment && !recordTime) {
      formik.setValues({
        ...formik.values,
        date: '',
      });
    }
    if (formik.values.clinicId) {
      getServiceList({
        clinicId: formik.values.clinicId,
        take: 100,
        asc: 'DESC',
        page: 1,
      });
    }
  }, [formik.values.clinicId]);

  useEffect(() => {
    if (formik.values.userId && formik.values.mainCoveredService) {
      getUserData({
        userId: formik.values.userId,
      });
    }
  }, [formik.values.userId, formik.values.mainCoveredService]);

  useEffect(() => {
    if (currentMonth && formik.values.clinicId) {
      getWeekends({
        clinicId: formik.values.clinicId,
        date: currentMonth.format('YYYY-MM-01'),
      })
        .unwrap()
        .then((res: Array<string>) => setArrWeekends(res));
    }
  }, [currentMonth, formik.values.clinicId]);

  useEffect(() => {
    if (!dayjs(formik.values.date).isValid()) {
      setCurrentMonth(new DateObject());
    } else {
      getTime({
        clinicId: formik.values.clinicId,
        date: dayjs(formik.values.date).format('YYYY-MM-DD'),
      })
        .unwrap()
        .then(res => {
          setArrTime(res.time);
        });
    }
  }, [formik.values.date]);

  return (
    <S.AppointmentInformation>
      <S.SubArticle>Appointment information</S.SubArticle>
      {me.role !== Roles.PATIENT && (
        <S.InputRow>
          <S.CheckboxWrapper
            $disabled={
              selectedAppointment?.isFirstTimeVisit ||
              (userProfile && !userProfile?.lastAppointmentId)
            }
          >
            <S.Label>Is it Re-evaluation?</S.Label>
            <CheckBox
              id="isReevaluation"
              name="isReevaluation"
              value={formik.values.isReevaluation}
              onChange={formik.handleChange}
              checked={formik.values.isReevaluation}
              disabled={
                selectedAppointment?.isFirstTimeVisit ||
                selectedAppointment?.status === AppointmentStatus.COMPLETED ||
                selectedAppointment?.status === AppointmentStatus.CANCELED ||
                selectedAppointment?.status === AppointmentStatus.ACTIVE
              }
            />
          </S.CheckboxWrapper>
        </S.InputRow>
      )}
      <S.InputRow>
        <SelectInput
          label="Clinic name"
          isRequired
          id="clinicId"
          name="clinicId"
          value={formik.values.clinicId}
          onChange={(e: SelectChangeEvent<unknown>) => {
            formik.setFieldError('clinicId', '');
            formik.setFieldValue('typeServiceIdes', []);
            formik.setErrors({});
            formik.handleChange(e);
          }}
          error={!!formik.errors.clinicId}
          helperText={formik.errors.clinicId}
          disabled={
            !!(
              (selectedAppointment && !selectedAppointment?.isPossibleToUpdate) ||
              duplAppointment
            )
          }
        >
          {clinic.currentData?.rows?.map(item => {
            return (
              <MenuItem value={item.id} key={item.id}>
                <S.MenuItemContent>{item.name}</S.MenuItemContent>
              </MenuItem>
            );
          })}
        </SelectInput>
      </S.InputRow>
      {!formik.values.clinicId ? (
        <S.HelperText>To continue filling out the fields, select a clinic</S.HelperText>
      ) : (
        <>
          <S.InputRow>
            <S.SelectInputWrapper>
              <DatePicker
                label="Date"
                isRequired
                shouldDisableDate={date => {
                  if (
                    me.role === Roles.PATIENT &&
                    arrWeekends &&
                    arrWeekends?.includes(dayjs(date).format('YYYY-MM-DD'))
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                disabled={
                  getWeekendsStatus.isLoading ||
                  !formik.values.clinicId ||
                  selectedAppointment?.status === AppointmentStatus.COMPLETED ||
                  selectedAppointment?.status === AppointmentStatus.CANCELED ||
                  selectedAppointment?.status === AppointmentStatus.ACTIVE
                }
                daysWeekends={me.role !== Roles.PATIENT ? arrWeekends : []}
                disablePast={true}
                value={dayjs(formik.values.date)}
                onOpen={() => {
                  !dayjs(formik.values.date).isValid()
                    ? setCurrentMonth(new DateObject())
                    : setCurrentMonth(
                        new DateObject(dayjs(formik.values.date).format('YYYY-MM-DD')),
                      );
                }}
                onMonthChange={date => setCurrentMonth(date)}
                onChange={value => {
                  formik.setFieldError(`date`, '');
                  formik.setFieldValue(`date`, value);
                }}
                id={`date`}
                name={`date`}
                error={
                  !!formik.errors.date ||
                  !!(formik.values.date && !dayjs(formik.values.date).isValid())
                }
                helperText={
                  formik.errors.date ||
                  (formik.values.date !== '' && !dayjs(formik.values.date).isValid())
                    ? 'Not valid format'
                    : ''
                }
              />
            </S.SelectInputWrapper>
            {!formik.values.date ? (
              <S.HelperText>
                To continue filling out the fields, select a preferred date
              </S.HelperText>
            ) : (
              <S.SelectInputWrapper>
                <SelectInput
                  label="Visit reason"
                  isRequired
                  multiple
                  id={`typeServiceIdes`}
                  name={`typeServiceIdes`}
                  error={!!formik.errors.typeServiceIdes}
                  helperText={formik.errors.typeServiceIdes}
                  renderValue={(selected: any) => (
                    <S.SelectInputOutput>
                      <S.SelectInputOutputText>
                        {servicesList.currentData?.rows
                          ?.filter(i => selected.includes(i.id))
                          ?.map(o => o.serviceName)
                          ?.join('+')}
                      </S.SelectInputOutputText>
                    </S.SelectInputOutput>
                  )}
                  value={formik.values.typeServiceIdes}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    formik.setFieldError(`typeServiceIdes`, '');
                    formik.setFieldValue(
                      `visits`,
                      (e.target.value as string[]).map(reason => {
                        const existingVisit = formik.values.visits.find(
                          visit => visit.typeServiceId === reason,
                        );
                        const defaultServiceTime = servicesList.currentData?.rows?.find(
                          i => i.id === reason,
                        )?.defaultServiceTime;

                        return (
                          existingVisit || {
                            typeServiceId: reason,
                            startTime: '',
                            visitLengthMinutes: defaultServiceTime
                              ? defaultServiceTime
                              : 0,
                            specialistId: '',
                          }
                        );
                      }),
                    );
                    formik.handleChange(e);
                  }}
                  disabled={
                    !!(
                      selectedAppointment &&
                      selectedAppointment?.status !== AppointmentStatus.PENDING
                    )
                  }
                >
                  {servicesList.currentData?.rows?.map(reason =>
                    reason?.isHold ? null : (
                      <MenuItem
                        key={reason.id}
                        value={reason.id}
                        disabled={servicesList.isFetching}
                      >
                        <S.MenuItemContent>
                          <S.LeftSide>
                            <CheckBox
                              checked={formik.values.typeServiceIdes.includes(reason?.id)}
                            />
                            {reason.serviceName}
                          </S.LeftSide>
                          <S.LeftSide>
                            {checkCoveredServicesStatus?.data &&
                              checkCoveredServicesStatus.data
                                ?.filter(item => item.typeServiceId === reason.id)
                                ?.map(item =>
                                  item.coveredService?.map((service, index) => {
                                    return (
                                      service.count > 0 && (
                                        <S.Text
                                          key={service.insuranceInfo.id}
                                          color={configColor(service)}
                                        >
                                          {`${service.insuranceInfo?.isPrimary ? 'P' : 'S'}-${configVRCount(
                                            service.count,
                                          )} `}
                                        </S.Text>
                                      )
                                    );
                                  }),
                                )}
                          </S.LeftSide>
                        </S.MenuItemContent>
                      </MenuItem>
                    ),
                  )}
                </SelectInput>
              </S.SelectInputWrapper>
            )}
          </S.InputRow>

          {!formik.values.typeServiceIdes?.length ? (
            <S.HelperText>
              {formik.values.date
                ? 'To continue filling out the fields, select a visit reason'
                : ''}
            </S.HelperText>
          ) : (
            <>
              <S.VisitReasons>
                <FieldArray
                  name="visits"
                  render={arrayHelpers => (
                    <>
                      {formik.values.visits?.map((item, index) => {
                        return (
                          <VisitReasonCard
                            recordTime={recordTime}
                            key={index}
                            visit={item}
                            formik={formik}
                            selectedAppointment={selectedAppointment}
                            arrTime={arrTime}
                            index={index}
                            selectedClinicId={formik.values.clinicId}
                            serviceName={
                              servicesList.currentData?.rows?.find(
                                i => i.id === item?.typeServiceId,
                              )?.serviceName ?? '-'
                            }
                            defaultServiceTime={
                              servicesList.currentData?.rows?.find(
                                i => i.id === item?.typeServiceId,
                              )?.defaultServiceTime
                            }
                          />
                        );
                      })}
                    </>
                  )}
                />
              </S.VisitReasons>

              {!selectedAppointment && me.role !== Roles.PATIENT && (
                <S.InputRow>
                  <SelectInput
                    label="Repeat"
                    id={`repeat`}
                    name={`repeat`}
                    error={!!formik.errors.repeat}
                    helperText={formik.errors.repeat}
                    value={formik.values.repeat}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      formik.setFieldError(`repeat`, '');
                      formik.setFieldError(`daysBetween`, '');
                      formik.handleChange(e);
                    }}
                  >
                    {repeatPicker.map(count => (
                      <MenuItem key={count.name} value={count.value}>
                        <S.MenuItemContent>{count.name}</S.MenuItemContent>
                      </MenuItem>
                    ))}
                  </SelectInput>
                  <SelectInput
                    label="Days Between"
                    id={`daysBetween`}
                    name={`daysBetween`}
                    error={!!formik.errors.daysBetween}
                    helperText={formik.errors.daysBetween}
                    value={formik.values.daysBetween}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      formik.setFieldError(`daysBetween`, '');
                      formik.handleChange(e);
                    }}
                    disabled={Number(formik.values.repeat) === 0}
                  >
                    {daysBetweenVisits.map(day => (
                      <MenuItem key={day.name} value={day.value}>
                        <S.MenuItemContent>{day.name}</S.MenuItemContent>
                      </MenuItem>
                    ))}
                  </SelectInput>
                </S.InputRow>
              )}

              {me.role !== Roles.PATIENT && (
                <S.InputRow>
                  <S.SelectInputWrap>
                    <Input
                      value={formik.values.authSigs}
                      onChange={handleChangeInput}
                      label={'Auth SIGs'}
                      type="number"
                      id="authSigs"
                      inputProps={{ min: 0 }}
                      name="authSigs"
                      disabled={
                        selectedAppointment?.status === AppointmentStatus.CANCELED ||
                        (selectedAppointment?.status === AppointmentStatus.COMPLETED &&
                          (me.role !== Roles.BILLING ||
                            selectedAppointment.isCheckBilling))
                      }
                    />
                  </S.SelectInputWrap>
                  <SelectInput
                    label="Covered service"
                    id={`mainCoveredService`}
                    name={`mainCoveredService`}
                    error={!!formik.errors.mainCoveredService}
                    helperText={formik.errors.mainCoveredService}
                    value={formik.values.mainCoveredService}
                    disabled={
                      selectedAppointment?.status === AppointmentStatus.CANCELED ||
                      (selectedAppointment?.status === AppointmentStatus.COMPLETED &&
                        (me.role !== Roles.BILLING || selectedAppointment.isCheckBilling))
                    }
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      formik.setFieldError(`mainCoveredService`, '');
                      formik.handleChange(e);
                      formik.setFieldValue('mainCoveredServiceType', '');
                      // formik.setFieldValue('dateOfService', '');
                    }}
                    endAdornment={
                      !!formik.values.mainCoveredService && (
                        <InputAdornment sx={{ marginRight: '12px' }} position="end">
                          <IconButton
                            disabled={
                              selectedAppointment?.status ===
                                AppointmentStatus.CANCELED ||
                              (selectedAppointment?.status ===
                                AppointmentStatus.COMPLETED &&
                                (me.role !== Roles.BILLING ||
                                  selectedAppointment.isCheckBilling))
                            }
                            onClick={() => {
                              formik.setFieldValue('mainCoveredService', '');
                              formik.setFieldValue('mainCoveredServiceType', '');
                              // formik.setFieldValue('dateOfService', '');
                            }}
                          >
                            {selectedAppointment?.status === AppointmentStatus.CANCELED ||
                              (selectedAppointment?.status ===
                                AppointmentStatus.COMPLETED &&
                              (me.role !== Roles.BILLING ||
                                selectedAppointment.isCheckBilling) ? (
                                <></>
                              ) : (
                                <CloseBtnSVG />
                              ))}
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  >
                    {formik.values.typeServiceIdes?.map(item => {
                      return checkServiceInInsurance(item) ? (
                        <MenuItem key={item} value={item}>
                          <S.MenuItemContent>
                            {servicesList.currentData?.rows?.find(i => i.id === item)
                              ?.serviceName ?? '-'}
                          </S.MenuItemContent>
                        </MenuItem>
                      ) : null;
                    })}
                  </SelectInput>
                </S.InputRow>
              )}

              {me.role !== Roles.PATIENT &&
                !!formik.values.mainCoveredService &&
                !!formik.values.userId && (
                  <S.InputRow>
                    <SelectInput
                      label="Covered service type"
                      id={`mainCoveredServiceType`}
                      name={`mainCoveredServiceType`}
                      error={!!formik.errors.mainCoveredServiceType}
                      helperText={formik.errors.mainCoveredServiceType}
                      value={formik.values.mainCoveredServiceType}
                      disabled={
                        selectedAppointment?.status === AppointmentStatus.CANCELED ||
                        (selectedAppointment?.status === AppointmentStatus.COMPLETED &&
                          (me.role !== Roles.BILLING ||
                            selectedAppointment.isCheckBilling))
                      }
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        formik.setFieldError(`mainCoveredServiceType`, '');
                        formik.handleChange(e);
                        formik.setFieldValue('dateOfService', '');
                      }}
                      // endAdornment={
                      //   !!formik.values.mainCoveredServiceType && (
                      //     <InputAdornment sx={{ marginRight: '12px' }} position="end">
                      //       <IconButton
                      //         onClick={() => {
                      //           formik.setFieldValue('mainCoveredServiceType', '');
                      //           formik.setFieldValue('dateOfService', '');
                      //         }}
                      //       >
                      //         <CloseBtnSVG />
                      //       </IconButton>
                      //     </InputAdornment>
                      //   )
                      // }
                    >
                      <MenuItem
                        value={ClinicInsuranceType.OUT_OF_NETWORK}
                        disabled={
                          !checkActiveServiceInInsurance(
                            ClinicInsuranceType.OUT_OF_NETWORK,
                          )
                        }
                      >
                        <S.MenuItemContent>Out of network</S.MenuItemContent>
                      </MenuItem>
                      <MenuItem
                        value={ClinicInsuranceType.IN_NETWORK}
                        disabled={
                          !checkActiveServiceInInsurance(ClinicInsuranceType.IN_NETWORK)
                        }
                      >
                        <S.MenuItemContent>In network</S.MenuItemContent>
                      </MenuItem>
                    </SelectInput>
                  </S.InputRow>
                )}
              {/*{me.role !== Roles.PATIENT && (*/}
              {/*  <S.SelectContent>*/}
              {/*    <S.SubArticle>Date of Service</S.SubArticle>*/}
              {/*    <S.SelectInputWrap>*/}
              {/*      <DatePicker*/}
              {/*        disabled={*/}
              {/*          selectedAppointment?.status === AppointmentStatus.CANCELED ||*/}
              {/*          (selectedAppointment?.status === AppointmentStatus.COMPLETED &&*/}
              {/*            (me.role !== Roles.BILLING ||*/}
              {/*              selectedAppointment.isCheckBilling))*/}
              {/*        }*/}
              {/*        slotProps={{ field: { clearable: !!formik.values.dateOfService } }}*/}
              {/*        value={dayjs(formik.values.dateOfService)}*/}
              {/*        onChange={value => {*/}
              {/*          formik.setFieldError(`dateOfService`, '');*/}
              {/*          formik.setFieldValue(`dateOfService`, value);*/}
              {/*        }}*/}
              {/*        id={`dateOfService`}*/}
              {/*        name={`dateOfService`}*/}
              {/*        label={''}*/}
              {/*      />*/}
              {/*    </S.SelectInputWrap>*/}
              {/*  </S.SelectContent>*/}
              {/*)}*/}

              {/*{me.role !== Roles.PATIENT && (*/}
              {/*  <S.SelectContent>*/}
              {/*    <S.SubArticle>Auth SIGs</S.SubArticle>*/}
              {/*    <S.SelectInputWrap>*/}
              {/*      <Input*/}
              {/*        value={formik.values.authSigs}*/}
              {/*        onChange={handleChangeInput}*/}
              {/*        label={''}*/}
              {/*        type="number"*/}
              {/*        id="authSigs"*/}
              {/*        inputProps={{ min: 0 }}*/}
              {/*        name="authSigs"*/}
              {/*        disabled={*/}
              {/*          selectedAppointment?.status === AppointmentStatus.CANCELED ||*/}
              {/*          (selectedAppointment?.status === AppointmentStatus.COMPLETED &&*/}
              {/*            (me.role !== Roles.BILLING ||*/}
              {/*              selectedAppointment.isCheckBilling))*/}
              {/*        }*/}
              {/*      />*/}
              {/*    </S.SelectInputWrap>*/}
              {/*  </S.SelectContent>*/}
              {/*)}*/}
              {me.role !== Roles.PATIENT && (
                <>
                  {selectedAppointment?.status !== AppointmentStatus.ACTIVE &&
                  selectedAppointment?.status !== AppointmentStatus.COMPLETED ? (
                    <S.InputRow>
                      <Input
                        multiline={true}
                        rows={4}
                        label="Note"
                        id={`note`}
                        name={`note`}
                        error={!!formik.errors.note}
                        helperText={formik.errors.note}
                        value={formik.values.note}
                        onChange={(
                          e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                        ) => {
                          formik.setFieldError(e.target.id, '');
                          formik.handleChange(e);
                        }}
                      />
                    </S.InputRow>
                  ) : (
                    <S.InputRow>
                      <Input
                        multiline={true}
                        rows={4}
                        label="Note"
                        id={`globalNote`}
                        disabled={
                          selectedAppointment?.status === AppointmentStatus.COMPLETED &&
                          (me.role !== Roles.BILLING ||
                            selectedAppointment.isCheckBilling)
                        }
                        name={`globalNote`}
                        error={!!formik.errors.globalNote}
                        helperText={formik.errors.globalNote}
                        value={formik.values.globalNote}
                        onChange={(
                          e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                        ) => {
                          formik.setFieldError(e.target.id, '');
                          formik.handleChange(e);
                        }}
                      />
                    </S.InputRow>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </S.AppointmentInformation>
  );
};

export default AppointmentInformationBlock;
