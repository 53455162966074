import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, DatePicker, Input, SelectInput } from 'components';
import {
  useClinicsGetQuery,
  useUpdateToClinicMutation,
} from 'store/api/clinics/clinicApi';
import * as S from './styles';
import { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Clinics } from 'types/ClinicTypes';
import { Roles } from 'types/Roles';

import { useAppSelector } from 'hooks/useAppSelector';
import { GetDoctorList } from '../AssignClinicDialog/GetDoctorList';
import { GetReceptionistList } from '../AssignClinicDialog/GetReceptionistList';
import { GetLocalAdminList } from '../AssignClinicDialog/GetLocalAdminList';
import {
  Article,
  SubArticle,
} from '../../../../../ProfileFacility/components/AssingFacilityDialog/styles';
import { NameClinic } from './styles';

type Props = {
  userId?: string;
  assignInfo?: Clinics;
  onClose: () => void;
  role?: Roles;
  clinicId?: string;

  isSpecialist?: boolean;
};

const UpdateClinicDialog = ({
  userId,
  assignInfo,
  onClose,
  role,
  clinicId,
  isSpecialist,
}: Props) => {
  const clinics = useClinicsGetQuery({});
  const me = useAppSelector(state => state.auth);

  const [clinicName, setClinicName] = useState('');
  const [taxonomy, setTaxonomy] = useState('');
  const [taxonomyName, setTaxonomyName] = useState('');
  const [npi, setNpi] = useState('');
  const [firstWorkDay, setFirstWorkDay] = useState<Dayjs>(dayjs());
  const [lastWorkDay, setLastWorkDay] = useState<Dayjs | string>('');
  const [selectedValue, setSelectedValue] = useState<any>(null);

  const [updateOn] = useUpdateToClinicMutation({});

  const dispatch = useAppDispatch();

  const disableButton = useMemo(
    () => !clinicName || !firstWorkDay,
    [clinicName, firstWorkDay],
  );

  const getTitle = useMemo(() => {
    switch (role) {
      case Roles.DOCTOR: {
        return isSpecialist ? 'Specialist' : 'Doctor';
      }
      case Roles.RECEPTIONIST: {
        return 'Receptionist';
      }
      case Roles.LOCATION_MANAGER: {
        return 'Local Admin';
      }
      default: {
        return 'Clinic';
      }
    }
  }, [role, isSpecialist]);

  useEffect(() => {
    if (assignInfo) {
      setClinicName(assignInfo.id);
      setFirstWorkDay(dayjs(assignInfo.workingStartDate));
      assignInfo.workingEndDate && setLastWorkDay(dayjs(assignInfo.workingEndDate));
      assignInfo?.npi && setNpi(assignInfo.npi);
      assignInfo?.taxonomy && setTaxonomy(assignInfo.taxonomy);
      assignInfo?.taxonomyName && setTaxonomyName(assignInfo.taxonomyName);
    }
  }, [assignInfo]);

  useEffect(() => {
    if (clinicId) {
      setClinicName(clinicId);
    }
  }, [clinicId]);

  const handleInputChange = (
    field: 'taxonomy' | 'taxonomyName' | 'npi',
    value: string,
  ) => {
    if (field === 'taxonomy') {
      setTaxonomy(value);
    } else if (field === 'taxonomyName') {
      setTaxonomyName(value);
    } else if (field === 'npi') {
      setNpi(value);
    }
  };
  return (
    <S.Content>
      <S.Article>Update {getTitle}</S.Article>
      <S.InputWrapper>
        {!userId && role === Roles.DOCTOR && (
          <S.InputAutoCompleteWrapper>
            <GetDoctorList
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              key={Roles.DOCTOR}
              isSpecialist={!!isSpecialist}
            />
          </S.InputAutoCompleteWrapper>
        )}
        {!userId && role === Roles.RECEPTIONIST && (
          <S.InputAutoCompleteWrapper>
            <GetReceptionistList
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              key={Roles.RECEPTIONIST}
            />
          </S.InputAutoCompleteWrapper>
        )}
        {!userId && role === Roles.LOCATION_MANAGER && (
          <S.InputAutoCompleteWrapper>
            <GetLocalAdminList
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              key={Roles.LOCATION_MANAGER}
            />
          </S.InputAutoCompleteWrapper>
        )}
      </S.InputWrapper>
      <S.InputWrapper>
        {!!assignInfo ? (
          <S.NameClinic>{assignInfo.name}</S.NameClinic>
        ) : (
          <SelectInput
            label="Clinic"
            value={clinicName}
            onChange={(e: SelectChangeEvent<unknown>) => {
              setClinicName(e.target.value as string);
            }}
            disabled={!!clinicId}
          >
            {clinics.currentData?.rows.map(clinic => {
              return me?.role === Roles.SUPER_ADMIN ? (
                <MenuItem key={clinic.id} value={clinic.id}>
                  <S.MenuItemContent>{clinic.name}</S.MenuItemContent>
                </MenuItem>
              ) : (
                me.clinics?.some(i => i.id === clinic.id) && (
                  <MenuItem key={clinic.id} value={clinic.id}>
                    <S.MenuItemContent>{clinic.name}</S.MenuItemContent>
                  </MenuItem>
                )
              );
            })}
          </SelectInput>
        )}
      </S.InputWrapper>
      <S.InputWrapper>
        <DatePicker
          label="First work day"
          value={firstWorkDay}
          onChange={value => setFirstWorkDay(value)}
        />
        <DatePicker
          label="Last work day"
          value={lastWorkDay}
          onChange={value => setLastWorkDay(value)}
        />
      </S.InputWrapper>

      {assignInfo?.previosWorkingDates &&
        assignInfo?.previosWorkingDates.length > 0 &&
        assignInfo?.previosWorkingDates?.map(date => (
          <S.InputDateWrapper>
            <S.PrevDate>
              Previous start date:{' '}
              <S.EndDate>{dayjs(date.workingStartDate).format('MM/DD/YYYY')}</S.EndDate>
            </S.PrevDate>
            <S.PrevDate>
              End date:{' '}
              <S.EndDate>{dayjs(date.workingEndDate).format('MM/DD/YYYY')}</S.EndDate>
            </S.PrevDate>
          </S.InputDateWrapper>
        ))}

      {me?.role === Roles.SUPER_ADMIN && (
        <>
          <S.InputWrapper>
            <Input
              id={`taxonomy`}
              name={`taxonomy`}
              label="Taxonomy"
              value={taxonomy}
              onChange={e => handleInputChange('taxonomy', e.target.value)}
            />
            <Input
              id={`taxonomyName`}
              name={`taxonomyName`}
              label="Taxonomy name"
              value={taxonomyName}
              onChange={e => handleInputChange('taxonomyName', e.target.value)}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <Input
              id={`npi`}
              name={`npi`}
              label="Npi"
              value={npi}
              onChange={e => handleInputChange('npi', e.target.value)}
            />
          </S.InputWrapper>
        </>
      )}

      <S.ButtonWrapper>
        <Button
          text="Save"
          disabled={disableButton}
          onClick={() =>
            updateOn({
              clinicId: clinicName,
              userId: userId || selectedValue.userId,
              workingStartDate: dayjs(firstWorkDay).format('YYYY-MM-DD'),
              ...(lastWorkDay && {
                workingEndDate: dayjs(lastWorkDay).format('YYYY-MM-DD'),
              }),
              ...(me?.role === Roles.SUPER_ADMIN && {
                taxonomy,
                taxonomyName,
                npi,
              }),
            })
              .unwrap()
              .then(res => {
                onClose();
                dispatch(
                  setMessage({
                    message: 'Clinic was successfully update',
                    type: 'success',
                  }),
                );
              })
              .catch(error => {
                onClose();
                dispatch(setMessage({ message: error.data.message, type: 'error' }));
              })
          }
        />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default UpdateClinicDialog;
