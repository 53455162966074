import React, { ReactNode, useState } from 'react';
import * as S from './styles';
import { IconButton, InputAdornment, TextFieldProps, Tooltip } from '@mui/material';
import { EyeUnVisibleSVG, EyeVisibleSVG } from 'assets/icons';

type Props = {
  width?: string;
  height?: string;
  isPassword?: boolean;
  label?: string;
  background?: string;
  isRequired?: boolean;
  isSum?: string | ReactNode;
  tooltipHelperText?: string;
} & TextFieldProps;

export const TooltipNumberInput = ({
  label,
  value,
  onChange,
  background,
  isPassword,
  width,
  height,
  isRequired,
  rows,
  multiline,
  children,
  select,
  error,
  helperText,
  id,
  name,
  isSum,
  type,
  tooltipHelperText,
  ...props
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (type === 'number') {
      const numericValue = parseFloat(inputValue);
      if (numericValue >= 0 || inputValue === '') {
        onChange?.(e);
      } else {
        e.target.value = '0';
        onChange?.(e);
      }
    } else {
      onChange?.(e);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const currentValue = Number(value || 0);
    if (e.key === 'ArrowDown' && currentValue <= 0) {
      e.preventDefault();
    }
  };

  const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    const currentValue = Number(value || 0);
    if (currentValue <= 0 && e.deltaY > 0) {
      e.preventDefault();
    }
  };

  const preventNegativeValues = (e: React.FocusEvent<HTMLInputElement>) => {
    const numericValue = parseFloat(e.target.value);
    if (type === 'number' && numericValue < 0) {
      e.target.value = '0';
      onChange?.(e as unknown as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <S.InputWrapper>
      <S.Label htmlFor="input">
        {label} {isRequired && <S.RequiredSpan>*</S.RequiredSpan>}
      </S.Label>
      <Tooltip title={tooltipHelperText ? tooltipHelperText : ''} placement={'right'}>
        <S.InputItem
          name={name}
          id={id}
          value={value}
          error={error}
          helperText={helperText}
          select={select}
          onBlur={preventNegativeValues}
          onChange={handleInputChange}
          rows={rows}
          onKeyDown={handleKeyDown}
          onWheel={handleWheel}
          inputMode={type === 'number' ? 'numeric' : undefined}
          // required={isRequired}
          multiline={multiline}
          type={!showPassword && isPassword ? 'password' : type || 'text'}
          {...props}
          InputProps={{
            style: {
              backgroundColor: background,
              borderColor: error ? 'red' : '',
            },
            endAdornment: (isPassword || isSum) && (
              <InputAdornment position="end">
                {isPassword && (
                  <IconButton
                    disableRipple={true}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <EyeVisibleSVG style={{ cursor: 'pointer' }} />
                    ) : (
                      <EyeUnVisibleSVG style={{ cursor: 'pointer' }} />
                    )}
                  </IconButton>
                )}
                {!!isSum && (
                  <IconButton
                    disableRipple={true}
                    aria-label="toggle password visibility"
                  >
                    {isSum}
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        >
          {children}
        </S.InputItem>
      </Tooltip>
    </S.InputWrapper>
  );
};
