import ReactInputMask from 'react-input-mask';
import * as S from '../styles';
import { Input } from '../../../../../../../components';
import { FormikProps } from 'formik';
import { ChangeEvent } from 'react';
import { CredentialsFormValues } from '../CredentialsDoctor';

type Props = {
  formik: FormikProps<CredentialsFormValues>;
};

const EditableCredentials: React.FC<Props> = ({ formik }) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };
  return (
    <S.BlockContent>
      <S.Items>
        <S.Item>
          <S.ItemArticle>NPI</S.ItemArticle>
          <Input
            value={formik.values.NPI}
            onChange={handleChangeInput}
            label=""
            id="NPI"
            name="NPI"
            error={!!formik.errors.NPI}
            helperText={formik.errors.NPI}
          />
        </S.Item>
        <S.Item>
          <S.ItemArticle>TAX ID</S.ItemArticle>
          <Input
            label=""
            id="TAX_ID"
            name="TAX_ID"
            value={formik.values.TAX_ID}
            onChange={handleChangeInput}
            error={!!formik.errors.TAX_ID}
            helperText={formik.errors.TAX_ID}
          />
        </S.Item>
        {/*<S.Item>*/}
        {/*  <S.ItemArticle>Taxanomy</S.ItemArticle>*/}
        {/*  <Input*/}
        {/*    label=""*/}
        {/*    id="Taxonomy"*/}
        {/*    name="Taxonomy"*/}
        {/*    error={!!formik.errors.Taxonomy}*/}
        {/*    helperText={formik.errors.Taxonomy}*/}
        {/*    value={formik.values.Taxonomy}*/}
        {/*    onChange={handleChangeInput}*/}
        {/*  />*/}
        {/*</S.Item>*/}
        {/*<S.Item>*/}
        {/*  <S.ItemArticle>Taxanomy name</S.ItemArticle>*/}
        {/*  <Input*/}
        {/*    label=""*/}
        {/*    id="Taxonomy_NAME"*/}
        {/*    name="Taxonomy_NAME"*/}
        {/*    error={!!formik.errors.Taxonomy_NAME}*/}
        {/*    helperText={formik.errors.Taxonomy_NAME}*/}
        {/*    value={formik.values.Taxonomy_NAME}*/}
        {/*    onChange={handleChangeInput}*/}
        {/*  />*/}
        {/*</S.Item>*/}
        <S.Item>
          <S.ItemArticle>NYS Acu License</S.ItemArticle>
          <Input
            label=""
            id="NYS_ACU"
            name="NYS_ACU"
            error={!!formik.errors.NYS_ACU}
            helperText={formik.errors.NYS_ACU}
            value={formik.values.NYS_ACU}
            onChange={handleChangeInput}
          />
        </S.Item>
        <S.Item>
          <S.ItemArticle>NYS Acu Lic Expiration</S.ItemArticle>
          <Input
            label=""
            id="NYS_ACU_Lic_EXP"
            name="NYS_ACU_Lic_EXP"
            error={!!formik.errors.NYS_ACU_Lic_EXP}
            helperText={formik.errors.NYS_ACU_Lic_EXP}
            value={formik.values.NYS_ACU_Lic_EXP}
            onChange={handleChangeInput}
          />
        </S.Item>
        <S.Item>
          <S.ItemArticle>CAQH ID</S.ItemArticle>
          <Input
            label=""
            id="CAQH_ID"
            name="CAQH_ID"
            error={!!formik.errors.CAQH_ID}
            helperText={formik.errors.CAQH_ID}
            value={formik.values.CAQH_ID}
            onChange={handleChangeInput}
          />
        </S.Item>
        <S.Item>
          <S.ItemArticle>First Aid Certification</S.ItemArticle>
          <Input
            label=""
            id="First_AID_Cert"
            name="First_AID_Cert"
            error={!!formik.errors.First_AID_Cert}
            helperText={formik.errors.First_AID_Cert}
            value={formik.values.First_AID_Cert}
            onChange={handleChangeInput}
          />
        </S.Item>
        <S.Item>
          <S.ItemArticle>First Aid Certification Expiration</S.ItemArticle>
          <Input
            label=""
            id="First_AID_Cert_EXP"
            name="First_AID_Cert_EXP"
            error={!!formik.errors.First_AID_Cert_EXP}
            helperText={formik.errors.First_AID_Cert_EXP}
            value={formik.values.First_AID_Cert_EXP}
            onChange={handleChangeInput}
          />
        </S.Item>
        <S.Item>
          <S.ItemArticle>Aetna ID</S.ItemArticle>
          <Input
            label=""
            id="Aetna_ID"
            name="Aetna_ID"
            error={!!formik.errors.Aetna_ID}
            helperText={formik.errors.Aetna_ID}
            value={formik.values.Aetna_ID}
            onChange={handleChangeInput}
          />
        </S.Item>
        <S.Item>
          <S.ItemArticle>UHC ID</S.ItemArticle>
          <Input
            label=""
            id="UHC_ID"
            name="UHC_ID"
            error={!!formik.errors.UHC_ID}
            helperText={formik.errors.UHC_ID}
            value={formik.values.UHC_ID}
            onChange={handleChangeInput}
          />
        </S.Item>
        <S.Item>
          <S.ItemArticle>OXPH PIN</S.ItemArticle>
          <Input
            label=""
            id="OXPH_PIN"
            name="OXPH_PIN"
            error={!!formik.errors.OXPH_PIN}
            helperText={formik.errors.OXPH_PIN}
            value={formik.values.OXPH_PIN}
            onChange={handleChangeInput}
          />
        </S.Item>
      </S.Items>
    </S.BlockContent>
  );
};

export default EditableCredentials;
