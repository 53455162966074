import { useParams } from 'react-router-dom';
import { DeleteBlueBtnSVG, EditBlueBtnSVG, PlacePinSVG } from 'assets/icons';
import * as S from './styles';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setMessage } from 'store/slices/message';
import { Dialog } from 'components';
import { useState } from 'react';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { FacilityDate } from '../../../../../../../../types/FacilityTypes';
import dayjs from 'dayjs';
import { useDoctorsRemoveFromFacilityMutation } from '../../../../../../../../store/api/facilities/facilityApi';
import AssignFacilityDialog from '../../../../../ProfileFacility/components/AssingFacilityDialog';

type Props = {
  assignInfo: FacilityDate;
  facilityList: FacilityDate[];
};

const AssignFacilityRecord: React.FC<Props> = ({ assignInfo, facilityList }) => {
  const [deleteFromDoctor] = useDoctorsRemoveFromFacilityMutation({});
  const { profileId } = useParams();
  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleSuccessDelete = () => {
    deleteFromDoctor({
      facilityId: assignInfo.facility.id,
      doctorId: profileId,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Facility was successfully unassigned from doctor',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  return (
    <S.Wrapper $isWorkNow={assignInfo.isActive || false}>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <AssignFacilityDialog
          doctorId={profileId || ''}
          onClose={() => setOpenDialog(!openDialog)}
          assignInfo={assignInfo}
        />
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <ConfirmDeleteDialog
          onClose={() => setOpenDeleteDialog(false)}
          fullTitle={`Do you really want to remove this Facility from the Doctor?`}
          helperText={'This action is irreversible.'}
          onSuccess={handleSuccessDelete}
        />
      </Dialog>

      <S.ClinicInfo>
        <PlacePinSVG />
        <S.ClinicInfoBox>
          <S.ClinicName>
            {assignInfo.facility.facilityName ? assignInfo.facility?.facilityName : '-'}
          </S.ClinicName>
          <S.Date>
            NPI:{' '}
            <S.SpanData>
              {assignInfo.facility?.npi ? assignInfo.facility.npi : '-'}
            </S.SpanData>
          </S.Date>
        </S.ClinicInfoBox>
      </S.ClinicInfo>
      <S.WorkRangeContainer>
        <S.WorkRange>
          <S.Date>
            Start date:{' '}
            <S.SpanData>
              {(assignInfo.startDate &&
                dayjs(assignInfo.startDate).format('MM/DD/YYYY')) ||
                '-'}
            </S.SpanData>
          </S.Date>
          <S.Date>
            End date:{' '}
            <S.SpanData>
              {(assignInfo.endDate && dayjs(assignInfo.endDate).format('MM/DD/YYYY')) ||
                'till now'}
            </S.SpanData>
          </S.Date>
        </S.WorkRange>
        {assignInfo?.taxonomyes?.map(taxonomy => (
          <S.WorkRange>
            <S.Date>
              Taxonomy: <S.SpanData>{taxonomy.taxonomy || '-'}</S.SpanData>
            </S.Date>
            <S.Date>
              Taxonomy name: <S.SpanData>{taxonomy.taxonomyName || '-'}</S.SpanData>
            </S.Date>
          </S.WorkRange>
        ))}
      </S.WorkRangeContainer>
      {assignInfo.isActive ? (
        <S.Buttons>
          <S.ButtonWrap $isWorkNow onClick={() => setOpenDialog(!openDialog)}>
            <EditBlueBtnSVG />
          </S.ButtonWrap>
          {facilityList?.length > 0 && (
            <S.ButtonWrap $isWorkNow onClick={() => setOpenDeleteDialog(true)}>
              <DeleteBlueBtnSVG />
            </S.ButtonWrap>
          )}
        </S.Buttons>
      ) : (
        <div></div>
      )}
    </S.Wrapper>
  );
};

export default AssignFacilityRecord;
