import { api } from '../api';
import {
  AllDoctors,
  AllFacilities,
  DoctorsDate,
  Facility,
  FacilityDate,
  FacilityExtend,
} from '../../../types/FacilityTypes';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

type Request = {
  page?: number;
  q?: string;
  id?: string;
  take?: number;
};

export const facilityApi = api.injectEndpoints({
  endpoints: builder => ({
    facilitiesGet: builder.query<Response<FacilityExtend>, any>({
      query: () => ({
        url: '/facility?take=20',
      }),
      providesTags: ['Appointments', 'User', 'Files', 'Facilities'],
    }),
    allFacilitiesGet: builder.query<Response<Facility>, any>({
      query: ({ q, take, ...params }) => ({
        url: '/facility',
        params: { q: q, take: take || 20, ...params },
      }),
      providesTags: ['Appointments', 'User', 'Files', 'Facilities'],
    }),
    facilitiesGetOne: builder.query<FacilityExtend, any>({
      query: ({ id }) => ({
        url: `/facility/${id}?take=20`,
      }),
      providesTags: ['Appointments', 'User', 'Files', 'Facilities', 'Staff'],
    }),
    facilityCreate: builder.mutation({
      query: ({ ...body }) => ({
        url: `/facility`,
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['Facilities'],
    }),
    allDoctorsGet: builder.query<AllDoctors[], any>({
      query: ({ id, q, page, take }) => ({
        url: `/user/doctors/all`,
        params: { q },
      }),
      providesTags: ['User'],
    }),
    allFacilitiesForDoctorGet: builder.query<Response<AllFacilities>, any>({
      query: ({ id, q, page, take }) => ({
        url: `/facility`,
        params: { q, page, take },
        cache: 'no-cache',
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
        } else {
          currentCache.rows.push(...newItems.rows);
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return !!currentArg?.id && currentArg !== previousArg;
      },
      providesTags: ['User'],
    }),
    allFacilityDoctorsGet: builder.query<Response<DoctorsDate>, any>({
      query: ({ id, q, page, take }) => ({
        url: `/facility/doctors/all/?facilityId=${id}`,
        params: { q },
      }),
      providesTags: ['User'],
    }),
    allDoctorFacilitiesGet: builder.query<Response<FacilityDate>, any>({
      query: ({ id, q, page, take }) => ({
        url: `/facility/facility/all/`,
        params: { doctorId: id, q, page, take },
        cache: 'no-cache',
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.page === 1) {
          currentCache.rows = [...newItems.rows];
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
        } else {
          currentCache.rows.push(...newItems.rows);
          currentCache.count = newItems.count;
          currentCache.page = newItems.page;
          currentCache.lastPage = newItems.lastPage;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return !!currentArg?.id && currentArg !== previousArg;
      },
      providesTags: ['User'],
    }),
    doctorAssignToFacility: builder.mutation({
      query: body => ({
        url: `/facility/assign/facility-to-doctor`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['Staff', 'Facilities', 'User'],
    }),
    facilityUpdate: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/facility/${id}`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['Facilities'],
    }),
    doctorsFacilitiesSave: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `facility/update/facility-user`,
        method: 'PATCH',
        body: { ...body },
      }),
      invalidatesTags: ['Facilities', 'Staff', 'User'],
    }),
    doctorsRemoveFromFacility: builder.mutation({
      query: ({ doctorId, facilityId }) => ({
        url: `/facility/${doctorId}/${facilityId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Staff', 'Clinics', 'User'],
    }),
  }),
});

export const {
  useAllDoctorFacilitiesGetQuery,
  useAllFacilityDoctorsGetQuery,
  useFacilityCreateMutation,
  useFacilitiesGetOneQuery,
  useAllDoctorsGetQuery,
  useAllFacilitiesGetQuery,
  useDoctorAssignToFacilityMutation,
  useFacilityUpdateMutation,
  useDoctorsRemoveFromFacilityMutation,
  useAllFacilitiesForDoctorGetQuery,
  useDoctorsFacilitiesSaveMutation,
} = facilityApi;
