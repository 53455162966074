import { ChangeEvent, forwardRef, useEffect } from 'react';
import { Input, RadioButton } from '../../../../../../../../components';
import CheckBoxWithLabel from '../../../../../../../../components/atoms/CheckboxWithLabel';
import * as S from '../../styles';
import { FormikProps } from 'formik';
import { PagePTInsuranceType } from '../../../pages/PagePhysicalTherapyInsurance/PagePTInsuranceType';
import { FormControlLabel, RadioGroup } from '@mui/material';
import { handleChangeInputRef } from '../../../../../../../../helpers/FormikFuncs/formikFuncs';
import { DebouncedFunc } from 'lodash';
import InputNumber from '../../../../../../../../components/atoms/Input/InputNumber';

type Props = {
  formik: FormikProps<PagePTInsuranceType>;

  setIsDirty?: () => void;
  // ref: React.MutableRefObject<{}>;
};
const PreviousPT = forwardRef<Record<string, unknown>, Props>(
  ({ formik, setIsDirty }, ref) => {
    return (
      <>
        <S.BlockWrapper>
          <S.Line>
            <S.Caption>1. Do you cover Physical Therapy?</S.Caption>
            <RadioGroup
              defaultValue={formik.values?.coverAcupunture}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'coverAcupunture', ref, setIsDirty)
              }
            >
              <S.RadioItems>
                <RadioButton value={true} rightPos label="Yes" />
                <RadioButton value={false} rightPos label="No" />
              </S.RadioItems>
            </RadioGroup>
          </S.Line>
          <S.Line>
            <S.Caption>Does the patient have out of network coverage?</S.Caption>
            <RadioGroup
              defaultValue={formik.values?.coverNetworkAcupunture}
              onChange={e =>
                handleChangeInputRef(e, 'coverNetworkAcupunture', ref, setIsDirty)
              }
            >
              <S.RadioItems>
                <RadioButton value={true} rightPos label="Yes" />
                <RadioButton value={false} rightPos label="No" />
              </S.RadioItems>
            </RadioGroup>
          </S.Line>
        </S.BlockWrapper>
        <S.Line>
          <S.Caption>
            2. How many treatments/visits does it cover per calendar year?
          </S.Caption>
          <S.InputWrapper>
            <InputNumber
              label=""
              type="number"
              defaultValue={formik.values?.countTreatmensPerCalendarYear}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'countTreatmensPerCalendarYear', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <S.Caption>How many treatments/visits have been used this year?</S.Caption>
          <S.InputWrapper>
            <InputNumber
              label=""
              type="number"
              defaultValue={formik.values?.countTreatmensUsedThisYear}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChangeInputRef(e, 'countTreatmensUsedThisYear', ref, setIsDirty)
              }
            />
          </S.InputWrapper>
        </S.Line>
      </>
    );
  },
);

export default PreviousPT;
