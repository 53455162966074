import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftSVG, PrintWhiteSVG } from 'assets/icons';
import { useInvoiceGetPdfMutation } from 'store/api/invoices/invoiceApi';
import { Button, CircularLoader } from 'components';
import { InvoicesMainType } from 'types/InvoiceType';
import * as S from './styles';
import { InvInformPostPaymentTable } from './components/InvInformPostpaymentTable';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

const InvoicePostPaymentDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [getPdf, loadingPdfStatus] = useInvoiceGetPdfMutation();

  useEffect(() => {
    const toastId = toast(
      'For EOB updates, please click the Show Reports button and download the NEW reports',
      {
        type: 'info',
        autoClose: false,
      },
    );

    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, []);

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText onClick={() => navigate('/invoices/post-payment')}>
          <ArrowLeftSVG /> Invoice {params.id}
          <S.InvoiceTypeSpan>•</S.InvoiceTypeSpan>
          <S.InvoiceTypeSpan>Post-payment</S.InvoiceTypeSpan>
          <S.InvoiceTypeSpan>
            {/* {detailInvoice.currentData?.status && (
              <UserStatusPin type={detailInvoice.currentData?.status} />
            )} */}
          </S.InvoiceTypeSpan>
        </S.SubtitleText>
        <S.SubtitleItems>
          {loadingPdfStatus.isLoading && (
            <S.CircularWrapper>
              <CircularLoader color="#0084B1" size={20} />
            </S.CircularWrapper>
          )}
          <S.ButtonWrapper>
            <Button
              text="Print"
              backgroundColor="#848A9B"
              onClick={() => {
                getPdf({
                  id: params.id,
                  type: InvoicesMainType.POSTPAYMENT,
                })
                  .unwrap()
                  .then((res: any) => {})
                  .catch(err => {});
              }}
            >
              <PrintWhiteSVG />
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.SubtitleBar>
      <S.ContentWrapper>
        <S.Content>
          <InvInformPostPaymentTable />
        </S.Content>
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

export default InvoicePostPaymentDetail;
