import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
} from '@mui/material';
import * as S from './styles';
import { AddCircleBlueSVG, AddRectSVG, ArrowDownSVG } from '../../../assets/icons';
import { EventsInfo, EventType } from '../../../types/AppointmentsTypes';
import dayjs from 'dayjs';
import { AppointmentsForms } from '../../../types/TemplateType';
import React, { ChangeEvent, useRef } from 'react';
import {
  useAddManualDocsMutation,
  useFilesCreateMutation,
  useUploadInitialDocsMutation,
} from '../../../store/api/files/files';
import { useParams } from 'react-router-dom';
import CircularLoader from '../CircuralLoader';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { setMessage } from '../../../store/slices/message';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useFormsDocumentsMutation } from '../../../store/api/treatment/treatmentApi';

type Props = {
  eventsInfo?: EventsInfo;
  index?: number;
  title?: string;
  events?: EventType;
  isInitial?: boolean;
  docsCount?: number;
  clinic?: string;
  button?: string;
  appointmentForms?: AppointmentsForms;
} & AccordionProps;

const Accordeon: React.FC<Props> = ({
  eventsInfo,
  index,
  title,
  docsCount,
  events,
  isInitial,
  clinic,
  button,
  appointmentForms,
  ...props
}) => {
  const [uploadInitialFiles, uploadInitialFilesStatus] = useUploadInitialDocsMutation({});
  const [uploadManualFiles, uploadManualFilesStatus] = useAddManualDocsMutation({});

  const params = useParams();
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const fileList = e.target.files;

    if (fileList) {
      const files = [...fileList];

      uploadInitialFiles({ files: files, userId: params.patientId })
        .unwrap()
        .then(res => {})
        .catch(error => console.log(error));
    }
  };
  const handleAddManualFile = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const fileList = e.target.files;

    if (fileList) {
      const files = [...fileList];
      uploadManualFiles({
        files: files,
        userId: params.patientId,
        appointmentId: appointmentForms?.appointmentId,
        clinicId: selectedClinic.id,
      })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Document was successfully added',
              type: 'success',
            }),
          );
        })
        .catch(error => console.log(error));
    }
  };

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const { target = {} } = event || {};

    // @ts-ignore
    target.value = '';
  };

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const isLoading = [
    uploadInitialFilesStatus.isLoading,
    uploadManualFilesStatus.isLoading,
  ].some(Boolean);

  return (
    <S.Wrapper>
      <Accordion {...props}>
        <AccordionSummary expandIcon={<ArrowDownSVG />}>
          <S.TitleWrapper>
            <S.TitleReason>{title}</S.TitleReason>
            {isInitial && (
              <S.Btn
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <label htmlFor="fileclick">
                  <S.Btn>
                    <input
                      id="fileclick"
                      type="file"
                      hidden
                      accept="image/png, image/jpeg, application/pdf"
                      onChange={handleChangeFile}
                    />
                    <AddRectSVG className="svg" />
                    Add Documents
                  </S.Btn>
                </label>
              </S.Btn>
            )}
            {appointmentForms && appointmentForms.formsCount > 0 && (
              <>
                <S.HelperText>
                  Date: {dayjs(appointmentForms.appointmentDate).format('MM/DD/YYYY')}
                </S.HelperText>
              </>
            )}

            <S.HelperText>Docs: {docsCount}</S.HelperText>
            {clinic && <S.HelperText>Clinic: {clinic}</S.HelperText>}

            {isLoading && (
              <S.Loader>
                <CircularLoader color="#0084B1" size={16} />
              </S.Loader>
            )}
          </S.TitleWrapper>
          {button && (
            <S.ButtonAdd onClick={e => e.stopPropagation()}>
              <label htmlFor="file">
                <input
                  ref={inputRef}
                  type="file"
                  id="file"
                  onChange={handleAddManualFile}
                  accept="image/png, image/jpeg, image/jpg, image/tiff, application/pdf"
                  hidden
                  onClick={handleInputClick}
                />
              </label>
              <AddCircleBlueSVG onClick={handleClick} />
              <S.AddButton onClick={handleClick}>{button}</S.AddButton>
            </S.ButtonAdd>
          )}
        </AccordionSummary>
        <AccordionDetails onClick={e => e.stopPropagation()}>
          {props.children}
        </AccordionDetails>
      </Accordion>
    </S.Wrapper>
  );
};

export default Accordeon;
