import { useParams } from 'react-router-dom';
import {
  DeleteBlueBtnSVG,
  DeleteGrayBtnSVG,
  EditBlueBtnSVG,
  EditGrayBtnSVG,
  PlacePinSVG,
} from 'assets/icons';
import { useStaffRemoveFromClinicMutation } from 'store/api/clinics/clinicApi';
import { Clinics } from 'types/ClinicTypes';
import * as S from './styles';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setMessage } from 'store/slices/message';
import { Dialog } from 'components';
import AssignClinicDialog from '../AssignClinicDialog';
import { useState } from 'react';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { Roles } from 'types/Roles';
import { getRoleName } from 'helpers/functions/roleName';
import UpdateClinicDialog from '../UpdateClinicDialog';
import dayjs from 'dayjs';

type Props = {
  assignInfo: Clinics;
  clinicsList: Clinics[];
  isSpecialist?: boolean;
  userRole?: string;
};

const AssignRecord: React.FC<Props> = ({
  assignInfo,
  clinicsList,
  isSpecialist = false,
  userRole,
}) => {
  const [deleteFromClinic] = useStaffRemoveFromClinicMutation({});
  const { profileId } = useParams();
  const dispatch = useAppDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleSuccessDelete = () => {
    deleteFromClinic({
      clinicId: assignInfo.id,
      staffId: profileId,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: 'Clinic was successfully unassigned from user',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(setMessage({ message: error.data.message, type: 'error' }));
      });
  };

  return (
    <S.Wrapper $isWorkNow={assignInfo.isWorkInClinic || false}>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <UpdateClinicDialog
          userId={profileId || ''}
          assignInfo={assignInfo}
          onClose={() => setOpenDialog(!openDialog)}
          isSpecialist={isSpecialist}
        />
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <ConfirmDeleteDialog
          onClose={() => setOpenDeleteDialog(false)}
          fullTitle={`Do you really want to remove this ${isSpecialist ? 'Specialist' : getRoleName(userRole as Roles)} from the Clinic?`}
          helperText={'This action is irreversible.'}
          onSuccess={handleSuccessDelete}
        />
      </Dialog>

      <S.ClinicInfo>
        <PlacePinSVG />
        <S.ClinicInfoBox>
          <S.ClinicName>{assignInfo.name}</S.ClinicName>
          {isSpecialist && (
            <S.Date>
              Total working time:{' '}
              <S.SpanData>
                {assignInfo?.totalWorkingTime
                  ? assignInfo.totalWorkingTime + ' h'
                  : '0 h'}
              </S.SpanData>
            </S.Date>
          )}
          <S.Date>
            NPI: <S.SpanData>{assignInfo?.npi ? assignInfo?.npi : '-'}</S.SpanData>
          </S.Date>
        </S.ClinicInfoBox>
      </S.ClinicInfo>

      <S.WorkRangeContainer>
        <S.WorkRange>
          <S.Date>
            Start date:{' '}
            <S.SpanData>
              {(assignInfo.workingStartDate &&
                dayjs(assignInfo.workingStartDate).format('MM/DD/YYYY')) ||
                '-'}
            </S.SpanData>
          </S.Date>
          <S.Date>
            End date:{' '}
            <S.SpanData>
              {(assignInfo.workingEndDate &&
                dayjs(assignInfo.workingEndDate).format('MM/DD/YYYY')) ||
                'till now'}
            </S.SpanData>
          </S.Date>
        </S.WorkRange>
        <S.WorkRange>
          <S.Date>
            Taxonomy:{' '}
            <S.SpanData>{assignInfo?.taxonomy ? assignInfo?.taxonomy : '-'}</S.SpanData>
          </S.Date>
          <S.Date>
            Taxonomy name:{' '}
            <S.SpanData>
              {assignInfo?.taxonomyName ? assignInfo?.taxonomyName : '-'}
            </S.SpanData>
          </S.Date>
        </S.WorkRange>
      </S.WorkRangeContainer>

      {assignInfo.isWorkInClinic ? (
        <S.Buttons>
          <S.ButtonWrap
            $isWorkNow={assignInfo.isWorkInClinic || false}
            onClick={() => setOpenDialog(!openDialog)}
          >
            {assignInfo.isWorkInClinic ? <EditBlueBtnSVG /> : <EditGrayBtnSVG />}
          </S.ButtonWrap>
          {clinicsList?.length > 1 && (
            <S.ButtonWrap
              $isWorkNow={assignInfo.isWorkInClinic || false}
              onClick={() => setOpenDeleteDialog(true)}
            >
              {assignInfo.isWorkInClinic ? <DeleteBlueBtnSVG /> : <DeleteGrayBtnSVG />}
            </S.ButtonWrap>
          )}
        </S.Buttons>
      ) : (
        <div></div>
      )}
    </S.Wrapper>
  );
};

export default AssignRecord;
