import { useState } from 'react';
import { AddPatientsSVG } from 'assets/icons';
import { Button, CustomPagination, CustomTable, Dialog } from 'components';
import * as S from '../../styles';
import { useParams } from 'react-router-dom';
import FacilityDoctorRow from './TableData/FacilityDoctorRow';
import { useAllFacilityDoctorsGetQuery } from '../../../../../../store/api/facilities/facilityApi';
import AssignDoctorDialog from '../../../ProfileFacility/components/AssingDoctorDialog';

type Props = {
  deletable?: boolean;

  isSpecialists?: boolean;
};

const DOCTORS_LIMIT = 20;

const FacilityDoctors: React.FC<Props> = ({ deletable, isSpecialists }) => {
  const params = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(1);

  const getDoctorsList = useAllFacilityDoctorsGetQuery({
    id: params.facilityId,
    page: page,
    take: 20,
  });

  const headers = [
    { name: 'Doctor name', key: 'DoctorName' },
    { name: 'NPI', key: 'Npi' },
    { name: 'Taxonomy', key: 'Taxonomy' },
    ...(deletable ? [{ name: '', key: 'Edit' }] : []),
  ];

  return (
    <S.TableBody>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <AssignDoctorDialog
          onClose={() => setOpenDialog(!openDialog)}
          facilityId={params.facilityId}
          getDoctorsList={getDoctorsList?.currentData?.rows}
        />
      </Dialog>
      <S.HeaderTable>
        <CustomPagination
          isFetching={getDoctorsList.isFetching}
          setPage={setPage}
          currentPage={getDoctorsList.currentData?.page || 1}
          lastPage={getDoctorsList.currentData?.lastPage || 1}
          count={getDoctorsList?.currentData?.count || 10}
          text={'Doctors'}
          limit={DOCTORS_LIMIT}
        />
        <S.SubtitleItems>
          <S.ButtonWrapper>
            <Button
              text={'Assign Doctor'}
              onClick={() => {
                setOpenDialog(!openDialog);
              }}
            >
              <AddPatientsSVG />
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getDoctorsList.isFetching}>
        <FacilityDoctorRow
          clickable
          data={getDoctorsList?.currentData?.rows}
          headers={headers}
          deletable={deletable}
        />
      </CustomTable>
    </S.TableBody>
  );
};

export default FacilityDoctors;
