import { Autocomplete, Box, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import * as S from './styles';
import { AvatarSVG } from 'assets/icons';
import { AllFacilities, FacilityDate } from '../../../types/FacilityTypes';

type Props = {
  mainLabel?: string;
  isRequired?: boolean;
  facilityList?: FacilityDate[];
  data: Array<any>;
  loading: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  selectedValue: any;
  setSelectedValue: (value: any) => void;
  onDoubleClick?: () => void;
  getOptionLabel?: (option: any) => string;
  options?: Array<any>;
  customRender?: boolean;
  disabled?: boolean;
  customRenderInput?: any;
  error?: boolean;
  helperText?: string;
  serverSelected?: Array<any>;
  getOptionDisabled?: (option: any) => boolean;
  defaultValue?: string;
  setUserLastElement?: Dispatch<
    SetStateAction<HTMLDivElement | HTMLTableRowElement | null>
  >;
  setPage?: (page: number) => void;
  isOptionEqualToValue?: (option: any, val: any) => boolean;
};

const AutoCompleteAllFacilitiesInput: React.FC<Props> = ({
  mainLabel,
  isRequired,
  data,
  loading,
  searchValue,
  disabled,
  selectedValue,
  setSearchValue,
  setSelectedValue,
  setUserLastElement,
  facilityList,
  onDoubleClick,
  getOptionLabel,
  customRender,
  error,
  helperText,
  getOptionDisabled,
  setPage,
  isOptionEqualToValue,
}) => {
  useEffect(() => {
    if (data && selectedValue) {
      const currentValue = data.find(item => item.id === selectedValue);
      if (currentValue) {
        setSelectedValue(currentValue.id);
      }
    }
  }, [data, selectedValue]);

  const isFacilityDisabled = (option: any): boolean => {
    return !!facilityList?.some(
      facility => facility.facility.id === option.id && facility.isActive,
    );
  };

  return (
    <S.Wrapper onDoubleClick={onDoubleClick}>
      <S.Label>
        {mainLabel} {isRequired && <S.RequiredSpan>*</S.RequiredSpan>}
      </S.Label>
      <Autocomplete
        value={data.find(item => item.id === selectedValue) || null}
        onChange={(e, newValue) => setSelectedValue(newValue?.id || null)}
        disabled={disabled}
        options={data || []}
        isOptionEqualToValue={(option, value) => option.id === value}
        getOptionLabel={option => `${option.facilityName || ''}`}
        renderOption={(props, option, state) => {
          const isLast = state.index === data.length - 1;
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              ref={isLast ? setUserLastElement : null}
            >
              <S.LiWrapper>
                <S.UserInfo>
                  <S.TextWrap>{`${option.facilityName || ''}`}</S.TextWrap>
                </S.UserInfo>
              </S.LiWrapper>
            </Box>
          );
        }}
        inputValue={searchValue}
        onInputChange={(e, newInputValue) => {
          setSearchValue(newInputValue);
          if (setPage) setPage(1);
        }}
        renderInput={params => (
          <TextField
            {...params}
            required={isRequired}
            error={error}
            helperText={helperText}
            placeholder="Search for facilities"
          />
        )}
        getOptionDisabled={isFacilityDisabled}
        loading={loading}
      />
    </S.Wrapper>
  );
};

export default AutoCompleteAllFacilitiesInput;
