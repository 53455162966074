import { useState } from 'react';
import { AddPatientsSVG } from 'assets/icons';
import { Button, CustomPagination, CustomTable, Dialog, SearchInput } from 'components';
import * as S from '../../styles';
import StaffDoctorRow from './TableData/StaffDoctorRow';
import { useStaffGetQuery } from 'store/api/clinics/clinicApi';
import { TabsEnum } from 'types/enums/TabsEnum';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import AssignClinicDialog from '../../../ProfileStaff/components/AssignTo/components/AssignClinicDialog';
import { Roles } from 'types/Roles';

type Props = {
  deletable?: boolean;
  fromClinic?: boolean;

  isSpecialists?: boolean;
};

const DOCTORS_LIMIT = 20;

const StaffDoctors: React.FC<Props> = ({ deletable, isSpecialists, fromClinic }) => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const params = useParams();
  const dispatch = useAppDispatch();
  const { role } = useAppSelector(state => state.auth);

  const [openDialog, setOpenDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);

  const getReceptionistsList = useStaffGetQuery({
    position: TabsEnum.DOCTOR,
    id: params.clinicId || selectedClinic.id,
    page: page,
    take: 20,
    q: debouncedSearchValue,
    isSpecialist: !!isSpecialists,
  });

  const headers = [
    { name: 'Doctor name', key: 'DoctorName' },
    { name: 'Clinic name', key: 'ClinicName' },
    { name: 'Phone', key: 'Phone' },
    //...(isSpecialists ? [{ name: 'Speciality', key: 'Speciality' }] : []),
    { name: 'Speciality', key: 'Speciality' },
    { name: 'Email', key: 'Email' },
    { name: 'Status', key: 'Status' },
    ...(deletable ? [{ name: '', key: 'Edit' }] : []),
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  return (
    <S.TableBody>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <AssignClinicDialog
          onClose={() => setOpenDialog(!openDialog)}
          role={Roles.DOCTOR}
          clinicId={params.clinicId}
          isSpecialists={isSpecialists}
          getReceptionistsList={getReceptionistsList.currentData?.rows}
        />
      </Dialog>
      <S.HeaderTable>
        <CustomPagination
          isFetching={getReceptionistsList.isFetching}
          setPage={setPage}
          currentPage={getReceptionistsList.currentData?.page}
          lastPage={getReceptionistsList.currentData?.lastPage}
          count={getReceptionistsList?.currentData?.count}
          text={'Doctors'}
          limit={DOCTORS_LIMIT}
        />
        <S.SubtitleItems>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>

          {!fromClinic && role === Roles.SUPER_ADMIN && (
            <S.ButtonWrapper>
              <Button
                text={isSpecialists ? 'Add Specialists' : 'Add Doctor'}
                onClick={() =>
                  dispatch(
                    showDrawer({
                      mode: DrawerMode.ADD_DOCTOR,
                      props: isSpecialists ? true : null,
                      show: true,
                    }),
                  )
                }
              >
                <AddPatientsSVG />
              </Button>
            </S.ButtonWrapper>
          )}

          {fromClinic && (
            <S.ButtonWrapper>
              <Button
                text={isSpecialists ? 'Assign Specialists' : 'Assign Doctor'}
                onClick={() => {
                  setOpenDialog(!openDialog);
                }}
              >
                <AddPatientsSVG />
              </Button>
            </S.ButtonWrapper>
          )}
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getReceptionistsList.isFetching}>
        <StaffDoctorRow
          clickable
          data={getReceptionistsList?.currentData?.rows}
          headers={headers}
          deletable={deletable}
        />
      </CustomTable>
    </S.TableBody>
  );
};

export default StaffDoctors;
