import styled from 'styled-components';
import { AccessLevel } from '../../../../../../types/enums/AppointmentEnum';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
`;

export const CellWrapper = styled.div<{ editable?: AccessLevel; disabled?: boolean }>`
  /* border: 1px solid red; */
  width: 100%;
  padding: 5px 0;
  max-width: 350px;
  word-break: normal;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  user-select: none;
  &:hover {
    color: #4570de;
    cursor: ${props => {
      return props.editable === AccessLevel.READ ? 'auto' : 'cell';
    }};
  }

  &.min {
    min-width: 80px;
  }

  &.cursor {
    cursor: auto;
  }
  ${({ disabled }) =>
    disabled &&
    `
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const Avatar = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 20px;

  img {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    object-fit: cover;
  }

  svg {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const Loader = styled.div``;

export const ButtonShow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 11px;

  svg {
    circle {
      stroke: #0084b1;
    }
    path {
      stroke: #0084b1;
    }
  }

  &:hover {
    svg {
      circle {
        stroke: #4570de;
      }
      path {
        stroke: #4570de;
      }
    }
  }
`;

export const Text = styled.div`
  color: var(--main, #0084b1);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    color: #4570de;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

export const Link = styled.div`
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  user-select: none;

  display: flex;
  align-items: center;
  gap: 10px;

  &:hover {
    color: #4570de;
  }

  &.simply {
    cursor: default;
    &:hover {
      color: #0084b1;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 200px;
`;

export const MenuItemContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const CptWrapper = styled.div<{ editable?: AccessLevel }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid #e0e0e0;
  padding: 4px 33px;
  justify-content: center;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: #4570de;
    cursor: ${props => {
      return props.editable === AccessLevel.READ ? 'auto' : 'cell';
    }};
  }
`;

export const ICDWrapper = styled.div<{ editable?: AccessLevel }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  &:hover {
    color: #4570de;
    cursor: ${props => {
      return props.editable === AccessLevel.READ ? 'auto' : 'cell';
    }};
  }
`;

export const DOSWrapper = styled.div<{ editable?: AccessLevel }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid #e0e0e0;
  padding: 4px 33px;
  justify-content: center;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    color: #4570de;
    cursor: ${props => {
      return props.editable === AccessLevel.READ ? 'auto' : 'cell';
    }};
  }
`;
export const ChargeWrapper = styled.div<{ editable?: AccessLevel; disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  border-bottom: 1px solid #e0e0e0;
  padding: 8px 33px;

  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: #4570de;
    cursor: ${props => {
      return props.editable === AccessLevel.READ ? 'auto' : 'cell';
    }};
  }
  ${({ disabled }) =>
    disabled &&
    `
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const CptCellWrapper = styled.div<{ disabled?: boolean }>`
  padding: 5px 0;
  max-width: 350px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  &.min {
    min-width: 80px;
  }

  &.cursor {
    cursor: auto;
  }

  &:after {
    content: ', ';
  }

  &:last-child {
    &:after {
      content: '';
    }
  }
  ${({ disabled }) =>
    disabled &&
    `
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    `}
`;
export const DOSCellWrapper = styled.div<{ disabled?: boolean }>`
  padding: 5px 0;
  max-width: 350px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  &.min {
    min-width: 80px;
  }

  &.cursor {
    cursor: auto;
  }

  &:after {
    content: ', ';
  }

  &:last-child {
    &:after {
      content: '';
    }
  }
  ${({ disabled }) =>
    disabled &&
    `
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    `}
`;
