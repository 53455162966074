import { Button, DatePicker, Input } from 'components';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useDoctorsFacilitiesSaveMutation } from '../../../../../../store/api/facilities/facilityApi';

import {
  AddCircleBlueSVG,
  DeleteBtnSVG,
  DeleteRedBtnSVG,
} from '../../../../../../assets/icons';
import { DoctorsDate } from '../../../../../../types/FacilityTypes';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
  onClose: () => void;
  doctorId?: string;
  doctorInfo?: DoctorsDate;
};

const EditFacilityDoctorsDialog = ({ onClose, doctorId, doctorInfo }: Props) => {
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [firstWorkDay, setFirstWorkDay] = useState<Dayjs>(dayjs());
  const [lastWorkDay, setLastWorkDay] = useState<Dayjs | string>('');
  const [saveOn] = useDoctorsFacilitiesSaveMutation({});
  const [rows, setRows] = useState([{ taxonomy: '', taxonomyName: '' }]);
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<{ taxonomy: boolean; taxonomyName: boolean }[]>([
    { taxonomy: false, taxonomyName: false },
  ]);

  const [dateErrors, setDateErrors] = useState<{
    firstWorkDay: boolean;
  }>({
    firstWorkDay: false,
  });

  useEffect(() => {
    if (doctorInfo) {
      setSelectedValue(
        doctorInfo.doctor?.profile?.firstName +
          ' ' +
          doctorInfo.doctor?.profile?.lastName,
      );
      doctorInfo.endDate && setLastWorkDay(dayjs(doctorInfo?.endDate));
      doctorInfo.startDate && setFirstWorkDay(dayjs(doctorInfo?.startDate));
      if (doctorInfo?.taxonomyes?.length > 0) {
        setRows(doctorInfo.taxonomyes);
      }
    }
  }, [doctorInfo]);

  const handleAddRow = () => {
    setRows([...rows, { taxonomy: '', taxonomyName: '' }]);
    setErrors([...errors, { taxonomy: false, taxonomyName: false }]);
  };

  const handleRemoveRow = (index: number) => {
    if (rows.length > 1) {
      setRows(rows.filter((_, i) => i !== index));
      setErrors(errors.filter((_, i) => i !== index));
    }
  };

  const handleInputChange = (
    index: number,
    field: keyof (typeof rows)[0],
    value: string,
  ) => {
    setRows(prevRows => {
      const updatedRows = [...prevRows];
      updatedRows[index] = { ...updatedRows[index], [field]: value };
      return updatedRows;
    });
    const updatedErrors = [...errors];
    updatedErrors[index][field] = !value.trim();
    setErrors(updatedErrors);
  };

  const validateFields = () => {
    const newErrors = rows.map(row => ({
      taxonomy: !row.taxonomy.trim(),
      taxonomyName: !row.taxonomyName.trim(),
    }));
    const newDateErrors = {
      firstWorkDay: !firstWorkDay,
    };

    setErrors(newErrors);
    setDateErrors(newDateErrors);

    return (
      newErrors.every(err => !err.taxonomy && !err.taxonomyName) &&
      !newDateErrors.firstWorkDay
    );
  };
  const handleSave = async () => {
    if (!doctorInfo) {
      dispatch(setMessage({ message: 'Doctor information is missing', type: 'error' }));
      return;
    }

    if (!validateFields()) {
      dispatch(
        setMessage({ message: 'Please fill in all required fields', type: 'error' }),
      );
      return;
    }

    try {
      await saveOn({
        doctorId,
        facilityId: doctorInfo.facilityId,
        taxonomyes: rows,
        startDate: dayjs(firstWorkDay).format('YYYY-MM-DD'),
        ...(lastWorkDay && {
          endDate: dayjs(lastWorkDay).format('YYYY-MM-DD'),
        }),
      });

      onClose();
      dispatch(
        setMessage({
          message: 'Doctor was successfully updated',
          type: 'success',
        }),
      );
    } catch (error: any) {
      onClose();
      dispatch(
        setMessage({
          message: error?.data?.message || 'Failed to update doctor',
          type: 'error',
        }),
      );
    }
  };

  return (
    <S.Content>
      <S.Article>{'Edit Doctor'}</S.Article>
      <S.InputWrapper>
        <S.InputAutoCompleteWrapper>
          <S.NameClinic>{selectedValue}</S.NameClinic>
        </S.InputAutoCompleteWrapper>
      </S.InputWrapper>
      <S.InputDateWrapper>
        <DatePicker
          isRequired
          label="First work day"
          value={firstWorkDay}
          onChange={value => setFirstWorkDay(value)}
          error={dateErrors.firstWorkDay}
        />
        <DatePicker
          label="Last work day"
          value={lastWorkDay}
          onChange={value => setLastWorkDay(value)}
        />
      </S.InputDateWrapper>
      <S.InputTaxonomyWrapper>
        <S.Taxonomy>Taxonomy</S.Taxonomy>
        <S.AddTaxonomy onClick={handleAddRow}>
          <AddCircleBlueSVG />
          Add Taxonomy
        </S.AddTaxonomy>
      </S.InputTaxonomyWrapper>
      <S.InputWrapper>
        {rows.map((row, index) => (
          <S.InputRow key={index}>
            <Input
              id={`taxonomy-${index}`}
              name={`taxonomy-${index}`}
              label="Taxonomy"
              value={row.taxonomy}
              isRequired
              error={errors[index]?.taxonomy}
              onChange={e => handleInputChange(index, 'taxonomy', e.target.value)}
            />
            <Input
              id={`taxonomyName-${index}`}
              name={`taxonomyName-${index}`}
              label="Taxonomy name"
              value={row.taxonomyName}
              isRequired
              error={errors[index]?.taxonomyName}
              onChange={e => handleInputChange(index, 'taxonomyName', e.target.value)}
            />
            {rows.length > 1 ? (
              <S.SVGWrapper disable={false}>
                <DeleteRedBtnSVG onClick={() => handleRemoveRow(index)} />
              </S.SVGWrapper>
            ) : (
              <S.SVGWrapper disable>
                <DeleteBtnSVG />
              </S.SVGWrapper>
            )}
          </S.InputRow>
        ))}
      </S.InputWrapper>
      <S.ButtonWrapper>
        <Button text={'Save'} onClick={handleSave} />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default EditFacilityDoctorsDialog;
