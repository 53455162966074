import { Button, DatePicker, Input } from 'components';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  useDoctorAssignToFacilityMutation,
  useDoctorsFacilitiesSaveMutation,
} from '../../../../../../store/api/facilities/facilityApi';

import {
  AddCircleBlueSVG,
  DeleteBtnSVG,
  DeleteRedBtnSVG,
} from '../../../../../../assets/icons';
import { GetFacilitiesDoctorList } from './GetFacilitiesDoctorList';
import { FacilityDate } from '../../../../../../types/FacilityTypes';
import dayjs, { Dayjs } from 'dayjs';

type Props = {
  onClose: () => void;
  doctorId?: string;
  assignInfo?: FacilityDate;
  facilityList?: FacilityDate[];
};

const AssignFacilityDialog = ({ onClose, doctorId, assignInfo, facilityList }: Props) => {
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [assignOn] = useDoctorAssignToFacilityMutation({});
  const [firstWorkDay, setFirstWorkDay] = useState<Dayjs>(dayjs());
  const [lastWorkDay, setLastWorkDay] = useState<Dayjs | string>('');
  const [saveOn] = useDoctorsFacilitiesSaveMutation({});
  const [rows, setRows] = useState([{ taxonomy: '', taxonomyName: '' }]);
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<{ taxonomy: boolean; taxonomyName: boolean }[]>([
    { taxonomy: false, taxonomyName: false },
  ]);
  const [dateErrors, setDateErrors] = useState<{
    firstWorkDay: boolean;
    // lastWorkDay: boolean;
  }>({
    firstWorkDay: false,
    // lastWorkDay: false,
  });

  useEffect(() => {
    if (assignInfo) {
      setSelectedValue(assignInfo.facility?.facilityName);
      assignInfo.endDate && setLastWorkDay(dayjs(assignInfo.endDate));
      assignInfo.startDate && setFirstWorkDay(dayjs(assignInfo.startDate));
      if (assignInfo.taxonomyes.length > 0) {
        setRows(assignInfo.taxonomyes);
      }
    }
  }, [assignInfo]);

  const handleAddRow = () => {
    setRows([...rows, { taxonomy: '', taxonomyName: '' }]);
    setErrors([...errors, { taxonomy: false, taxonomyName: false }]);
  };

  const handleRemoveRow = (index: number) => {
    if (rows.length > 1) {
      setRows(rows.filter((_, i) => i !== index));
      setErrors(errors.filter((_, i) => i !== index));
    }
  };

  const handleInputChange = (
    index: number,
    field: keyof (typeof rows)[0],
    value: string,
  ) => {
    setRows(prevRows => {
      const updatedRows = [...prevRows];
      updatedRows[index] = { ...updatedRows[index], [field]: value };
      return updatedRows;
    });
    const updatedErrors = [...errors];
    updatedErrors[index][field] = !value.trim();
    setErrors(updatedErrors);
  };

  const getPreviousWorkingDates = (
    facilityList: FacilityDate[] | undefined,
    facilityId: string,
  ) => {
    if (!assignInfo && !facilityId) return null;
    if (assignInfo) {
      return assignInfo.previousDates || null;
    }
    const facility = facilityList?.find(item => item.facility.id === facilityId);
    return facility?.previousDates || null;
  };

  const validateFields = () => {
    const newErrors = rows.map(row => ({
      taxonomy: !row.taxonomy.trim(),
      taxonomyName: !row.taxonomyName.trim(),
    }));
    const newDateErrors = {
      firstWorkDay: !firstWorkDay,
      // lastWorkDay: !lastWorkDay,
    };

    setErrors(newErrors);
    setDateErrors(newDateErrors);

    return (
      newErrors.every(err => !err.taxonomy && !err.taxonomyName) &&
      !newDateErrors.firstWorkDay
      // && !newDateErrors.lastWorkDay
    );
  };
  const handleAssign = () => {
    if (!selectedValue) {
      dispatch(setMessage({ message: 'Please select a doctor', type: 'error' }));
      return;
    }

    if (!validateFields()) {
      dispatch(
        setMessage({ message: 'Please fill in all required fields', type: 'error' }),
      );
      return;
    }

    if (!!assignInfo) {
      saveOn({
        doctorId,
        facilityId: assignInfo.facility.id,
        taxonomyes: rows,
        startDate: dayjs(firstWorkDay).format('YYYY-MM-DD'),
        ...(lastWorkDay && {
          endDate: dayjs(lastWorkDay).format('YYYY-MM-DD'),
        }),
      })
        .then(() => {
          onClose();
          dispatch(
            setMessage({
              message: 'Facility was successfully updated',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          onClose();
          dispatch(
            setMessage({
              message: error.data.message || 'Failed to update facility',
              type: 'error',
            }),
          );
        });
    } else {
      assignOn({
        doctorId,
        facilityId: selectedValue,
        taxonomyes: rows,
        startDate: dayjs(firstWorkDay).format('YYYY-MM-DD'),
        ...(lastWorkDay && {
          endDate: dayjs(lastWorkDay).format('YYYY-MM-DD'),
        }),
      })
        .unwrap()
        .then(() => {
          onClose();
          dispatch(
            setMessage({
              message: 'Facility was successfully assigned to doctor',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          onClose();
          dispatch(
            setMessage({
              message: error.data.message || 'Failed to assign facility',
              type: 'error',
            }),
          );
        });
    }
  };
  return (
    <S.Content>
      <S.Article>{!!assignInfo ? 'Edit Facility' : 'Assign Facility'}</S.Article>
      <S.InputWrapper>
        <S.InputAutoCompleteWrapper>
          {!!assignInfo ? (
            <S.NameClinic>{selectedValue}</S.NameClinic>
          ) : (
            <GetFacilitiesDoctorList
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
              facilityList={facilityList}
            />
          )}
        </S.InputAutoCompleteWrapper>
      </S.InputWrapper>
      <S.InputDateWrapper>
        <DatePicker
          isRequired
          label="First work day"
          value={firstWorkDay}
          onChange={value => setFirstWorkDay(value)}
          error={dateErrors.firstWorkDay}
        />
        <DatePicker
          // isRequired
          label="Last work day"
          value={lastWorkDay}
          onChange={value => setLastWorkDay(value)}
          // error={dateErrors.lastWorkDay}
        />
      </S.InputDateWrapper>

      {getPreviousWorkingDates(facilityList, selectedValue)?.map(date => (
        <S.InputPrevDateWrapper>
          <S.PrevDate>
            Previous start date:{' '}
            <S.EndDate>{dayjs(date.startDate).format('MM/DD/YYYY')}</S.EndDate>
          </S.PrevDate>
          <S.PrevDate>
            End date: <S.EndDate>{dayjs(date.endDate).format('MM/DD/YYYY')}</S.EndDate>
          </S.PrevDate>
        </S.InputPrevDateWrapper>
      ))}
      <S.InputTaxonomyWrapper>
        <S.Taxonomy>Taxonomy</S.Taxonomy>
        <S.AddTaxonomy onClick={handleAddRow}>
          <AddCircleBlueSVG />
          Add Taxonomy
        </S.AddTaxonomy>
      </S.InputTaxonomyWrapper>
      <S.InputWrapper>
        {rows.map((row, index) => (
          <S.InputRow key={index}>
            <Input
              id={`taxonomy-${index}`}
              name={`taxonomy-${index}`}
              label="Taxonomy"
              value={row.taxonomy}
              isRequired
              error={errors[index]?.taxonomy}
              onChange={e => handleInputChange(index, 'taxonomy', e.target.value)}
            />
            <Input
              id={`taxonomyName-${index}`}
              name={`taxonomyName-${index}`}
              label="Taxonomy name"
              value={row.taxonomyName}
              isRequired
              error={errors[index]?.taxonomyName}
              onChange={e => handleInputChange(index, 'taxonomyName', e.target.value)}
            />
            {rows.length > 1 ? (
              <S.SVGWrapper disable={false}>
                <DeleteRedBtnSVG onClick={() => handleRemoveRow(index)} />
              </S.SVGWrapper>
            ) : (
              <S.SVGWrapper disable>
                <DeleteBtnSVG />
              </S.SVGWrapper>
            )}
          </S.InputRow>
        ))}
      </S.InputWrapper>
      <S.ButtonWrapper>
        <Button
          text={!!assignInfo ? 'Save' : 'Assign'}
          onClick={handleAssign}
          disabled={!selectedValue}
        />
      </S.ButtonWrapper>
    </S.Content>
  );
};

export default AssignFacilityDialog;
