import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  useAllDoctorsGetQuery,
  useAllFacilitiesForDoctorGetQuery,
} from '../../../../../../store/api/facilities/facilityApi';
import AutoCompleteAllDoctorsInput from '../../../../../../components/atoms/AutoComplete/AutoCompleteAllDoctorsInput';
import AutoCompleteAllFacilitiesInput from 'components/atoms/AutoComplete/AutoCompleteAllFacilitiesInput';
import { AllFacilities, FacilityDate } from '../../../../../../types/FacilityTypes';
import useInfinityScroll from '../../../../../../hooks/useInfinityScroll';
import { debounce } from 'lodash';

type Props = {
  selectedValue: any;
  setSelectedValue: (selectedValue: any) => void;
  facilityList?: FacilityDate[];
};
const FACILITIES_LIMIT = 10;

export const GetFacilitiesDoctorList = ({
  selectedValue,
  setSelectedValue,
  facilityList,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const { data, isFetching, refetch } = useAllFacilitiesForDoctorGetQuery({
    q: debouncedSearchValue || undefined,
    page,
    take: FACILITIES_LIMIT,
  });

  const { setLastElement } = useInfinityScroll({
    fetchNextData: () => setPage(prevPage => prevPage + 1),
    hasMore: (data?.page ?? 0) < (data?.lastPage ?? 0),
  });

  useEffect(() => {
    setPage(1);
  }, [debouncedSearchValue]);

  useEffect(() => {
    refetch();
  }, [page, debouncedSearchValue, refetch]);

  return (
    <AutoCompleteAllFacilitiesInput
      isRequired
      data={data?.rows || []}
      mainLabel={'Facilities'}
      loading={isFetching}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      facilityList={facilityList}
      setPage={setPage}
      setUserLastElement={setLastElement}
    />
  );
};
