import React from 'react';

import * as S from './styles';
import Button from '../../../atoms/Button';

type Props = {
  onClose: () => void;
  fullTitle?: string;
  helperText?: string | React.ReactNode;
};

const ClaimErrorDialog: React.FC<Props> = ({ onClose, fullTitle, helperText }) => {
  return (
    <S.Content>
      <S.Article>{fullTitle || 'An error occurred with this Claim'}</S.Article>

      {helperText && <S.HelperText>{helperText}</S.HelperText>}
      <S.Buttons>
        <S.ButtonWrapper>
          <Button text="Close" onClick={onClose} backgroundColor="#C66060" />
        </S.ButtonWrapper>
      </S.Buttons>
    </S.Content>
  );
};

export default ClaimErrorDialog;
