import { UserProfile } from '../../../types/UserProfileTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
};

export const userApi = api.injectEndpoints({
  endpoints: builder => ({
    mailListGet: builder.query<any, any>({
      query: ({ ...params }) => {
        return {
          url: '/appointment-notification',
          params: { ...params },
        };
      },
      providesTags: ['Mail'],
    }),
    mailListUpdate: builder.mutation({
      query: body => ({
        url: `/appointment-notification/bulk`,
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: () => [{ type: 'Mail' }],
    }),
    mailListSend: builder.mutation({
      query: ({ id, ...params }) => ({
        url: `/appointment-notification/${id}/send`,
        params: { ...params },
        method: 'PATCH',
      }),
      invalidatesTags: () => [{ type: 'Mail' }],
    }),
  }),
});

export const { useMailListGetQuery, useMailListUpdateMutation, useMailListSendMutation } =
  userApi;
