import { useDispatch } from 'react-redux';
import { Button, Input, SelectInput } from 'components';
import * as S from './styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ChangeEvent } from 'react';
import { setMessage } from 'store/slices/message';
import { DrawerMode } from 'types/DrawerMode';
import { showDrawer } from 'store/slices/drawerSlice';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { StatesUSA } from 'constants/statesUSA';
import ReactInputMask from 'react-input-mask';
import { emailValidator, phoneValidator } from 'constants/constants';
import { useFacilityCreateMutation } from '../../../../store/api/facilities/facilityApi';

type FormValues = {
  facilityName: string;
  phone: string;
  email: string;
  npi: string;
  ein: string;
  primaryState: string;
  primaryCity: string;
  primaryStreet: string;
  primaryZipCode: string;
  billingState: string;
  billingCity: string;
  billingStreet: string;
  billingZipCode: string;
};

const Shape = yup.object().shape({
  facilityName: yup.string().required('Is required'),
  phone: phoneValidator,
  email: emailValidator,
  primaryState: yup.string().required('Is required'),
  primaryCity: yup.string().required('Is required'),
  primaryStreet: yup.string().required('Is required'),
  primaryZipCode: yup.string().required('Is required'),
  billingState: yup.string().required('Is required'),
  billingCity: yup.string().required('Is required'),
  billingStreet: yup.string().required('Is required'),
  billingZipCode: yup.string().required('Is required'),
  npi: yup.string().required('Is required'),
  ein: yup.string().required('Is required'),
});

const AddFacility = () => {
  const dispatch = useDispatch();
  const [createFacility] = useFacilityCreateMutation({});

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      facilityName: '',
      phone: '',
      email: '',
      npi: '',
      ein: '',
      primaryState: '',
      primaryCity: '',
      primaryStreet: '',
      primaryZipCode: '',
      billingState: '',
      billingCity: '',
      billingStreet: '',
      billingZipCode: '',
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: () => {
      const data = {
        facilityName: values.facilityName,
        phone: values.phone,
        email: values.email,
        npi: values.npi,
        ein: values.ein,
        primaryState: values.primaryState,
        primaryCity: values.primaryCity,
        primaryStreet: values.primaryStreet,
        primaryZipCode: values.primaryZipCode,
        billingState: values.billingState,
        billingCity: values.billingCity,
        billingStreet: values.billingStreet,
        billingZipCode: values.billingZipCode,
      };

      createFacility({
        ...data,
      })
        .unwrap()
        .then(res => {
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
          dispatch(
            setMessage({
              message: 'Facility was successfully created',
              type: 'success',
            }),
          );
          resetForm();
        })
        .catch(error => {
          dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.Wrapper>
        <S.Content>
          <S.ArticleWrap>
            <S.Article>Add Facility</S.Article>
          </S.ArticleWrap>
          <S.PaymentInformation>
            <S.InputRow>
              <Input
                id="facilityName"
                name="facilityName"
                label="Facility name"
                isRequired
                value={values.facilityName}
                onChange={handleChangeInput}
                error={!!errors.facilityName}
                helperText={errors.facilityName}
              />
            </S.InputRow>
            <S.InputRow>
              <ReactInputMask
                mask="+19999999999"
                value={values.phone}
                onChange={handleChangeInput}
                disabled={false}
              >
                <Input
                  label="Phone "
                  id="phone"
                  isRequired
                  name="phone"
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </ReactInputMask>

              <Input
                id="email"
                name="email"
                label="Email"
                isRequired
                value={values.email}
                onChange={handleChangeInput}
                error={!!errors.email}
                helperText={errors.email}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="npi"
                name="npi"
                label="NPI"
                isRequired
                value={values.npi}
                onChange={handleChangeInput}
                error={!!errors.npi}
                helperText={errors.npi}
              />
              <Input
                id="ein"
                name="ein"
                label="EIN"
                isRequired
                value={values.ein}
                onChange={handleChangeInput}
                error={!!errors.ein}
                helperText={errors.ein}
              />
            </S.InputRow>
            <S.SubArticle>Primary Address</S.SubArticle>
            <S.InputRow>
              <Input
                id="primaryStreet"
                name="primaryStreet"
                label="Street"
                isRequired
                value={values.primaryStreet}
                onChange={handleChangeInput}
                error={!!errors.primaryStreet}
                helperText={errors.primaryStreet}
              />
              <Input
                id="primaryCity"
                name="primaryCity"
                label="City"
                isRequired
                value={values.primaryCity}
                onChange={handleChangeInput}
                error={!!errors.primaryCity}
                helperText={errors.primaryCity}
              />
            </S.InputRow>
            <S.InputRow>
              <SelectInput
                label="State"
                isRequired
                id="primaryState"
                name="primaryState"
                value={values.primaryState}
                error={!!errors.primaryState}
                helperText={errors.primaryState}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setFieldError('primaryState', '');
                  handleChange(e);
                }}
              >
                {StatesUSA.map(state => (
                  <MenuItem key={state.abbreviation} value={state.abbreviation}>
                    <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </SelectInput>
              <Input
                id="primaryZipCode"
                name="primaryZipCode"
                label="ZipCode"
                isRequired
                value={values.primaryZipCode}
                onChange={handleChangeInput}
                error={!!errors.primaryZipCode}
                helperText={errors.primaryZipCode}
              />
            </S.InputRow>
            <S.SubArticle>Billing Address</S.SubArticle>
            <S.InputRow>
              <Input
                id="billingStreet"
                name="billingStreet"
                label="Street"
                isRequired
                value={values.billingStreet}
                onChange={handleChangeInput}
                error={!!errors.billingStreet}
                helperText={errors.billingStreet}
              />
              <Input
                id="billingCity"
                name="billingCity"
                label="City"
                isRequired
                value={values.billingCity}
                onChange={handleChangeInput}
                error={!!errors.billingCity}
                helperText={errors.billingCity}
              />
            </S.InputRow>
            <S.InputRow>
              <SelectInput
                label="State"
                isRequired
                id="billingState"
                name="billingState"
                value={values.billingState}
                error={!!errors.billingState}
                helperText={errors.billingState}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setFieldError('billingState', '');
                  handleChange(e);
                }}
              >
                {StatesUSA.map(state => (
                  <MenuItem key={state.abbreviation} value={state.abbreviation}>
                    <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </SelectInput>
              <Input
                id="billingZipCode"
                name="billingZipCode"
                label="ZipCode"
                isRequired
                value={values.billingZipCode}
                onChange={handleChangeInput}
                error={!!errors.billingZipCode}
                helperText={errors.billingZipCode}
              />
            </S.InputRow>
          </S.PaymentInformation>
        </S.Content>
        <S.Footer>
          <S.BtnWrapper>
            <Button text="Create" type="submit" />
          </S.BtnWrapper>
        </S.Footer>
      </S.Wrapper>
    </form>
  );
};

export default AddFacility;
