import styled from 'styled-components';
import { devices } from '../../../../../../constants/device';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const EditBtnWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 10px;
  width: 100%;

  & svg {
    width: 38px;
    height: 38px;
    cursor: pointer;
  }
`;

export const SwitchCustomWrapper = styled.div`
  margin-top: 10px;
`;

export const PrefixLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const PrefixMainWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PrefixRB = styled.div`
  margin-top: 15px;
  cursor: pointer;
`;

export const PrefixSpan = styled.span`
  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Btn = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;

  color: var(--main, #0084b1);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PrefixWrap = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 20px;
  margin-right: 20px;
  align-items: center;
  gap: 10px;
  display: flex;
`;

export const PrefixItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 10px;
  font-family: Inter;
  font-size: 14px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ButtonsEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
`;

export const InputWrapper = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.prefix {
    margin-top: 15px;
  }
`;

export const AvatarDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  margin-top: 20px;

  color: var(--text, #202e5f);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  & svg {
    width: 70px;
    height: 70px;
  }

  & img {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    object-fit: cover;
  }
`;

export const AvatarClickZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const AvatarZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 70px;
  }
`;

export const AvatarHelper = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  @media ${devices.xs} {
    width: 310px;
    margin-top: 8px;
  }
`;

export const DeleteCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #c66060;
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 70px;
  right: 115px;
  cursor: pointer;
`;

export const Name = styled.div`
  margin-top: 5px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
`;

export const TextHelper = styled.span`
  color: var(--icon, #848a9b);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const MainInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 18px;
  width: 100%;
`;

export const InfoRow = styled.div`
  display: flex;
  width: 100%;
`;
export const InfoRowColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BlockText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const EditInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RowArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;

  align-items: center;
  gap: 8px;
  margin-left: 20px;
`;

export const RowText = styled.div`
  width: 100px;
`;

export const ColumnRowWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 0.5px solid var(--line, #d7d7d7);
  width: 100%;
`;

export const BlockRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const UpperArticle = styled.div`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

export const UpperInfoText = styled.div`
  color: var(--text, #202e5f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ReceptionistName = styled.span`
  color: var(--icon, #848a9b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const RowItems = styled.div`
  width: 100%;
`;
