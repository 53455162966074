import { useState } from 'react';
import { AddClinicIconSVG } from 'assets/icons';
import { Button, CustomPagination, CustomTable, SearchInput } from 'components';
import * as S from './styles';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAllClinicsGetQuery } from 'store/api/clinics/clinicApi';

import { DrawerMode } from 'types/DrawerMode';
import { showDrawer } from 'store/slices/drawerSlice';
import { useDispatch } from 'react-redux';
import FacilitiesRow from './TableData/FacilitiesRow';
import { useAllFacilitiesGetQuery } from '../../../../store/api/facilities/facilityApi';

const headers = [
  { name: 'Facility name', key: 'FacilityName' },
  { name: 'NPI', key: 'NPI' },
  { name: 'EIN', key: 'EIN' },
  { name: 'Doctors', key: 'Doctors' },
  { name: 'Status', key: 'Status' },
  // { name: 'Local Admin', key: 'LocalAdmin' },
  // { name: 'Chat link', key: 'Chatlink' },
];

const FACILITY_LIMIT = 20;

const Facilities = () => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const listResult = useAllFacilitiesGetQuery({
    q: debouncedSearch,
    take: FACILITY_LIMIT,
    page,
  });
  const dispatch = useDispatch();

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>Facilities</S.SubtitleText>
        <S.SubtitleItems>
          <S.ButtonWrapper>
            <Button
              text="Add Facilities"
              onClick={() => {
                dispatch(
                  showDrawer({
                    show: true,
                    mode: DrawerMode.ADD_FACILITY,
                    props: null,
                  }),
                );
              }}
            >
              <AddClinicIconSVG />
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.SubtitleBar>
      <S.Content>
        <S.Content>
          <S.HeaderTable>
            <CustomPagination
              isFetching={listResult.isFetching}
              setPage={setPage}
              currentPage={listResult.currentData?.page}
              lastPage={listResult.currentData?.lastPage}
              count={listResult?.currentData?.count}
              text={'Facilities'}
              limit={FACILITY_LIMIT}
            />
            <S.SearchWrapper>
              <SearchInput value={searchValue} onChange={handleSearch} />
            </S.SearchWrapper>
          </S.HeaderTable>
          <S.TableBody>
            <CustomTable headers={headers} isFetching={listResult.isFetching}>
              <FacilitiesRow
                clickable
                data={listResult?.currentData?.rows}
                headers={headers}
              />
            </CustomTable>
          </S.TableBody>
        </S.Content>
      </S.Content>
    </S.Wrapper>
  );
};

export default Facilities;
