import React, { useMemo, useState } from 'react';
import CircularLoader from 'components/atoms/CircuralLoader';
import { Tab } from '@mui/material';
import Button from 'components/atoms/Button';

import { ChooseProviderTab } from 'constants/constants';
import { CustomTabsItem } from 'components/atoms/Tabs';

import * as S from './styles';
import { DoctorsTable } from '../ChooseDoctorOrClinic/DoctorsTable';
import { FacilityTable } from './FacilityTable';

type Props = {
  onApplyClick: (id: string | null, tabLabel: string) => void;
  currentValue?: string;
  title?: string;
  onlyDoctor?: boolean;
  onlyClinic?: boolean;
  defaultDoctorTab?: boolean;
};

export const ChooseDoctorOrFacility = ({
  onApplyClick,
  currentValue,
  title = 'Billing Provider',
  onlyDoctor = false,
  onlyClinic = false,
  defaultDoctorTab = false,
}: Props) => {
  const [tabIndex, setTabIndex] = useState(
    onlyDoctor || defaultDoctorTab ? ChooseProviderTab.Doctor : ChooseProviderTab.Clinic,
  );
  const [isLoading, setIsLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const getTable = useMemo(() => {
    switch (tabIndex) {
      case ChooseProviderTab.Clinic:
        return (
          <FacilityTable
            currentValue={currentValue}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setIsLoading={setIsLoading}
          />
        );
      case ChooseProviderTab.Doctor:
        return (
          <DoctorsTable
            currentValue={currentValue}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setIsLoading={setIsLoading}
          />
        );
      default:
        return null;
    }
  }, [tabIndex, currentValue, selectedItem]);

  return (
    <S.Content>
      {isLoading && (
        <S.Loader>
          <CircularLoader color="#0084B1" size={16} />
        </S.Loader>
      )}
      <S.Article>{title}</S.Article>

      <S.TabWrapper>
        <CustomTabsItem
          tabIndex={tabIndex}
          setTabIndex={index => {
            setTabIndex(index as string);
            setSelectedItem(null);
          }}
        >
          {!onlyDoctor && (
            <Tab
              disableRipple
              label={ChooseProviderTab.Clinic}
              value={ChooseProviderTab.Clinic}
            />
          )}
          {!onlyClinic && (
            <Tab
              disableRipple
              label={ChooseProviderTab.Doctor}
              value={ChooseProviderTab.Doctor}
            />
          )}
        </CustomTabsItem>
      </S.TabWrapper>

      {getTable}

      <S.ButtonWrapper>
        <Button text="Apply" onClick={() => onApplyClick(selectedItem, tabIndex)} />
      </S.ButtonWrapper>
    </S.Content>
  );
};
