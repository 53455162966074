import { useParams } from 'react-router-dom';
import * as S from './styles';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  ApproveBtnSVG,
  EditBtnSVG,
  EmailIconSVG,
  PhoneIconSVG,
  ResetApproveBtnSVG,
} from 'assets/icons';
import { Input, SelectInput } from 'components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store/slices/message';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { StatesUSA } from 'constants/statesUSA';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';
import {
  useFacilitiesGetOneQuery,
  useFacilityUpdateMutation,
} from '../../../../../../store/api/facilities/facilityApi';

const FacilityProfileInformation = () => {
  const params = useParams();

  const facilityInfo = useFacilitiesGetOneQuery({
    id: params.facilityId,
  });

  const [isEditable, setIsEditable] = useState(false);

  type FormValues = {
    facilityName: string;
    email: string;
    phone: string;
    primaryState: string;
    primaryCity: string;
    primaryStreet: string;
    primaryZipCode: string;
    billingState: string;
    billingCity: string;
    billingStreet: string;
    billingZipCode: string;
    npi: string;
    ein: string;
  };

  const Schema = yup.object().shape({});
  const [updateFacility] = useFacilityUpdateMutation({});
  const dispatch = useDispatch();

  const { role } = useAppSelector(state => state.auth);

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
    initialValues,
    submitForm,
  } = useFormik<FormValues>({
    initialValues: {
      facilityName: '',
      email: '',
      phone: '',
      primaryState: '',
      primaryCity: '',
      primaryStreet: '',
      primaryZipCode: '',
      billingState: '',
      billingCity: '',
      billingStreet: '',
      billingZipCode: '',
      npi: '',
      ein: '',
    },
    validateOnChange: false,
    validationSchema: Schema,
    onSubmit: async () => {
      await updateFacility({
        id: params.facilityId,
        facilityName: values.facilityName,
        email: values.email,
        phone: values.phone,
        primaryState: values.primaryState,
        primaryCity: values.primaryCity,
        primaryStreet: values.primaryStreet,
        primaryZipCode: values.primaryZipCode,
        billingState: values.billingState,
        billingCity: values.billingCity,
        billingStreet: values.billingStreet,
        billingZipCode: values.billingZipCode,
        npi: values.npi,
        ein: values.ein,
      })
        .unwrap()
        .then(res => {
          setIsEditable(false);
          resetForm();
          dispatch(
            setMessage({
              message: res.message,
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(
            setMessage({
              message: error.data.message,
              type: 'error',
            }),
          );
        });
    },
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  useEffect(() => {
    setValues({
      ...values,
      facilityName: facilityInfo.currentData?.facilityName || '',
      email: facilityInfo.currentData?.email || '',
      phone: facilityInfo.currentData?.phone || '',
      primaryState: facilityInfo.currentData?.primaryState || '',
      primaryCity: facilityInfo.currentData?.primaryCity || '',
      primaryStreet: facilityInfo.currentData?.primaryStreet || '',
      primaryZipCode: facilityInfo.currentData?.primaryZipCode || '',
      billingState: facilityInfo.currentData?.billingState || '',
      billingCity: facilityInfo.currentData?.billingCity || '',
      billingStreet: facilityInfo.currentData?.billingStreet || '',
      billingZipCode: facilityInfo.currentData?.billingZipCode || '',
      npi: facilityInfo.currentData?.npi || '',
      ein: facilityInfo.currentData?.ein || '',
    });
  }, [isEditable]);

  return (
    <S.Wrapper>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <S.EditBtnWrapper>
          {isEditable ? (
            <S.ButtonsEdit>
              <ResetApproveBtnSVG
                onClick={() => {
                  setIsEditable(false);
                }}
              />
              <ApproveBtnSVG
                onClick={() => {
                  submitForm();
                }}
              />
            </S.ButtonsEdit>
          ) : role === Roles.SUPER_ADMIN ? (
            <S.ButtonsEdit>
              <EditBtnSVG onClick={() => setIsEditable(true)} />
            </S.ButtonsEdit>
          ) : null}
        </S.EditBtnWrapper>
        <S.MainInfoWrapper>
          <S.Name>
            {isEditable ? (
              <>
                <S.InputWrapper>
                  <Input
                    isRequired
                    label="Facility name"
                    id="facilityName"
                    name="facilityName"
                    error={!!errors.facilityName}
                    helperText={errors.facilityName}
                    onChange={handleChangeInput}
                    value={values.facilityName}
                  />
                </S.InputWrapper>
              </>
            ) : (
              facilityInfo.currentData?.facilityName
            )}
          </S.Name>
          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <Input
                  label="Email"
                  id="email"
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email}
                  onChange={handleChangeInput}
                  value={values.email}
                />
              </S.InputWrapper>
            ) : (
              <>
                <S.RowArticle>
                  <EmailIconSVG />
                  <S.BlockText>
                    <S.RowText>Email</S.RowText>
                  </S.BlockText>
                </S.RowArticle>
                <S.InputWrapper>{facilityInfo.currentData?.email || '-'}</S.InputWrapper>
              </>
            )}
          </S.InfoRow>
          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <Input
                  label="Phone"
                  id="phone"
                  name="phone"
                  error={!!errors.phone}
                  helperText={errors.phone}
                  onChange={handleChangeInput}
                  value={values.phone}
                />
              </S.InputWrapper>
            ) : (
              <>
                <S.RowArticle>
                  <PhoneIconSVG />
                  <S.BlockText>
                    <S.RowText>Phone </S.RowText>
                  </S.BlockText>
                </S.RowArticle>
                <S.InputWrapper>{facilityInfo.currentData?.phone || '-'}</S.InputWrapper>
              </>
            )}
          </S.InfoRow>
          {isEditable ? (
            <>
              <S.InfoRow>
                <S.InputWrapper>
                  <Input
                    label="Primary Street"
                    id="primaryStreet"
                    name="primaryStreet"
                    error={!!errors.primaryStreet}
                    helperText={errors.primaryStreet}
                    onChange={handleChangeInput}
                    value={values.primaryStreet}
                  />
                </S.InputWrapper>
              </S.InfoRow>
              <S.InfoRow>
                <S.InputWrapper>
                  <Input
                    label="Primary City"
                    id="primaryCity"
                    name="primaryCity"
                    error={!!errors.primaryCity}
                    helperText={errors.primaryCity}
                    onChange={handleChangeInput}
                    value={values.primaryCity}
                  />
                </S.InputWrapper>
              </S.InfoRow>
              <S.InfoRow>
                <S.InputWrapper>
                  <SelectInput
                    label="Primary State"
                    isRequired
                    id="primaryState"
                    name="primaryState"
                    value={values.primaryState}
                    error={!!errors.primaryState}
                    helperText={errors.primaryState}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      setFieldError('primaryState', '');
                      handleChange(e);
                    }}
                  >
                    {StatesUSA.map(state => (
                      <MenuItem key={state.abbreviation} value={state.abbreviation}>
                        <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                      </MenuItem>
                    ))}
                  </SelectInput>
                </S.InputWrapper>
              </S.InfoRow>
              <S.InfoRow>
                <S.InputWrapper>
                  <Input
                    label="Primary Zipcode"
                    id="primaryZipCode"
                    name="primaryZipCode"
                    error={!!errors.primaryZipCode}
                    helperText={errors.primaryZipCode}
                    onChange={handleChangeInput}
                    value={values.primaryZipCode}
                  />
                </S.InputWrapper>
              </S.InfoRow>
            </>
          ) : (
            <S.Row>
              <S.BlockRow>
                <S.UpperArticle>PRIMARY ADDRESS</S.UpperArticle>
                <S.UpperInfoText>
                  {[
                    facilityInfo.currentData?.primaryStreet || '',
                    facilityInfo.currentData?.primaryCity || '',
                    facilityInfo.currentData?.primaryState || '',
                    facilityInfo.currentData?.primaryZipCode || '',
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </S.UpperInfoText>
              </S.BlockRow>
            </S.Row>
          )}
          {isEditable ? (
            <>
              <S.InfoRow>
                <S.InputWrapper>
                  <Input
                    label="Billing Street"
                    id="billingStreet"
                    name="billingStreet"
                    error={!!errors.billingStreet}
                    helperText={errors.billingStreet}
                    onChange={handleChangeInput}
                    value={values.billingStreet}
                  />
                </S.InputWrapper>
              </S.InfoRow>
              <S.InfoRow>
                <S.InputWrapper>
                  <Input
                    label="Billing City"
                    id="billingCity"
                    name="billingCity"
                    error={!!errors.billingCity}
                    helperText={errors.billingCity}
                    onChange={handleChangeInput}
                    value={values.billingCity}
                  />
                </S.InputWrapper>
              </S.InfoRow>
              <S.InfoRow>
                <S.InputWrapper>
                  <SelectInput
                    label="Billing State"
                    isRequired
                    id="billingState"
                    name="billingState"
                    value={values.billingState}
                    error={!!errors.billingState}
                    helperText={errors.billingState}
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      setFieldError('billingState', '');
                      handleChange(e);
                    }}
                  >
                    {StatesUSA.map(state => (
                      <MenuItem key={state.abbreviation} value={state.abbreviation}>
                        <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                      </MenuItem>
                    ))}
                  </SelectInput>
                </S.InputWrapper>
              </S.InfoRow>
              <S.InfoRow>
                <S.InputWrapper>
                  <Input
                    label="Billing Zipcode"
                    id="billingZipCode"
                    name="billingZipCode"
                    error={!!errors.billingZipCode}
                    helperText={errors.billingZipCode}
                    onChange={handleChangeInput}
                    value={values.billingZipCode}
                  />
                </S.InputWrapper>
              </S.InfoRow>
            </>
          ) : (
            <S.Row>
              <S.BlockRow>
                <S.UpperArticle>BILLING ADDRESS</S.UpperArticle>
                <S.UpperInfoText>
                  {[
                    facilityInfo.currentData?.billingStreet || '',
                    facilityInfo.currentData?.billingCity || '',
                    facilityInfo.currentData?.billingState || '',
                    facilityInfo.currentData?.billingZipCode || '',
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </S.UpperInfoText>
              </S.BlockRow>
            </S.Row>
          )}

          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <Input
                  label="NPI"
                  id="npi"
                  name="npi"
                  error={!!errors.npi}
                  helperText={errors.npi}
                  onChange={handleChangeInput}
                  value={values.npi}
                />
              </S.InputWrapper>
            ) : (
              <>
                <S.Row>
                  <S.BlockRow>
                    <S.UpperArticle>NPI</S.UpperArticle>
                    <S.UpperInfoText>
                      {facilityInfo.currentData?.npi || '-'}
                    </S.UpperInfoText>
                  </S.BlockRow>
                </S.Row>
              </>
            )}
          </S.InfoRow>

          <S.InfoRow>
            {isEditable ? (
              <S.InputWrapper>
                <Input
                  label="EIN"
                  id="ein"
                  name="ein"
                  error={!!errors.ein}
                  helperText={errors.ein}
                  onChange={handleChangeInput}
                  value={values.ein}
                />
              </S.InputWrapper>
            ) : (
              <>
                <S.Row>
                  <S.BlockRow>
                    <S.UpperArticle>EIN</S.UpperArticle>
                    <S.UpperInfoText>
                      {facilityInfo.currentData?.ein || '-'}
                    </S.UpperInfoText>
                  </S.BlockRow>
                </S.Row>
              </>
            )}
          </S.InfoRow>
        </S.MainInfoWrapper>
      </form>
    </S.Wrapper>
  );
};

export default FacilityProfileInformation;
