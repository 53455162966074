import React, { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useAllDoctorsGetQuery } from '../../../../../../store/api/facilities/facilityApi';
import AutoCompleteAllDoctorsInput from '../../../../../../components/atoms/AutoComplete/AutoCompleteAllDoctorsInput';
import { DoctorsDate } from '../../../../../../types/FacilityTypes';

type Props = {
  selectedValue: any;
  setSelectedValue: (selectedValue: any) => void;
  getDoctorsList?: DoctorsDate[];
};

export const GetDoctorsFacilityList = ({
  selectedValue,
  setSelectedValue,
  getDoctorsList,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const getAll = useAllDoctorsGetQuery({
    q: debouncedSearchValue || undefined,
    take: 100,
    page: 1,
  });

  return (
    <AutoCompleteAllDoctorsInput
      isRequired
      data={getAll?.currentData || []}
      mainLabel={'Doctors'}
      loading={getAll.isLoading}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      getDoctorsList={getDoctorsList}
    />
  );
};
