import * as S from '../../../styles';
import { Dialog } from 'components';
import { AvatarSVG, DeleteBtnSVG, EditGrayBtnSVG } from 'assets/icons';
import { MouseEvent, useState } from 'react';
import ConfirmDeleteDialog from 'components/molecules/ConfirmDeleteDialog';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store/slices/message';
import { useDoctorsRemoveFromFacilityMutation } from '../../../../../../../store/api/facilities/facilityApi';
import EditFacilityDoctorsDialog from '../../../../ProfileFacility/components/EditFacilityDoctorsDialog';

type Props = {
  row: any;
  header: string;
  setIsClickedDelete?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsClickedEdit?: React.Dispatch<React.SetStateAction<boolean>>;
};

const FacilityDoctorData: React.FC<Props> = ({
  row,
  header,
  setIsClickedDelete,
  setIsClickedEdit,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [removeDoctorFromFacility] = useDoctorsRemoveFromFacilityMutation({});
  const dispatch = useDispatch();

  const handleChangeDialog = () => {
    setOpenDialog(openDialog => !openDialog);
  };

  const params = useParams();

  const handleSuccessDelete = () => {
    setOpenDialog(false);
    setIsClickedDelete && setIsClickedDelete(false);
    removeDoctorFromFacility({
      doctorId: row.doctor?.id,
      facilityId: params.facilityId,
    })
      .unwrap()
      .then(res => {
        dispatch(
          setMessage({
            message: res.message,
            type: 'success',
          }),
        );
      })
      .catch(error => {
        dispatch(
          setMessage({
            message: error.data.message,
            type: 'error',
          }),
        );
      });
  };

  switch (header) {
    case 'DoctorName': {
      return (
        <S.CellWrapper>
          <S.Avatar>
            {row.doctor.avatar ? (
              <img src={row.doctor.avatar.url} alt="avatar" />
            ) : (
              <AvatarSVG />
            )}
          </S.Avatar>
          {row.doctor?.profile?.firstName + ' ' + row.doctor?.profile?.lastName || '-'}
        </S.CellWrapper>
      );
    }

    case 'Npi': {
      return <S.CellWrapper>{row?.doctor?.credentials?.npi || '-'}</S.CellWrapper>;
    }
    case 'Taxonomy': {
      const taxonomyes = row?.taxonomyes || [];

      return (
        <S.TaxonomyWrapper>
          {taxonomyes.length > 0
            ? taxonomyes.map((item: any, index: any) => (
                <S.MenuTaxonomyWrapper key={index}>
                  {item.taxonomy && (
                    <>
                      <S.Label>Taxonomy:</S.Label>
                      <S.Value>{item.taxonomy}</S.Value>
                    </>
                  )}
                  {item.taxonomyName && (
                    <>
                      <S.Label>Taxonomy name:</S.Label>
                      <S.Value>{item.taxonomyName}</S.Value>
                    </>
                  )}
                </S.MenuTaxonomyWrapper>
              ))
            : '-'}
        </S.TaxonomyWrapper>
      );
    }
    case 'Edit': {
      return (
        <>
          <S.AllDotsWrapper>
            <S.DotsWrapper
              onClick={(e: MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                setIsClickedEdit && setIsClickedEdit(true);
                setOpenEditDialog(!openEditDialog);
              }}
            >
              <EditGrayBtnSVG />
            </S.DotsWrapper>
            <S.DotsWrapper
              onClick={(e: MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                setIsClickedDelete && setIsClickedDelete(true);
                handleChangeDialog();
              }}
            >
              <DeleteBtnSVG />
            </S.DotsWrapper>
          </S.AllDotsWrapper>
          <Dialog
            open={openEditDialog}
            onClose={() => {
              setIsClickedEdit && setIsClickedEdit(false);
              setOpenEditDialog(!openEditDialog);
            }}
          >
            <EditFacilityDoctorsDialog
              doctorId={row?.doctorId || ''}
              onClose={() => {
                setIsClickedEdit && setIsClickedEdit(false);
                setOpenEditDialog(!openEditDialog);
              }}
              doctorInfo={row}
            />
          </Dialog>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <ConfirmDeleteDialog
              onClose={() => {
                setOpenDialog(false);
                setIsClickedDelete && setIsClickedDelete(false);
              }}
              titleText={'doctor from current facility'}
              onSuccess={handleSuccessDelete}
            />
          </Dialog>
        </>
      );
    }
    default:
      return row[header] || '-';
  }
};

export default FacilityDoctorData;
