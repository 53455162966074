import { Autocomplete, Box, TextField } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import * as S from './styles';
import { AvatarSVG } from 'assets/icons';
import { DoctorsDate } from '../../../types/FacilityTypes';

type Props = {
  mainLabel?: string;
  isRequired?: boolean;
  data: Array<any>;
  loading: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  selectedValue: any;
  setSelectedValue: (value: any) => void;
  onDoubleClick?: () => void;
  getOptionLabel?: (option: any) => string;
  options?: Array<any>;
  customRender?: boolean;
  disabled?: boolean;
  customRenderInput?: any;
  error?: boolean;
  helperText?: string;
  serverSelected?: Array<any>;
  getOptionDisabled?: (option: any) => boolean;
  defaultValue?: string;
  setUserLastElement?: Dispatch<
    SetStateAction<HTMLDivElement | HTMLTableRowElement | null>
  >;
  setPage?: (page: number) => void;
  isOptionEqualToValue?: (option: any, val: any) => boolean;
  getDoctorsList?: DoctorsDate[];
};

const AutoCompleteAllDoctorsInput: React.FC<Props> = ({
  mainLabel,
  getDoctorsList,
  isRequired,
  data,
  loading,
  searchValue,
  disabled,
  selectedValue,
  setSearchValue,
  setSelectedValue,
  onDoubleClick,
  getOptionLabel,
  customRender,
  error,
  helperText,
  getOptionDisabled,
  setPage,
  isOptionEqualToValue,
}) => {
  useEffect(() => {
    if (data && selectedValue) {
      const currentValue = data.find(item => item.id === selectedValue);
      if (currentValue) {
        setSelectedValue(currentValue.id);
      }
    }
  }, [data, selectedValue]);

  const isDoctorDisabled = (option: any): boolean => {
    return !!getDoctorsList?.some(doctor => doctor.doctor.id === option.id);
  };

  return (
    <S.Wrapper onDoubleClick={onDoubleClick}>
      <S.Label>
        {mainLabel} {isRequired && <S.RequiredSpan>*</S.RequiredSpan>}
      </S.Label>
      <Autocomplete
        value={data.find(item => item.id === selectedValue) || null}
        onChange={(e, newValue) => setSelectedValue(newValue?.id || null)}
        disabled={disabled}
        options={data || []}
        isOptionEqualToValue={(option, value) => option.id === value}
        getOptionLabel={option =>
          `${option.profile?.firstName || ''} ${option.profile?.lastName || ''}`
        }
        getOptionDisabled={isDoctorDisabled}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.id}>
            <S.LiWrapper>
              <S.UserInfo>
                {option.avatar ? (
                  <S.Avatar>
                    <img src={option.avatar} alt="avatar" />
                  </S.Avatar>
                ) : (
                  <AvatarSVG />
                )}
                <div>
                  {`${option.profile?.firstName || ''} ${option.profile?.lastName || ''}`}
                </div>
              </S.UserInfo>
            </S.LiWrapper>
          </Box>
        )}
        inputValue={searchValue}
        onInputChange={(e, newInputValue) => {
          setSearchValue(newInputValue);
          if (setPage) setPage(1);
        }}
        renderInput={params => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            placeholder="Search for doctors"
            required={isRequired}
          />
        )}
        loading={loading}
      />
    </S.Wrapper>
  );
};

export default AutoCompleteAllDoctorsInput;
