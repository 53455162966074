export enum DrawerMode {
  NEW_APPOINTMENT = 'new_appointment',
  NEW_APPOINTMENT_TIME = 'new_appointment_time',
  INFO_APPOINTMENT = 'info_appointment',
  DUPL_APPOINTMENT = 'dupl_appointment',
  NEW_APPOINTMENT_USER = 'new_appointment_user',
  INFO_APPOINTMENT_USER = 'info_appointment_user',
  TREATMENT = 'treatment',
  NEW_TREATMENT = 'new_treatment',
  ALL_NOTES = 'all_notes',
  TICKET_CREATE = 'ticket_create',
  ADD_PATIENTS = 'add_patients',
  ADD_RECEPTIONIST = 'add_receptionist',
  ADD_DOCTOR = 'add_doctor',
  ADD_REF_PROVIDER = 'add_ref_provider',
  ADD_CLINIC = 'add_clinic',
  ADD_FACILITY = 'add_facility',
  ADD_LOCAL = 'add_local',
  ADD_BILLING = 'add_billing',
  HEALTH_RECORDS = 'health_records',
  DEFAULT = '',
  APPOINMENT_WITHOUT_REGISTRATION = 'appointment_without_reg',
  ADD_COUPON = 'add_coupon',
  ADD_SURVEY = 'add_survey',
  SHOW_INVOICE_HISTORY = 'show_invoice_history',
  INSURANCE_LOGS = 'insurance_logs',
}
