import React, { useEffect, useState } from 'react';
import { MenuItem, RadioGroup, SelectChangeEvent } from '@mui/material';
import { TooltipInput, RadioButton, TooltipSelectInput } from 'components';
import { StatesUSA } from 'constants/statesUSA';
import { FormikProps } from 'formik';
import { ProviderInfoFormType } from 'types/AppointmentNewTypes';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';
import { useDebounce } from 'use-debounce';
import { useGetAllDoctorsQuery } from 'store/api/staff/staffApi';
import AutoCompleteInput from 'components/atoms/AutoComplete';

import * as S from '../../styles';
import { useAllFacilitiesGetQuery } from '../../../../../../../../store/api/facilities/facilityApi';
import { Facility } from '../../../../../../../../types/FacilityTypes';

type Props = {
  providerFormik: FormikProps<Partial<ProviderInfoFormType>>;
  setBillingEin: (billingEin?: string | null) => void;
  checkStatusCondition?: boolean;
  isBlankClaim?: boolean;
  facilities?: Facility[];
};

export const BillingCard = ({
  checkStatusCondition,
  providerFormik,
  setBillingEin,
  isBlankClaim,
  facilities,
}: Props) => {
  // const clinics = useClinicsGetQuery({});

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const getAll = useGetAllDoctorsQuery({
    q: debouncedSearchValue,
    page: 1,
  });

  const [selectedValue, setSelectedValue] = useState<any>(null);

  // const onChangeClinic = (clinicId: string) => {
  //   const currentClinic = clinics.currentData?.rows?.find(i => i.id === clinicId);
  //
  //   if (currentClinic) {
  //     providerFormik.setValues({
  //       ...providerFormik.values,
  //       billingFacilityName: currentClinic?.name ?? '',
  //       billingFacilityId: currentClinic?.id ?? '',
  //       billingStreet: currentClinic?.street ?? '',
  //       billingOffice: '',
  //       billingCity: currentClinic?.city ?? '',
  //       billingState: currentClinic?.state ?? '',
  //       billingZip: currentClinic?.zipCode ?? '',
  //       billingNpi: currentClinic?.npi ?? '',
  //       billingEin: currentClinic?.ein ?? '',
  //     });
  //     setBillingEin(currentClinic?.ein ?? '');
  //   }
  // };
  const onChangeFacility = (facilityId: string) => {
    const currentFacilities = facilities?.find(i => i.id === facilityId);

    if (currentFacilities) {
      providerFormik.setValues({
        ...providerFormik.values,
        billingFacilityName: currentFacilities?.facilityName ?? '',
        billingFacilityId: currentFacilities?.id ?? '',
        billingStreet: currentFacilities?.billingStreet ?? '',
        billingOffice: '',
        billingCity: currentFacilities?.billingCity ?? '',
        billingState: currentFacilities?.billingState ?? '',
        billingZip: currentFacilities?.billingZipCode ?? '',
        billingNpi: currentFacilities?.npi ?? '',
        billingEin: currentFacilities?.ein ?? '',
      });
      setBillingEin(currentFacilities?.ein ?? '');
    }
  };
  const setInitValue = () => {
    setSelectedValue(null);
    void providerFormik.setValues({
      ...providerFormik.values,
      billingFacilityName: '',
      billingFacilityId: '',
      billingStreet: '',
      billingOffice: '',
      billingCity: '',
      billingState: '',
      billingZip: '',
      billingNpi: '',
      billingEin: '',
    });
  };

  useEffect(() => {
    if (selectedValue) {
      void providerFormik.setValues({
        ...providerFormik.values,
        billingFacilityName: selectedValue?.user?.profile
          ? selectedValue?.user?.profile?.firstName +
            ' ' +
            selectedValue?.user?.profile?.lastName
          : '',
        billingFacilityId: selectedValue?.userId ? selectedValue?.userId : '',
        billingNpi:
          providerFormik.values?.billingFacilityId === selectedValue?.userId
            ? providerFormik.values?.billingNpi
            : (selectedValue?.user?.credentials?.npi ?? ''),
      });
    }
  }, [selectedValue]);

  return (
    <S.Card>
      <S.EmptyDiv>
        <S.Caption>33. BILLING PROVIDER</S.Caption>
        <RadioGroup
          value={providerFormik.values.isFacilityBilling}
          onChange={e => {
            setInitValue();
            providerFormik.setFieldValue('isFacilityBilling', e.target.value);
            setBillingEin('');
          }}
        >
          <S.Label>
            <S.LabelArticle></S.LabelArticle>
          </S.Label>
          <S.RadioItem>
            <S.RadioButtons>
              <RadioButton
                label="Facility"
                value={'isFacilityBilling'}
                disabled={checkStatusCondition}
              />
              <RadioButton
                label="Physician"
                value={'isFhysicanBilling'}
                disabled={checkStatusCondition}
              />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>

        <S.InputWrapper className="all">
          {providerFormik.values.isFacilityBilling === 'isFacilityBilling' ? (
            isBlankClaim ? (
              <>
                <S.InputWrapper className="all">
                  <TooltipInput
                    label="Facility Name"
                    disabled={checkStatusCondition}
                    placeholder="Facility Name"
                    {...providerFormik.getFieldProps('billingFacilityName')}
                    error={!!providerFormik.errors.billingFacilityName}
                    tooltipHelperText={providerFormik.errors.billingFacilityName}
                  />
                </S.InputWrapper>
                <S.Line>
                  <S.InputWrapper className="percent50">
                    <TooltipInput
                      label=""
                      placeholder="Street"
                      disabled={checkStatusCondition}
                      {...providerFormik.getFieldProps('billingStreet')}
                      error={!!providerFormik.errors.billingStreet}
                      tooltipHelperText={providerFormik.errors.billingStreet}
                    />
                  </S.InputWrapper>
                  <S.InputWrapper className="percent50">
                    <TooltipInput
                      label=""
                      placeholder="Office"
                      disabled={checkStatusCondition}
                      {...providerFormik.getFieldProps('billingOffice')}
                      error={!!providerFormik.errors.billingOffice}
                      tooltipHelperText={providerFormik.errors.billingOffice}
                    />
                  </S.InputWrapper>
                </S.Line>
                <S.Line>
                  <S.InputWrapper className="percent33">
                    <TooltipInput
                      label=""
                      placeholder="City"
                      disabled={checkStatusCondition}
                      {...providerFormik.getFieldProps('billingCity')}
                      error={!!providerFormik.errors.billingCity}
                      tooltipHelperText={providerFormik.errors.billingCity}
                    />
                  </S.InputWrapper>
                  <S.InputWrapper className="percent33">
                    <TooltipSelectInput
                      label=""
                      id="state"
                      name="state"
                      disabled={checkStatusCondition}
                      placeholder={'State'}
                      value={providerFormik.values.billingState}
                      onChange={(e: SelectChangeEvent<unknown>) =>
                        providerFormik.setFieldValue('billingState', e.target.value)
                      }
                      error={!!providerFormik.errors.billingState}
                      tooltipHelperText={providerFormik.errors.billingState}
                    >
                      {StatesUSA.map(state => (
                        <MenuItem key={state.abbreviation} value={state.abbreviation}>
                          <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                        </MenuItem>
                      ))}
                    </TooltipSelectInput>
                  </S.InputWrapper>
                  <S.InputWrapper className="percent33">
                    <TooltipInput
                      label=""
                      placeholder="Zip"
                      disabled={checkStatusCondition}
                      {...providerFormik.getFieldProps('billingZip')}
                      error={!!providerFormik.errors.billingZip}
                      tooltipHelperText={providerFormik.errors.billingZip}
                    />
                  </S.InputWrapper>
                </S.Line>
                <S.Line>
                  <S.InputWrapper className="percent50">
                    <TooltipInput
                      label="EIN"
                      disabled={checkStatusCondition}
                      {...providerFormik.getFieldProps('billingEin')}
                      error={!!providerFormik.errors.billingEin}
                      tooltipHelperText={providerFormik.errors.billingEin}
                    />
                  </S.InputWrapper>
                  <S.InputWrapper className="percent50">
                    <TooltipInput
                      label="NPI"
                      disabled={checkStatusCondition}
                      {...providerFormik.getFieldProps('billingNpi')}
                      error={!!providerFormik.errors.billingNpi}
                      tooltipHelperText={providerFormik.errors.billingNpi}
                    />
                  </S.InputWrapper>
                </S.Line>
              </>
            ) : (
              <>
                <S.EmptyDiv className={'smallGap'}>
                  <TooltipSelectInput
                    label="Facility Name"
                    disabled={checkStatusCondition}
                    value={providerFormik.values.billingFacilityId}
                    // onChange={e => onChangeClinic(e.target.value as string)}
                    onChange={e => onChangeFacility(e.target.value as string)}
                    error={!!providerFormik.errors.billingFacilityName}
                    tooltipHelperText={providerFormik.errors.billingFacilityName}
                  >
                    {/*{clinics.currentData?.rows?.map(clinic => (*/}
                    {/*  <MenuItem key={clinic.id} value={clinic.id}>*/}
                    {/*    <S.MenuItemContent>{clinic.name}</S.MenuItemContent>*/}
                    {/*  </MenuItem>*/}
                    {/*))}*/}
                    {facilities?.map(facility => (
                      <MenuItem key={facility.id} value={facility.id}>
                        <S.MenuItemContent>{facility.facilityName}</S.MenuItemContent>
                      </MenuItem>
                    ))}
                  </TooltipSelectInput>
                </S.EmptyDiv>

                <S.Line>
                  <S.InputWrapper className="percent50">
                    <TooltipInput
                      label=""
                      placeholder={'Street'}
                      disabled={checkStatusCondition}
                      {...providerFormik.getFieldProps('billingStreet')}
                      error={!!providerFormik.errors.billingStreet}
                      tooltipHelperText={providerFormik.errors.billingStreet}
                    />
                  </S.InputWrapper>
                  <S.InputWrapper className="percent50">
                    <TooltipInput
                      label=""
                      disabled={checkStatusCondition}
                      placeholder={'Office'}
                      {...providerFormik.getFieldProps('billingOffice')}
                      error={!!providerFormik.errors.billingOffice}
                      tooltipHelperText={providerFormik.errors.billingOffice}
                    />
                  </S.InputWrapper>
                </S.Line>

                <S.Line>
                  <S.InputWrapper className="percent33">
                    <TooltipInput
                      label=""
                      placeholder={'City'}
                      disabled={checkStatusCondition}
                      {...providerFormik.getFieldProps('billingCity')}
                      error={!!providerFormik.errors.billingCity}
                      tooltipHelperText={providerFormik.errors.billingCity}
                    />
                  </S.InputWrapper>
                  <S.InputWrapper className="percent33">
                    <TooltipSelectInput
                      label=""
                      id="state"
                      name="state"
                      disabled={checkStatusCondition}
                      placeholder={'State'}
                      value={providerFormik.values.billingState}
                      onChange={(e: SelectChangeEvent<unknown>) =>
                        providerFormik.setFieldValue('billingState', e.target.value)
                      }
                      error={!!providerFormik.errors.billingState}
                      tooltipHelperText={providerFormik.errors.billingState}
                    >
                      {StatesUSA.map(state => (
                        <MenuItem key={state.abbreviation} value={state.abbreviation}>
                          <S.MenuItemContent>{state.abbreviation}</S.MenuItemContent>
                        </MenuItem>
                      ))}
                    </TooltipSelectInput>
                  </S.InputWrapper>
                  <S.InputWrapper className="percent33">
                    <TooltipInput
                      label=""
                      placeholder={'Zip'}
                      disabled={checkStatusCondition}
                      {...providerFormik.getFieldProps('billingZip')}
                      error={!!providerFormik.errors.billingZip}
                      tooltipHelperText={providerFormik.errors.billingZip}
                    />
                  </S.InputWrapper>
                </S.Line>

                <S.Line>
                  <S.InputWrapper className="percent50">
                    <TooltipInput
                      label="EIN"
                      name="billingEin"
                      disabled={checkStatusCondition}
                      value={providerFormik.values?.billingEin}
                      onChange={e => {
                        providerFormik.setFieldValue('billingEin', e.target.value);
                        setBillingEin(e.target.value);
                      }}
                      error={!!providerFormik.errors.billingEin}
                      tooltipHelperText={providerFormik.errors.billingEin}
                    />
                  </S.InputWrapper>
                  <S.InputWrapper className="percent50">
                    <TooltipInput
                      label="NPI"
                      disabled={checkStatusCondition}
                      {...providerFormik.getFieldProps('billingNpi')}
                      error={!!providerFormik.errors.billingNpi}
                      tooltipHelperText={providerFormik.errors.billingNpi}
                    />
                  </S.InputWrapper>
                </S.Line>
              </>
            )
          ) : (
            <>
              {isBlankClaim ? (
                <TooltipInput
                  label="Name"
                  disabled={checkStatusCondition}
                  placeholder="Physician Name"
                  {...providerFormik.getFieldProps('billingFacilityName')}
                  error={!!providerFormik.errors.billingFacilityName}
                  tooltipHelperText={providerFormik.errors.billingFacilityName}
                />
              ) : (
                <S.InputAutoCompleteWrapper>
                  <AutoCompleteInput
                    data={getAll?.currentData?.rows || []}
                    mainLabel={'Name'}
                    loading={!!getAll.isLoading}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    disabled={checkStatusCondition}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    defaultValue={providerFormik.values?.billingFacilityId ?? undefined}
                    error={!!providerFormik.errors.billingFacilityName}
                    helperText={providerFormik.errors.billingFacilityName}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.userId === value?.userId
                    }
                  />
                </S.InputAutoCompleteWrapper>
              )}

              <S.Gap />
              <S.InputWrapper className="all">
                <TooltipInput
                  label="NPI"
                  disabled={checkStatusCondition}
                  {...providerFormik.getFieldProps('billingNpi')}
                  error={!!providerFormik.errors.billingNpi}
                  tooltipHelperText={providerFormik.errors.billingNpi}
                />
              </S.InputWrapper>
            </>
          )}
        </S.InputWrapper>
      </S.EmptyDiv>
    </S.Card>
  );
};
